import { FormElementType } from 'components/formik/FormElement';
import * as Yup from 'yup';
import { createComponent } from './CreateComponent';
import BOX1 from 'assets/img/component-temps/box1.png';

export const box1Component = createComponent({
  type: 'box1',
  title: 'Box',
  initialValues: {
    title: '',
    description: '',
    imageUrl: '',
    imageFirst: false,
    links: [],
  },
  form: [
    {
      type: FormElementType.Input,
      name: 'title',
      validation: Yup.string().min(2).max(128),
      props: {
        placeholder: 'Enter Section Title',
        label: 'Title',
      },
    },
    {
      type: FormElementType.Textarea,
      name: 'description',
      initialValue: '',
      validation: Yup.string().min(2).max(256),
      props: {
        placeholder: 'Enter Section Description',
        label: 'Description',
        rows: 6,
      },
    },
    {
      type: FormElementType.FilePicker,
      name: 'imageUrl',
      validation: Yup.string().min(2),
      props: {
        label: 'Image url',
      },
    },
    {
      type: FormElementType.Switch,
      name: 'imageFirst',
      validation: Yup.boolean(),
      props: {
        label: 'Image First',
      },
    },
    {
      type: FormElementType.Array,
      name: 'links',
      // validation: Yup.string().min(2).max(128),
      props: {
        label: 'Links',
        emptyValues: { title: '', url: '' },
        form: [
          {
            type: FormElementType.Input,
            name: 'title',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Enter Title',
              label: 'Title',
            },
          },
          {
            type: FormElementType.Input,
            name: 'url',
            validation: Yup.string().min(2).max(1024),
            props: {
              placeholder: 'https://example.com OR /pageId/page-title',
              label: 'Link',
            },
          },
        ],
      },
    },
  ],
  image: BOX1,
});

import { FormElementType } from 'components/formik/FormElement';
import * as Yup from 'yup';
import PACKAGE1 from 'assets/img/component-temps/package1.png';
import { createComponent } from './CreateComponent';

export const package1Component = createComponent({
  type: 'package1',
  title: 'Package',
  initialValues: {
    title: '',
    description: '',
    packageTitle: '',
    packageItems: [],
    packageOffers: [{ title: '', price: '' }],
    buttonTitle: '',
    buttonLink: '',
    offersDescription: '',
  },
  form: [
    {
      type: FormElementType.Input,
      name: 'title',
      validation: Yup.string().min(2).max(128),
      props: {
        placeholder: 'Enter Title',
        label: 'Title',
      },
    },
    {
      type: FormElementType.Textarea,
      name: 'description',
      validation: Yup.string().min(2).max(512),
      props: {
        placeholder: 'Enter Description',
        label: 'Description',
      },
    },
    {
      type: FormElementType.Input,
      name: 'packageTitle',
      validation: Yup.string().min(2).max(128),
      props: {
        placeholder: 'Enter Title',
        label: 'Package Title',
      },
    },
    {
      type: FormElementType.Tags,
      name: 'packageItems',
      props: {
        placeholder: 'Enter Items...',
        label: 'Package Items',
      },
    },
    {
      type: FormElementType.Array,
      name: 'packageOffers',
      // validation: Yup.string().min(2).max(128),
      props: {
        label: 'Package Offers',
        emptyValues: { title: '', price: '' },
        form: [
          {
            type: FormElementType.Input,
            name: 'title',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Enter Title',
              label: 'Title',
            },
          },
          {
            type: FormElementType.Input,
            name: 'price',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Enter Price',
              label: 'Price',
            },
          },
        ],
      },
    },
    {
      type: FormElementType.Input,
      name: 'buttonTitle',
      validation: Yup.string().min(2).max(32),
      props: {
        placeholder: 'Enter Title',
        label: 'Button Title',
      },
    },
    {
      type: FormElementType.Input,
      name: 'buttonLink',
      validation: Yup.string().min(2),
      props: {
        placeholder: 'Enter Link',
        label: 'Button Link',
      },
    },
    {
      type: FormElementType.Textarea,
      name: 'offersDescription',
      validation: Yup.string().min(2).max(128),
      props: {
        placeholder: 'Enter Description',
        label: 'Offer Description',
      },
    },
  ],
  image: PACKAGE1,
});

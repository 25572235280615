import { useMemo } from 'react';
import { b2cApiPrefix, useFetchPost } from '../services/api.service';

export const useHotelLocationQuery = (model) => {
  const { data, ...others } = useFetchPost(
    model.query < 3 ? null : [`${b2cApiPrefix}/hotel/b2c/location`, model],
  );

  const _data = useMemo(() => {
    if (!data) return [];
    return data.items;
  }, [data]);

  return { data: _data, ...others };
};

import * as Yup from 'yup';

import { FormElementType } from 'components/formik/FormElement';
import { createComponent, defaultForm } from './CreateComponent';

export const htmlComponent = createComponent(
  {
    type: 'html',
    title: 'Html',
    defaultValues: {
      content: '',
      hasContainer: true,
      hasGutters: true,
    },
    form: [
      ...defaultForm,
      {
        type: FormElementType.Switch,
        name: 'hasContainer',
        props: {
          label: 'Has Container',
        },
      },
      {
        type: FormElementType.Switch,
        name: 'hasGutters',
        props: {
          label: 'Has Gutters',
        },
      },
      {
        type: FormElementType.TextEditor,
        name: 'content',
        validation: Yup.string(),
        props: {
          placeholder: 'Enter Content',
          label: 'Content',
        },
      },
    ],
  },
  true,
);

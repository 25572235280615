import { hero1Component } from './Hero1';
import { mapLocations1Component } from './MapLocations1';
import { popularRoutes1Component } from './PopularRoutes1';
import { recommendedHotels1Component } from './RecommendedHotels1';
import { steps3Component } from './Steps3';
import { topDestinations1Component } from './TopDestinations1';
import { topTours1Component } from './TopTours1';
import { footer1Component } from './Footer1';
import { htmlComponent } from './HtmlComponent';
import { youtube1Component } from './Youtube1';
import { youtube2Component } from './Youtube2';
import { steps1Component } from './Steps1';
// import { steps2Component } from './Steps2';
import { package1Component } from './Package1';
import { box1Component } from './Box1';
import { overview1Component } from './Overview1';
import { carousel1Component } from './Carousel1';
import { carousel2Component } from './Carousel2';
import { form1Component } from './Form1';

export const components = [
  hero1Component,
  mapLocations1Component,
  // popularRoutes1Component,
  // recommendedHotels1Component,
  steps1Component,
  // steps2Component,
  steps3Component,
  topDestinations1Component,
  topTours1Component,
  footer1Component,
  carousel1Component,
  carousel2Component,
  youtube1Component,
  youtube2Component,
  package1Component,
  box1Component,
  overview1Component,
  form1Component,
  htmlComponent,
];

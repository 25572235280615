import { MdAddCircle, MdAddCircleOutline } from 'react-icons/md';

export function AddSectionButton(props) {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      className="border flex justify-center py-6 rounded-lg cursor-pointer select-none"
    >
      <MdAddCircleOutline size={40} className="opacity-40" />
    </div>
  );
}

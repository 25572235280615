import HERO1 from 'assets/img/component-temps/hero1.png';
import { FormElementType } from 'components/formik/FormElement';
import { createComponent } from './CreateComponent';

export const hero1Component = createComponent({
  type: 'hero1',
  title: 'Hero',
  initialValues: {
    flight: true,
    hotel: true,
    car: true,
    transfer: true,
    bus: true,
    train: true,
    activity: true,
    cruises: true,
  },
  form: [
    // {
    //   type: FormElementType.Select,
    //   name: 'products',
    //   validation: Yup.array().of(Yup.string()),
    //   props: {
    //     multiple: true,
    //     options: ['hotel', 'flight'],
    //     label: 'Products',
    //   },
    // },
  ],
  image: HERO1,
});

import { FormElementType } from 'components/formik/FormElement';
import useForm from 'components/formik/useForm';
import { useMemo } from 'react';
import * as Yup from 'yup';

export default function SettingsFormLang({ data, lang, onSubmit }) {
  const handleSubmit = async (values) => {
    const newValues = { ...values };
    Object.entries(newValues).forEach(([key, value]) => {
      if (!value) delete newValues[key];
    });

    await onSubmit({
      ...data,
      [lang]: newValues,
    });
  };

  const mainData = useMemo(() => data?.main, [data]);
  const initialValues = useMemo(() => data?.[lang], [data, lang]);

  const form = useMemo(
    () => [
      {
        type: FormElementType.Input,
        name: 'name',
        validation: Yup.string().min(2).max(64),
        props: {
          placeholder: mainData?.name || '',
          label: 'Name',
        },
      },
      {
        type: FormElementType.FilePicker,
        name: 'logo',
        validation: Yup.string().min(2),
        props: {
          image: true,
          label: 'Logo',
          placeholder: mainData?.logo || '',
        },
      },
    ],
    [initialValues, mainData],
  );

  const { formik, SubmitButton, AllElements } = useForm({
    form,
    initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <div className="grid gap-4">{AllElements}</div>
      <SubmitButton className="mt-4" />
    </>
  );
}

import React, { useState } from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { addBlog, useBlogs } from '../../../lib/api/blogs.api';
import { isSuccessful } from '../../../lib/services/api.service';
import PageCard from 'components/page-components/page-card';
import AddPageButton from 'components/page-components/add-page-button';
import AddPageModal from 'components/page-components/add-page-modal';
import { defaultLang } from 'lib/constants';
import BlogSeoFormModal from './components/blog-seo-form-modal';

export default function Blog() {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const seoModalDisclosure = useDisclosure();
  const [selected, setSelected] = useState();
  // const { openAlert, closeAlert } = useAlertContext();

  const { data, error, isLoading, mutate } = useBlogs();

  const openBlog = (blog) => {
    const url = `/admin/blog/${blog.id}`;
    history.push(url);
  };

  const onCardClick = (blog, lang) => {
    if (!lang) return openBlog(blog);
    setSelected({ id: blog.id, lang });
    seoModalDisclosure.onOpen();
  };
  const onCloseBlogSeo = (successful) => {
    if (successful) mutate();
    seoModalDisclosure.onClose();
  };
  const handleToggleDisabled = (blog) => {};
  const handleDelete = (blog) => {};

  const handleAddBlog = async (values) => {
    const res = await addBlog(values);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate();
    onClose();
    openBlog(res.data.data);
  };
  return (
    <Box>
      <div className="flex flex-wrap gap-4">
        <div className="w-full sm:w-96">
          <AddPageButton onClick={onOpen} />
        </div>
        {isLoading ? (
          Array(10)
            .fill('')
            .map((_, idx) => (
              <div className="w-full sm:w-96 max-w-full" key={idx}>
                <PageCard />
              </div>
            ))
        ) : error || !data ? (
          <>An error has occurred</>
        ) : (
          data.map((blog) => (
            <div className="w-full sm:w-96 max-w-full" key={blog.id}>
              <PageCard
                onClick={(lang) => onCardClick(blog, lang)}
                onToggleDisabled={handleToggleDisabled}
                data={blog}
                onDelete={handleDelete}
              />
            </div>
          ))
        )}
      </div>
      <AddPageModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleAddBlog}
        title="Add Blog"
        permalinkPrefix="/blog/"
        excludePrefix="post"
        // permalinkPrefix="/en/blog/id/"
        image
      />
      <BlogSeoFormModal
        {...seoModalDisclosure}
        {...selected}
        onClose={onCloseBlogSeo}
      />
    </Box>
  );
}

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export default function InputModal(props) {
  const { siblings, data, isOpen, onClose, onSubmit } = props;
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    const _value = value.trim();
    if (isLoading || !_value) return;
    if (_value === data?.name) return onClose();
    if (siblings.find((n) => n === _value.toLowerCase())) {
      // TODO: handle error
      return;
    }

    setIsLoading(true);
    await onSubmit(_value);
    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) return;
    setValue(data?.name || 'untitled folder');
  }, [data, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{data ? 'Rename' : 'New Folder'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              disabled={isLoading}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button disabled={isLoading} mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="brand"
            variant="solid"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {data ? 'Save' : 'Create'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import * as Yup from 'yup';

import FOOTER1 from 'assets/img/component-temps/footer1.png';
import { FormElementType } from 'components/formik/FormElement';
import { createComponent, defaultForm } from './CreateComponent';

export const footer1Component = createComponent(
  {
    type: 'footer1',
    title: 'Footer 1',
    initialValues: {
      logo: '',

      tel: '',
      whatsappNumber: '',
      email: '',
      address: '',

      policyTitle: '',
      policyLink: '',

      copyright: '',

      facebookUrl: '',
      instagramUrl: '',
      youtubeUrl: '',
      pinterestUrl: '',

      columns: [
        {
          title: '',
          items: [],
        },
        {
          title: '',
          items: [],
        },
      ],
    },
    form: [
      {
        type: FormElementType.FilePicker,
        name: 'logo',
        validation: Yup.string().min(2),
        props: {
          image: true,
          label: 'Logo',
          placeholder: 'Logo Url',
        },
      },
      {
        type: FormElementType.Input,
        name: 'tel',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Tel',
          label: 'Tel',
        },
      },
      {
        type: FormElementType.Input,
        name: 'whatsappNumber',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Whatsapp Number',
          label: 'Whatsapp',
        },
      },
      {
        type: FormElementType.Input,
        name: 'email',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Email',
          label: 'Email',
        },
      },
      {
        type: FormElementType.Input,
        name: 'facebookUrl',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Facebook Address',
          label: 'Facebook',
        },
      },
      {
        type: FormElementType.Input,
        name: 'instagramUrl',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Instagram Address',
          label: 'Instagram',
        },
      },
      {
        type: FormElementType.Input,
        name: 'youtubeUrl',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Youtube Address',
          label: 'Youtube',
        },
      },
      {
        type: FormElementType.Input,
        name: 'pinterestUrl',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Pinterest Address',
          label: 'Pinterest',
        },
      },
      {
        type: FormElementType.Textarea,
        name: 'address',
        validation: Yup.string().min(2).max(512),
        props: {
          placeholder: 'Enter Address',
          label: 'Address',
        },
      },
      {
        type: FormElementType.Input,
        name: 'policyTitle',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Policy Link Title',
          label: 'Policy Title',
        },
      },
      {
        type: FormElementType.Input,
        name: 'policyLink',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Policy Link Url',
          label: 'Policy Link',
        },
      },
      {
        type: FormElementType.Input,
        name: 'copyright',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter copyright',
          label: 'Copyright',
        },
      },
      ...defaultForm,
      {
        type: FormElementType.Array,
        name: 'columns',
        // validation: Yup.string().min(2).max(128),
        props: {
          label: `Columns`,
          emptyValues: { title: '', items: [] },
          minItems: 2,
          maxItems: 2,
          form: [
            {
              type: FormElementType.Input,
              name: 'title',
              props: {
                placeholder: 'Enter title...',
                label: 'Title',
              },
            },
            {
              type: FormElementType.LinkPicker,
              name: 'items',
              props: {
                multiple: true,
                label: 'Links',
              },
            },
          ],
        },
      },
    ],
    image: FOOTER1,
    overridables: [
      'address',
      'tel',
      'email',
      'bgColor',
      'policyTitle',
      'policyLink',
      'copyright',
    ],
  },
  true,
);

import {
  IconButton,
  Skeleton,
  SkeletonText,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import * as Yup from 'yup';
import Card from 'components/card/Card';
import useForm from 'components/formik/useForm';
import { FormElementType } from 'components/formik/FormElement';
import { useMemo } from 'react';
import { VscCopy } from 'react-icons/vsc';
import copy from 'copy-to-clipboard';
import yupSchemas from 'helpers/yupSchemas';
import FormSkeleton from 'components/form-skeleton';
import { CONFIG } from 'lib/configuration';

export default function SeoForm(props) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  if (props.data) return <RealComponent {...props} />;
  return (
    <Card className="gap-4">
      <Text
        className="mb-3"
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="xl"
        align="center"
      >
        SEO
      </Text>
      <FormSkeleton />
      <FormSkeleton />
      <FormSkeleton />
      <FormSkeleton height={20} />
      <FormSkeleton />
      <FormSkeleton className="mt-4" button />
    </Card>
  );
}

const RealComponent = (props) => {
  const { data, onSubmit, getRoute, permalinkPrefix, excludePrefix, hasImage } =
    props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const initialValues = useMemo(
    () => ({
      name: data.name,
      title: data.title,
      permalink: data.permalink,
      caption: data.caption,
      description: data.description,
      imageUrl: data.imageUrl,
      keywords:
        typeof data.keywords === 'string'
          ? JSON.parse(data.keywords)
          : data.keywords,
    }),
    [data],
  );

  const form = useMemo(() => {
    return [
      {
        type: FormElementType.Input,
        name: 'name',
        validation: Yup.string().min(2).max(64).required('Required'),
        props: {
          placeholder: 'Enter Name',
          label: 'Name',
        },
      },
      {
        type: FormElementType.FilePicker,
        name: 'imageThumbUrl',
        validation: Yup.string().min(2),
        hidden: !hasImage,
        props: {
          image: true,
          label: 'Image Thumbnail',
        },
      },
      {
        type: FormElementType.FilePicker,
        name: 'imageUrl',
        validation: Yup.string().min(2),
        hidden: !hasImage,
        props: {
          image: true,
          label: 'Image',
        },
      },
      {
        type: FormElementType.Input,
        name: 'title',
        validation: Yup.string().min(2).max(256).required('Required'),
        props: {
          placeholder: 'Enter Title',
          label: 'Title',
          // onChange: (e) =>
          //   formik.setFieldValue('permalink', toPermalink(e.target.value)),
        },
      },
      {
        type: FormElementType.Input,
        name: 'permalink',
        validation: yupSchemas.permalink(excludePrefix),
        props: {
          label: 'Permalink',
          leftAddon: `${permalinkPrefix}`,
          rightElement: (
            <div>
              <IconButton
                onClick={() =>
                  copy(
                    `${CONFIG.PLATFORM_CLIENT_URL}${getRoute(
                      // formik.values.permalink,
                      data.permalink,
                    )}`,
                  )
                }
                size="sm"
                icon={<VscCopy />}
              />
            </div>
          ),
        },
      },
      {
        type: FormElementType.Input,
        name: 'caption',
        validation: Yup.string().min(2).max(512),
        props: {
          placeholder: 'Enter Caption',
          label: 'Caption',
          // onChange: (e) =>
          //   formik.setFieldValue('permalink', toPermalink(e.target.value)),
        },
      },
      {
        type: FormElementType.Textarea,
        name: 'description',
        validation: Yup.string().max(1024),
        props: {
          placeholder: 'Enter Description',
          label: 'Description',
        },
      },
      {
        type: FormElementType.Tags,
        name: 'keywords',
        props: {
          placeholder: 'Type keywords...',
          label: 'Keywords',
        },
      },
    ];
  }, [data.permalink, data.lang]);

  const { formik, SubmitButton, AllElements } = useForm({
    form,
    initialValues,
    onSubmit,
  });

  return (
    <Card className="gap-4">
      <Text
        className="mb-3"
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="xl"
        align="center"
      >
        SEO
      </Text>
      {AllElements}
      <SubmitButton className="mt-4" />
    </Card>
  );
};

import * as Yup from 'yup';

import TOP_TOURS1 from 'assets/img/component-temps/topTours1.png';
import { FormElementType } from 'components/formik/FormElement';
import { createComponent } from './CreateComponent';

export const topTours1Component = createComponent({
  type: 'topTours1',
  title: 'Best Offers',
  initialValues: {
    title: '',
    items: Array(2).fill({
      heading: '',
      title: '',
      description: '',
      image1Url: '',
      image2Url: '',
      image3Url: '',
      image4Url: '',
      url: '',
    }),
  },
  form: [
    {
      type: FormElementType.Input,
      name: 'title',
      validation: Yup.string().min(2).max(128),
      props: {
        placeholder: 'Enter Section Title',
        label: 'Title',
      },
    },
    {
      type: FormElementType.Array,
      name: 'items',
      // validation: Yup.string().min(2).max(128),
      props: {
        minItems: 2,
        maxItems: 2,
        itemsWrapper: (props) => (
          <div {...props} className="grid md:grid-cols-2 gap-4" />
        ),
        wrapper: (props) => (
          <div
            {...props}
            className="p-4 border rounded-lg flex flex-col gap-3"
          />
        ),
        divider: false,
        form: [
          {
            type: FormElementType.Input,
            name: 'heading',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Best Offers',
              label: 'Item Heading',
            },
          },
          {
            type: FormElementType.Input,
            name: 'title',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Enter Title',
              label: 'Item Title',
            },
          },
          {
            type: FormElementType.Textarea,
            name: 'description',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Enter Description',
              label: 'Item Description',
            },
          },

          {
            type: FormElementType.FilePicker,
            name: 'image1Url',
            validation: Yup.string().required(),
            props: {
              image: true,
              placeholder: 'Pick Image',
              label: 'Item Image 1',
            },
          },
          {
            type: FormElementType.FilePicker,
            name: 'image2Url',
            validation: Yup.string().required(),
            props: {
              image: true,
              placeholder: 'Pick Image',
              label: 'Item Image 2',
            },
          },
          {
            type: FormElementType.FilePicker,
            name: 'image3Url',
            validation: Yup.string().required(),
            props: {
              image: true,
              placeholder: 'Pick Image',
              label: 'Item Image 3',
            },
          },
          {
            type: FormElementType.FilePicker,
            name: 'image4Url',
            validation: Yup.string().required(),
            props: {
              image: true,
              placeholder: 'Pick Image',
              label: 'Item Image 4',
            },
          },
          {
            type: FormElementType.Input,
            name: 'url',
            validation: Yup.string().url().required(),
            props: {
              placeholder: 'Enter Url',
              label: 'Item Url',
            },
          },
        ],
      },
    },
  ],
  image: TOP_TOURS1,
});

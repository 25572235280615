import { Skeleton, SkeletonText } from '@chakra-ui/react';

export default function FormSkeleton({ height, button, className }) {
  if (button) {
    return (
      <Skeleton className={'w-full !rounded-xl ' + (className ?? '')} h={10} />
    );
  }
  return (
    <div className={className}>
      <SkeletonText className="mb-5" w={50} noOfLines={1} skeletonHeight={3} />
      <Skeleton className="w-full" h={height || 10} />
    </div>
  );
}

import { useSeoData, updateSeoData } from 'lib/api/globals.api';
import React from 'react';
import {
  Skeleton,
  SkeletonText,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import Card from 'components/card/Card';
import useForm from 'components/formik/useForm';
import { FormElementType } from 'components/formik/FormElement';
import { useMemo } from 'react';
import { isSuccessful } from 'lib/services/api.service';

export default function GlobalSeoComponent() {
  const { data, error, mutate } = useSeoData();
  if (data) return <RealComponent data={data} mutate={mutate} />;
  return <>LOADING...</>;
}

function RealComponent(props) {
  const { data, mutate } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const onSubmit = async (values) => {
    const res = await updateSeoData(values);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    await mutate();
    // formik.resetForm({ values });
  };
  const initialValues = useMemo(
    () => ({
      defaultTitle: data.defaultTitle || '',
      defaultDescription: data.defaultDescription || '',
      defaultKeywords: data.defaultKeywords || [],
      googleAnalyticsId: data.googleAnalyticsId || '',
      alexaId: data.alexaId || '',
      facebookPixelId: data.facebookPixelId || '',
      allowIndex: data.allowIndex ?? false,
    }),
    [data],
  );

  const form = useMemo(() => {
    return [
      {
        type: FormElementType.Input,
        name: 'defaultTitle',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Title',
          label: 'Default Title',
        },
      },
      {
        type: FormElementType.Textarea,
        name: 'defaultDescription',
        validation: Yup.string().max(1024),
        props: {
          placeholder: 'Enter Description',
          label: 'Default Description',
        },
      },
      {
        type: FormElementType.Tags,
        name: 'defaultKeywords',
        props: {
          placeholder: 'Type keywords...',
          label: 'Default Keywords',
        },
      },
      {
        type: FormElementType.Input,
        name: 'googleAnalyticsId',
        validation: Yup.string().min(2),
        props: {
          label: 'Google Analytics Id',
        },
      },
      {
        type: FormElementType.Input,
        name: 'alexaId',
        validation: Yup.string().min(2),
        props: {
          label: 'Alexa Id',
        },
      },
      {
        type: FormElementType.Input,
        name: 'facebookPixelId',
        validation: Yup.string().min(2),
        props: {
          label: 'Facebook Pixel Id',
        },
      },
      {
        type: FormElementType.Switch,
        name: 'allowIndex',
        props: {
          label: 'Allow Google to index your site',
        },
      },
    ];
  }, [data.permalink, data.lang]);

  const { formik, SubmitButton, AllElements } = useForm({
    form,
    initialValues,
    onSubmit,
  });

  return (
    <Card className="gap-4">
      <Text
        className="mb-3"
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="xl"
        align="center"
      >
        SEO
      </Text>
      {AllElements}
      <SubmitButton className="mt-4" />
    </Card>
  );
}

import { post, del, useFetchPost } from '../services/api.service';

export const useFormsPagination = (model) => {
  return useFetchPost(['/forms/paginate', model]);
};

export const useFormAnswersPagination = (id, model) => {
  return useFetchPost([`/forms/answers/paginate/${id}`, model]);
};

export const createForm = (model) => {
  return post('/forms/create', model);
};

export const updateForm = (id, model) => {
  return post(`/forms/update/${id}`, model);
};

export const deleteForm = (id) => {
  return del(`/forms/${id}`);
};

import { Box, Button, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAlertContext } from 'components/alert-dialog';
import PageCard from 'components/page-components/page-card';
import AddPageButton from 'components/page-components/add-page-button';
import AddPageModal from 'components/page-components/add-page-modal';

import { isSuccessful } from 'lib/services/api.service';
import { addBlogPost, useBlogPosts } from 'lib/api/blogs.api';
import { defaultLang } from 'lib/constants';
import BlogSeoForm from '../blog/components/blog-seo-form';

export default function BlogPosts() {
  const { id } = useParams();
  const history = useHistory();
  const { openAlert, closeAlert } = useAlertContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const blogPosts = useBlogPosts(id, { page, pageSize });

  const onBack = () => {
    history.replace('/admin/blog');
  };

  const openBlogPost = (post, lang = defaultLang) => {
    const url = `/admin/blog/post/${post.id}/${lang}`;
    history.push(url);
  };
  const handleClone = async (post) => {
    // TODO:
  };

  const handleToggleDisabled = async (post) => {
    openAlert({
      title: post.disabled ? 'Enable post' : 'Disable post',
      description: `Are you sure? "${post.name}" will be ${
        post.disabled ? 'enabled' : 'disabled'
      }`,
      onConfirm: async () => {
        // make the request
        console.log(post);
        closeAlert();
      },
    });
  };
  const handleDelete = async (post) => {};

  const handleAddBlogPost = async (values) => {
    const res = await addBlogPost(id, values);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    blogPosts.mutate();
    onClose();
    openBlogPost(res.data.data);
  };

  return (
    <Box>
      <Button className="mb-4" onClick={() => onBack()}>
        Back
      </Button>

      <div>
        <div className="grid md:grid-cols-2 lg:grid-cols-10 gap-4 lg:gap-8">
          <div className="lg:col-span-7 mb-4">
            <div className="flex flex-wrap gap-4">
              <div className="w-full sm:w-96">
                <AddPageButton onClick={onOpen} />
              </div>
              {blogPosts.isLoading
                ? Array(10)
                    .fill('')
                    .map((_, idx) => (
                      <div className="w-full sm:w-96 max-w-full" key={idx}>
                        <PageCard />
                      </div>
                    ))
                : blogPosts?.data?.items.map((blog) => (
                    <div className="w-full sm:w-96 max-w-full" key={blog.id}>
                      <PageCard
                        data={blog}
                        onClick={(lang) => openBlogPost(blog, lang)}
                        onToggleDisabled={handleToggleDisabled}
                        onClone={handleClone}
                        onDelete={handleDelete}
                      />
                    </div>
                  ))}
            </div>
          </div>
          <div className="lg:col-span-3">
            <BlogSeoForm id={id} lang={defaultLang} />
          </div>
        </div>
      </div>

      <AddPageModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleAddBlogPost}
        title="Add Post"
        // permalinkPrefix="/en/blog/post/id/"
        permalinkPrefix="/blog/post/"
        image
      />
    </Box>
  );
}

import SeoForm from 'components/page-components/seo-form';
import { updateBlogSeo, useBlog } from 'lib/api/blogs.api';
import { isSuccessful } from 'lib/services/api.service';

export default function BlogSeoForm(props) {
  const { id, lang, onClose } = props;

  const { data, error, isLoading, mutate } = useBlog(id, lang);

  const onSubmitSeo = async (values) => {
    const res = await updateBlogSeo(id, lang, values);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    onClose?.();
    mutate({ data: { ...data, ...values } });
  };

  if (error) {
    return <>An error has occurred</>;
  }
  if (isLoading) {
    return <SeoForm />;
  }

  return (
    <SeoForm
      data={data}
      onSubmit={onSubmitSeo}
      getRoute={(val) => `/blog/${val}`}
      permalinkPrefix={`/blog/`}
      excludePrefix="post"
      hasImage
    />
  );
}

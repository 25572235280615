import clsx from 'clsx';
import { useMemo } from 'react';

export default function FileItem(props) {
  const { onSelect, data, selectedPath } = props;

  const icon = useMemo(() => {
    return preparePreview(data);
  }, [data.ext]);

  return (
    <li
      onClick={() => onSelect(data)}
      className={clsx('file', {
        selected: data.path === selectedPath,
      })}
      title={data.name}
    >
      <div className="details name file-item">
        <i className={icon}></i>
        {data.isImage && <img src={data.path} alt={data.name} />}
        {/*{ f.isAudio && (<img src={f.path} alt={f.name} />) }*/}
        {/*{ f.isVideo && (<img src={f.path} alt={f.name} />) }*/}
        <span>{data.name}</span>
      </div>
    </li>
  );
}

const preparePreview = (file) => {
  switch (file.ext.toLowerCase()) {
    case '.png':
    case '.jpg':
    case '.jpeg':
    case '.bmp':
    case '.webp':
      //   file.isImage = true;
      return 'ap-icon-file-picture';
    case '.mp4':
    case '.mkv':
    case '.avi':
    case '.ogv':
      //   file.isVideo = true;
      return 'ap-icon-file-video';

    case '.mp3':
    case '.wave':
    case '.wav':
    case '.ogg':
      //   file.isAudio = true;
      return 'ap-icon-file-music';

    case '.doc':
    case '.docx':
    case '.rtf':
      return 'ap-icon-file-word';

    case '.ppt':
    case '.pptx':
      return 'ap-icon-file-presentation';
    case '.xls':
    case '.xlsx':
    case '.csv':
      return 'ap-icon-file-excel';

    case '.pdf':
      return 'ap-icon-file-pdf';

    default:
      return 'ap-icon-file-empty';
  }
};

import { v4 as Guid } from 'uuid';
import { DEVICE_KEY } from '../constants';

const _repository = {};

const readFromStore = (key, defaultValue = '', toObject = false) => {
  const content = localStorage.getItem(key) || defaultValue;
  return toObject ? JSON.parse(content) : content;
};

const readFromStoreOnce = (key, defaultValue = '', toObject = false) => {
  let content;
  if (_repository[key]) {
    content = _repository[key];
  } else {
    _repository[key] = content = localStorage.getItem(key) || defaultValue;
  }

  return toObject ? JSON.parse(content) : content;
};

const writeToStore = (key, value) => {
  const json = typeof value !== 'string' ? JSON.stringify(value) : value;
  localStorage.setItem(key, json);
};
const removeFromStore = (key) => {
  localStorage.removeItem(key);
};

const ensureDeviceId = () => {
  let deviceId = readFromStore(DEVICE_KEY);
  if (!deviceId) {
    deviceId = Guid();
    writeToStore(DEVICE_KEY, deviceId);
  }
};

ensureDeviceId();

export { readFromStore, readFromStoreOnce, writeToStore, removeFromStore };

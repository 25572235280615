import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import Fieldset from 'components/fieldset';
import { FormElementType } from 'components/formik/FormElement';
import useForm from 'components/formik/useForm';
import { useMemo } from 'react';
import { FormikFormGenerator } from 'views/admin/page/components/FormikFormGenerator';
import * as Yup from 'yup';

export default function FormGeneratorModal(props) {
  const { data, isOpen, onClose, onSubmit } = props;
  const initialValues = useMemo(() => {
    return {
      title: data?.title || '',
      description: data?.description || '',
      data: data?.data || {
        elements: [],
      },
      disabled: data?.disabled || false,
      expireDate: data?.expireDate || '',
    };
  }, [data]);

  const { formik, SubmitButton, AllElements } = useForm({
    onSubmit,
    form,
    initialValues,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="md:!max-w-screen-sm">
        <ModalHeader className="capitalize">
          {data ? 'Edit Form' : 'Create Form'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          {AllElements}
          <SubmitButton>Save</SubmitButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
const form = [
  {
    type: FormElementType.Input,
    name: 'title',
    validation: Yup.string().required(),
    props: {
      placeholder: 'Enter Title',
      label: 'Title',
    },
  },
  {
    type: FormElementType.Textarea,
    name: 'description',

    validation: Yup.string().max(1024),
    props: {
      placeholder: 'Enter Description',
      label: 'Description',
    },
  },
  {
    type: FormElementType.Custom,
    name: 'data.elements',
    validation: Yup.array()
      .min(1, 'You should add at least 1 element')
      .required(),
    component: (props) => <FormikFormGenerator {...props} />,
  },
  {
    type: FormElementType.Switch,
    name: 'disabled',
    props: { label: 'Disabled', inline: true },
  },
  {
    type: FormElementType.Switch,
    name: 'data.customEndpoint',
    initialValue: false,
    props: { label: 'Custom endpoint', inline: true },
  },
  {
    type: FormElementType.Container,
    name: 'data.requestConfig',
    // hidden: (formik) => !formik?.values.data.customEndpoint,
    props: {
      wrapper: ({ children }) => (
        <Fieldset title="Request Config:">
          {/* <div className="border border-border rounded-lg p-5" {...other}> */}
          {/* <div>Request Config</div> */}
          <div className="grid gap-4 grid-cols-2">{children}</div>
          {/* </div> */}
        </Fieldset>
      ),
      form: [
        {
          type: FormElementType.Input,
          name: 'data.requestConfig.url',
          props: {
            placeholder: 'Enter Url',
            label: 'Url',
            // className: 'md:col-span-2 lg:col-span-3',
          },
        },
        {
          type: FormElementType.Select,
          name: 'data.requestConfig.method',
          props: {
            placeholder: 'Select Method...',
            label: 'Method',
            options: [
              { title: 'post', value: 'post' },
              { title: 'patch', value: 'patch' },
              { title: 'put', value: 'put' },
              { title: 'get', value: 'get' },
            ],
          },
        },
      ],
    },
  },
];

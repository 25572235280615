import { Input, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';
import FormControl from '../FormControl';
import useFormHelpers from '../useFormHelpers';
import { useState } from 'react';

export default function FormikTags(props) {
  const { formik, onChange, name } = props;
  const {
    controlProps,
    componentProps: { value, isInvalid, disabled },
    ...others
  } = useFormHelpers(props);

  const [inputVal, setInputVal] = useState('');

  const handleChange = (newValue) => {
    formik.setFieldValue(name, newValue);
    onChange?.(newValue);
  };

  const onRemove = (tag) => {
    handleChange(value.filter((v) => v !== tag));
  };

  const onKeyDown = (e) => {
    if (e.key !== 'Enter' || !value) return;
    const newItem = e.target?.value?.trim();
    if (!newItem?.length) return;
    if (value.includes(newItem)) return;

    const newValue = [...value, newItem];
    handleChange(newValue);

    setInputVal('');
  };

  return (
    <FormControl {...controlProps}>
      {Boolean(value?.length) && (
        <div className="flex gap-1 flex-wrap mb-4">
          {value?.map((tag) => (
            <Tag
              key={tag}
              borderRadius="full"
              variant="solid"
              colorScheme="brand"
            >
              <TagLabel>{tag}</TagLabel>
              <TagCloseButton onClick={() => onRemove(tag)} />
            </Tag>
          ))}
        </div>
      )}
      <Input
        disabled={disabled}
        isInvalid={isInvalid}
        {...others}
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </FormControl>
  );
}

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormElementType } from 'components/formik/FormElement';
import useForm from 'components/formik/useForm';
import { useMemo, useEffect } from 'react';
import * as Yup from 'yup';
import { useMenuQuery } from 'lib/api/menu.api';

// Banner = "banner",
// Steps = "steps",
// Carousel = "carousel",
// Html = "html",
export const LinkType = {
  Page: 1,
  Blog: 2,
  Post: 3,
  External: 4,
};

function getTypeTitle(type) {
  switch (+type) {
    case LinkType.Page:
      return 'Page';
    case LinkType.Blog:
      return 'Blog';
    case LinkType.Post:
      return 'Post';
    default:
      return '';
  }
}
const validationSchema = Yup.object({
  title: Yup.string().min(2).max(256).required('Required'),
  type: Yup.number().required(),
  // url: Yup.string().when('type', {
  //   is: `${LinkType.External}`,
  //   then: Yup.string().required(),
  //   otherwise: Yup.string(),
  // }),

  // id: Yup.string().when('type', {
  //   is: (...args) => {
  //     console.log(args);
  //     return true;
  //   },
  //   then: Yup.string().required(),
  //   otherwise: Yup.string(),
  // }),
});

export default function LinkPickerModal(props) {
  const { onClose, data, isOpen, onSubmit } = props;

  const initialValues = useMemo(() => {
    const { title = '', type = '', id = '', url = '' } = data || {};
    return { title, type, id, url };
  }, [data]);

  const { formik, SubmitButton, AllElements } = useForm({
    onSubmit,
    validationSchema,
    form: [
      {
        type: FormElementType.Input,
        name: 'title',
        props: {
          placeholder: 'Enter Title',
          label: 'Title',
        },
      },
      {
        type: FormElementType.Select,
        name: 'type',
        props: {
          placeholder: 'Select Type...',
          label: 'Type',
          onChange: () =>
            formik.setValues({ ...formik.values, id: '', url: '' }),
          options: [
            { title: 'Page', value: 1 },
            { title: 'Blog', value: 2 },
            { title: 'Post', value: 3 },
            { title: 'External', value: 4 },
          ],
        },
      },
      (formik) => ({
        type: FormElementType.Input,
        name: 'url',
        hidden: +formik?.values.type !== LinkType.External,
        props: {
          placeholder: 'https://example.com...',
          label: 'Link',
        },
      }),
      (formik) => ({
        type: FormElementType.Autocomplete,
        name: 'id',
        hidden: ![LinkType.Page, LinkType.Blog, LinkType.Post].includes(
          +formik?.values.type,
        ),

        props: {
          placeholder: 'Select...',
          label: getTypeTitle(formik?.values.type),
          keySelector: (item) => item.id,
          valueSelector: (item) => item.id,
          onChange: (item) => {
            const prefix = (() => {
              switch (+formik.values.type) {
                case LinkType.Page:
                  return '/';
                case LinkType.Blog:
                  return '/blog/';
                case LinkType.Post:
                  return '/blog/post/';
                default:
                  return '';
              }
            })();
            return formik.setFieldValue('url', `${prefix}${item.permalink}`);
          },
          // formik.setFieldValue('url', `/${item.index}/${item.permalink}`),
          useBackend: (query) =>
            useMenuQuery({
              query,
              type: +formik.values.type,
              lang: 'en',
            }),
        },
      }),
    ],
    initialValues,
  });

  useEffect(() => {
    formik.resetForm({ values: initialValues });
  }, [isOpen]);
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{data ? 'Edit' : 'Add'} Link</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex flex-col gap-4">{AllElements}</div>
        </ModalBody>

        <ModalFooter>
          <Button disabled={formik.isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <SubmitButton>Save</SubmitButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export function toPermalink(str) {
  if (!str) return str;
  return str.trim().replaceAll(' ', '-').replaceAll('/', '').toLowerCase();
}
export function encodeData(jsonData) {
  const jsonString = JSON.stringify(jsonData);
  const encoder = new TextEncoder();
  const data = encoder.encode(jsonString);
  return btoa(String.fromCharCode.apply(null, data));
}

// function decodeData(base64Data) {
//   const decoder = new TextDecoder('utf-8');
//   const binaryString = atob(base64Data);
//   const data = new Uint8Array(binaryString.length);
//   for (let i = 0; i < binaryString.length; i++) {
//     data[i] = binaryString.charCodeAt(i);
//   }
//   const jsonString = decoder.decode(data);
//   const jsonData = JSON.parse(jsonString);
//   return jsonData;
// }

import { Box, Grid, useDisclosure } from '@chakra-ui/react';
import { useAlertContext } from 'components/alert-dialog';
import AddPageButton from 'components/page-components/add-page-button';
import ComponentCard from 'components/page-components/page-card';
import { createComponent } from 'lib/api/shared-components.api';
import { deleteComponent } from 'lib/api/shared-components.api';
import { toggleComponentVisibility } from 'lib/api/shared-components.api';
import { useSharedComponents } from 'lib/api/shared-components.api';
import { isSuccessful } from 'lib/services/api.service';
import React, { useState } from 'react';
import { AddSectionModal } from '../page/components/PageComponents/AddSectionModal';
import Pagination from 'components/pagination/Pagination';
import { ComponentInfoModal } from './components/component-info-modal';
import { EditSectionModal } from '../page/components/PageComponents/EditSectionModal';
import { defaultLang } from 'lib/constants';
import { useSharedComponentDetails } from 'lib/api/shared-components.api';
import { updateSharedComponent } from 'lib/api/shared-components.api';

export default function SharedComponent() {
  const [page, setPage] = useState(1);
  const [info, setInfo] = useState();
  const pageSize = 20;
  const { data, error, isLoading, mutate } = useSharedComponents({
    pageSize,
    page,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editComponent, setEditComponent] = useState();
  const { openAlert, closeAlert } = useAlertContext();

  const componentDetail = useSharedComponentDetails(
    editComponent?.id,
    editComponent?.lang,
  );

  const handleDelete = async (component) => {
    openAlert({
      title: 'Delete component',
      variant: 'delete',
      description: `Are you sure? "${component.name}" will be deleted permanently`,
      onConfirm: async () => {
        const res = await deleteComponent(component.id);
        if (!isSuccessful(res)) {
          // TODO: HANDLE ERROR
          closeAlert();
          return;
        }
        await mutate();
        closeAlert();
      },
    });
  };
  const handleToggleDisabled = async (component) => {
    openAlert({
      title: component.hidden ? 'Enable component' : 'Disable component',
      description: `Are you sure? "${component.name}" will be ${
        component.hidden ? 'enabled' : 'disabled'
      }`,
      onConfirm: async () => {
        const res = await toggleComponentVisibility(component.id);
        closeAlert();
        if (!isSuccessful(res)) {
          // TODO: HANDLE ERROR
          return;
        }
        mutate({
          data: {
            ...data,
            items: data.items.map((item) => {
              if (item.id === component.id) item.hidden = !item.hidden;
              return item;
            }),
          },
        });
      },
    });
  };

  const handleAddComponent = async (values) => {
    const { componentName, componentDescription, ...payload } = values;
    // TODO: handle edit
    if (info.id) return;
    const model = {
      name: componentName,
      description: componentDescription,
      payload: JSON.stringify(payload),
      type: info.type,
    };
    const res = await createComponent(model);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate();
    setInfo(null);
  };
  const onAdd = () => onOpen();
  const handleSelectTemplate = ({ type }) => {
    setInfo({ type });
    onClose();
  };

  const onSubmitEdit = async (values) => {
    if (!editComponent) return;
    const res = await updateSharedComponent({
      id: editComponent.id,
      lang: editComponent.lang,
      payload: JSON.stringify(values),
    });
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    componentDetail.mutate();
    setEditComponent(null);
  };
  const onEditComponent = (component, lang = defaultLang) => {
    setEditComponent({ ...component, lang });
  };

  return (
    <Box>
      {error ? (
        // TODO: Handle error
        <div>An error has occurred</div>
      ) : (
        <div className="flex flex-wrap gap-4">
          <div className="w-full sm:w-96">
            <AddPageButton onClick={onAdd} />
          </div>

          {isLoading
            ? Array(10)
                .fill('')
                .map((_, idx) => (
                  <div className="w-full sm:w-96 max-w-full" key={idx}>
                    <ComponentCard />
                  </div>
                ))
            : data?.items.map((component) => (
                <div className="w-full sm:w-96 max-w-full" key={component.id}>
                  <ComponentCard
                    data={component}
                    onToggleDisabled={handleToggleDisabled}
                    onDelete={handleDelete}
                    onClick={(lang) => onEditComponent(component, lang)}
                  />
                </div>
              ))}
        </div>
      )}
      {Boolean(data?.totalPages > 1) && (
        <Pagination
          page={page}
          totalPages={data?.totalPages}
          onPageChange={(p) => setPage(p)}
        />
      )}

      <AddSectionModal
        isOpen={isOpen}
        onClose={() => onClose()}
        onSelect={handleSelectTemplate}
        mode="template"
        title="Select Template"
      />
      {Boolean(editComponent) && (
        <EditSectionModal
          title={`Edit Component (${editComponent.name}-${editComponent.lang})`}
          data={componentDetail.data}
          onClose={() => setEditComponent(null)}
          onSubmit={onSubmitEdit}
          isParent
        />
      )}
      {info && (
        <ComponentInfoModal
          data={info}
          onClose={() => setInfo(null)}
          onSubmit={handleAddComponent}
        />
      )}
    </Box>
  );
}

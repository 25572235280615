import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import FormControl from '../FormControl';
import useFormHelpers from '../useFormHelpers';
import './styles.css';

const config = {
  allowedContent: true,
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      // {
      //   model: 'heading1',
      //   view: 'h1',
      //   title: 'Heading 1',
      //   class: 'heading-1',
      // },
      // {
      //   model: 'heading2',
      //   view: 'h2',
      //   title: 'Heading 2',
      //   class: 'heading-2',
      // },

      {
        model: 'heading3',
        title: 'h3',
        view: {
          name: 'h3',
          classes: 'heading-3',
        },
      },
      {
        model: 'heading4',
        title: 'h4',
        view: {
          name: 'h4',
          classes: 'heading-4',
        },
      },
      {
        model: 'heading5',
        title: 'h5',
        view: {
          name: 'h5',
          classes: 'heading-5',
        },
      },
    ],
  },
  // style: {
  //   definitions: [
  //     {
  //       name: 'Heading 3',
  //       element: 'h3',
  //       classes: ['heading-3'],
  //     },
  //     {
  //       name: 'Heading 5',
  //       element: 'p',
  //       classes: ['heading-5'],
  //     },
  //   ],
  // },
  // fontColor: {
  //   colors: [
  //     {
  //       color: 'hsl(0, 0%, 0%)',
  //       label: 'Black',
  //     },
  //     {
  //       color: 'hsl(0, 0%, 30%)',
  //       label: 'Dim grey',
  //     },
  //     {
  //       color: 'hsl(0, 0%, 60%)',
  //       label: 'Grey',
  //     },
  //     {
  //       color: 'hsl(0, 0%, 90%)',
  //       label: 'Light grey',
  //     },
  //     {
  //       color: 'hsl(0, 0%, 100%)',
  //       label: 'White',
  //       hasBorder: true,
  //     },
  //     // More colors.
  //     // ...
  //   ],
  // },
  // fontSize: {
  //   options: ['tiny', 'small', 'default', 'big', 'huge'],
  // },
};

export default function FormikTextEditor(props) {
  const { formik, onChange, name } = props;
  const {
    controlProps,
    componentProps: { value, isInvalid, disabled },
    leftAddon,
    rightElement,
    onBlur,
    ...others
  } = useFormHelpers(props);

  const handleBlur = (event, editor) => {
    onBlur?.(event, editor);
    formik.setFieldValue(name, editor.getData());
  };

  return (
    <FormControl {...controlProps}>
      <CKEditor
        editor={Editor}
        data={value}
        config={config}
        // onReady={(editor) => {
        //   // You can store the "editor" and use when it is needed.
        //   console.log('Editor is ready to use!', editor);
        // }}
        // onChange={(event, editor) => {
        //   const data = editor.getData();
        //   console.log({ event, editor, data });
        // }}
        onBlur={handleBlur}
        // onFocus={(event, editor) => {
        //   console.log('Focus.', editor);
        // }}
        {...others}
      />
    </FormControl>
  );
}

import routes from '../routes.js';
import { Route } from 'react-router-dom';

const findActivePath = (def, field) => {
  let activeRoute = def;
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse) {
      let collapseActiveRoute = findActivePath(routes[i].items);
      if (collapseActiveRoute !== activeRoute) {
        return collapseActiveRoute;
      }
    } else if (routes[i].category) {
      let categoryActiveRoute = findActivePath(routes[i].items);
      if (categoryActiveRoute !== activeRoute) {
        return categoryActiveRoute;
      }
    } else {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i][field];
      }
    }
  }
  return activeRoute;
};
const getActiveRoute = () => {
  return findActivePath('B2C Cms', 'name');
};
const getActiveNavbar = () => {
  return findActivePath(false, 'secondary');
};
const getActiveNavbarText = () => {
  return findActivePath(false, 'messageNavbar');
};
const getRoutes = (path) => {
  return routes.map((prop, key) => {
    if (prop.layout === path) {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    }
    if (prop.collapse) {
      return getRoutes(prop.items, path);
    }
    if (prop.category) {
      return getRoutes(prop.items, path);
    } else {
      return null;
    }
  });
};

export {
  getActiveRoute,
  getActiveNavbar,
  getActiveNavbarText,
  getRoutes,
  routes,
};

import { Fragment } from 'react';
import FormikInput from './Input';
import FormikTags from './Tags';
import FormikTextarea from './Textarea';
import { IconButton } from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { getIn } from 'formik';
import FormikSelect from './Select';
import FormikAutocomplete from './Autocomplete';
import FormikMultiAutocomplete from './MultiAutocomplete';
import FormikSwitch from './Switch';
import FormikColorPicker from './ColorPicker';
import FormikFilePicker from './FilePicker';
import FormikTextEditor from './TextEditor';
import FormikLinkPicker from './LinkPicker';

export const FormElementType = {
  Input: 1,
  Textarea: 2,
  Tags: 3,
  Select: 4,
  Autocomplete: 5,
  Switch: 6,
  ColorPicker: 7,
  FilePicker: 8,
  TextEditor: 9,
  LinkPicker: 10,
  MultiAutocomplete: 11,
  Array: 20,
  Container: 30,
  Custom: 40,
};

export default function FormElement(properties) {
  const { type, name, props, formik, component: Component } = properties;
  switch (type) {
    case FormElementType.Input:
      return <FormikInput name={name} formik={formik} {...props} />;
    case FormElementType.Textarea:
      return <FormikTextarea name={name} formik={formik} {...props} />;
    case FormElementType.Tags:
      return <FormikTags name={name} formik={formik} {...props} />;
    case FormElementType.Select:
      return <FormikSelect name={name} formik={formik} {...props} />;
    case FormElementType.Autocomplete:
      return <FormikAutocomplete name={name} formik={formik} {...props} />;
    case FormElementType.MultiAutocomplete:
      return <FormikMultiAutocomplete name={name} formik={formik} {...props} />;
    case FormElementType.Switch:
      // console.log({ name, type, props, formik });
      return <FormikSwitch name={name} formik={formik} {...props} />;
    case FormElementType.ColorPicker:
      return <FormikColorPicker name={name} formik={formik} {...props} />;
    case FormElementType.FilePicker:
      return <FormikFilePicker name={name} formik={formik} {...props} />;
    case FormElementType.TextEditor:
      return <FormikTextEditor name={name} formik={formik} {...props} />;
    case FormElementType.LinkPicker:
      return <FormikLinkPicker name={name} formik={formik} {...props} />;
    case FormElementType.Array:
      return <FormikArray name={name} formik={formik} {...props} />;
    case FormElementType.Container:
      return <FormikContainer formik={formik} {...props} />;
    case FormElementType.Custom:
      return <Component name={name} formik={formik} {...props} />;
    default:
      return (
        <div>
          UNKNOWN TYPE {type} FOR ELEMENT {name}
        </div>
      );
  }
}

function FormikArray(props) {
  const {
    name,
    formik,
    form,
    wrapper: Wrapper = Fragment,
    itemsWrapper: ItemsWrapper = Fragment,
    divider,
    label,
    emptyValues,
    minItems = 1,
    maxItems,
  } = props;

  const value = getIn(formik.values, name) || [];

  const pushToField = () => {
    formik.setFieldValue(name, [...value, emptyValues]);
  };
  const removeFromField = (index) => {
    formik.setFieldValue(
      name,
      value.filter((_, idx) => idx !== index),
    );
  };

  return (
    <>
      <div className="flex">
        <div className="grow text-lg font-semibold">{label}</div>
        {(!maxItems || value?.length < maxItems) && (
          <IconButton onClick={pushToField} icon={<AddIcon />} />
        )}
      </div>
      <ItemsWrapper>
        {value.map((_, index) => (
          <Wrapper key={`${name}[${index}]`}>
            {value.length > minItems && (
              <div className="relative col-span-full">
                <IconButton
                  onClick={() => removeFromField(index)}
                  className="!absolute end-0 z-10"
                  icon={<DeleteIcon />}
                />
              </div>
            )}
            {form.map((element) => {
              const el =
                typeof element === 'function' ? element(formik) : element;

              if (el.hidden instanceof Function ? el.hidden(formik) : el.hidden)
                return null;

              return (
                <FormElement
                  key={`${name}[${index}].${el.name}`}
                  type={el.type}
                  props={el.props}
                  name={`${name}[${index}].${el.name}`}
                  formik={formik}
                  component={el.component}
                  {...el.props}
                />
              );
            })}

            {divider !== false && <hr className="col-span-full" />}
          </Wrapper>
        ))}
      </ItemsWrapper>
    </>
  );
}
function FormikContainer(props) {
  const { formik, form, wrapper: Wrapper = Fragment } = props;
  // console.log(props);

  return (
    <Wrapper>
      {form.map((element) => {
        const { name, type, props, hidden, component } =
          typeof element === 'function' ? element(formik) : element;
        if (hidden instanceof Function ? hidden(formik) : hidden) return null;

        return (
          <FormElement
            key={element.name}
            {...{
              name,
              type,
              props,
              hidden,
              component,
              formik,
            }}
          />
        );
      })}
    </Wrapper>
  );
}

import { Box, useDisclosure } from '@chakra-ui/react';
import Footer from '../../components/footer/FooterAdmin.js';
import Sidebar from '../../components/sidebar/Sidebar.js';
import { SidebarContext } from '../../contexts/SidebarContext';
import React, { useEffect, useState } from 'react';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { getRoutes, routes } from '../layout.helper';

export default function Dashboard(props) {
  const history = useHistory();
  const { ...rest } = props;
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  useEffect(() => {
    if (!global.$app.token) {
      history.push('/auth/sign-in');
      return;
    }
  }, []);

  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar
            routes={routes.filter((r) => !r.hidden && r.layout === '/admin')}
            display="none"
            {...rest}
          />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            overflow="auto"
            position="relative"
            maxHeight="100%"
            w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Box
              mx="auto"
              p={{ base: '20px', md: '30px' }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Switch>
                {getRoutes('/admin')}
                <Redirect from="/" to="/admin/dashboard" />
              </Switch>
            </Box>
            {/* <Box>
              <Footer />
            </Box> */}
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}

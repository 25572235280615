const environment = {
  PLATFORM_CLIENT_URL: 'http://localhost:3001',
  PLATFORM_CMS_API_URL: 'http://localhost:3000/api',
  PLATFORM_CDN_API_URL: 'https://client-cdn.zarenplatform.com/api',
  PLATFORM_CLIENT_API_URL: 'http://localhost:3000/b2c-api',
};
const get = (environment, key, attr, fallback) => {
  if (process.env.NODE_ENV === 'development') {
    return environment[key];
  }
  const provided = document.body.dataset[attr];
  const value = provided || fallback;
  console.info(`ENV:${key}:${value}`);
  return value;
};

const CONFIG = {
  PLATFORM_CLIENT_URL: get(environment, 'PLATFORM_CLIENT_URL', 'clientUrl', ''),
  PLATFORM_CMS_API_URL: get(environment, 'PLATFORM_CMS_API_URL', 'apiUrl', ''),
  PLATFORM_CDN_API_URL: get(environment, 'PLATFORM_CDN_API_URL', 'cdnUrl', ''),
  PLATFORM_CLIENT_API_URL: get(
    environment,
    'PLATFORM_CLIENT_API_URL',
    'b2cApiUrl',
    '',
  ),
};

export { CONFIG };

import GlobalScriptsComponent from './components/global-scripts';
import GlobalSeoComponent from './components/global-seo';
import GlobalStylesComponent from './components/global-styles';

export default function GlobalsPage() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      <div className="md:order-1">
        <GlobalSeoComponent />
      </div>
      <div className="col-span-2 grid grid-cols-1 2xl:grid-cols-2 gap-8">
        <GlobalStylesComponent />
        <GlobalScriptsComponent />
      </div>
    </div>
  );
}

import { Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export default function SuperAdminLoginRoute() {
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (params.token) {
      global.$app.token = params.token;
      global.$app.storeInstance();
    }
    history.replace('/admin/dashboard');
  }, [params.token]);

  return (
    <div className="flex w-screen h-screen items-center justify-center">
      <Spinner colorScheme="brand" />
    </div>
  );
}

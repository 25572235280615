import * as Yup from 'yup';

import STEPS3 from 'assets/img/component-temps/steps3.png';
import { FormElementType } from 'components/formik/FormElement';
import { createComponent } from './CreateComponent';

export const steps3Component = createComponent({
  type: 'steps3',
  title: 'Steps 2',
  initialValues: {
    title: '',
    description: '',
    steps: [{ imageUrl: '', title: '', description: '' }],
  },
  form: [
    {
      type: FormElementType.Input,
      name: 'title',
      validation: Yup.string().min(2).max(128),
      props: {
        placeholder: 'Enter Title',
        label: 'Title',
      },
    },
    {
      type: FormElementType.Textarea,
      name: 'description',
      validation: Yup.string().min(2).max(512),
      props: {
        placeholder: 'Enter Description',
        label: 'Description',
      },
    },
    {
      type: FormElementType.Array,
      name: 'steps',
      // validation: Yup.string().min(2).max(128),
      props: {
        label: 'Steps',
        emptyValues: { imageUrl: '', title: '', description: '' },
        form: [
          {
            type: FormElementType.FilePicker,
            name: 'imageUrl',
            validation: Yup.string(),
            props: {
              image: true,
              placeholder: 'Pick Image',
              label: 'Image',
            },
          },
          {
            type: FormElementType.Input,
            name: 'title',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Enter Title',
              label: 'Title',
            },
          },
          {
            type: FormElementType.Textarea,
            name: 'description',
            validation: Yup.string().min(2).max(512),
            props: {
              placeholder: 'Enter Description',
              label: 'Description',
            },
          },
        ],
      },
    },
  ],
  image: STEPS3,
});

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { components } from './components';
import SelectSharedComponent from './SelectSharedComponent';
import TemplateCard from './TemplateCard';

export function AddSectionModal(props) {
  const { onClose, isOpen, onSelect, mode, title } = props;

  const templatesContent = (
    <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-3">
      {components.map((component) => (
        <TemplateCard
          key={component.type}
          onClick={() => onSelect({ type: component.type })}
          title={component.title || component.type}
          image={component.image}
        />
      ))}
    </div>
  );
  const componentsContent = (
    <SelectSharedComponent
      onSelect={({ type, id, name }) =>
        onSelect({ type, component_id: id, name })
      }
    />
  );

  return (
    <Modal
      onClose={onClose}
      closeOnEsc={false}
      isOpen={isOpen}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent className="md:!max-w-screen-sm lg:!max-w-screen-lg xl:!max-w-screen-xl">
        <ModalHeader>{title || 'Add Section'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {mode === 'template' ? (
            templatesContent
          ) : mode === 'component' ? (
            componentsContent
          ) : (
            <Tabs isFitted>
              <TabList mb="1em">
                <Tab className="!shadow-none">Templates</Tab>
                <Tab className="!shadow-none">Shared Components</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>{templatesContent}</TabPanel>
                <TabPanel>{componentsContent}</TabPanel>
              </TabPanels>
            </Tabs>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

import Card from 'components/card/Card';
import { Fragment, useState } from 'react';
import { SectionComponent } from './SectionComponent';
import { AddSectionButton } from './AddSectionButton';
import { AddSectionModal } from './AddSectionModal';
import { EditSectionModal } from './EditSectionModal';
import { useAlertContext } from 'components/alert-dialog';
import { isSuccessful } from 'lib/services/api.service';

import {
  addSection,
  updateSection,
  deleteSection,
  toggleVisibilitySection,
  reorderSection,
} from 'lib/api/pages.api';

export default function PageComponents(props) {
  const { data, mutate } = props;
  const { sections, id } = data;
  const { openAlert, closeAlert } = useAlertContext();
  const [addIndex, setAddIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [orderLoading, setOrderLoading] = useState(false);
  // const [index, setIndex] = useState(null);
  //   id: 'ade7d780-1ecd-4f07-af82-81b57f3d5f12';
  //   createdAt: '2023-08-25T15:58:54.270Z';
  //   index: 2;
  //   isLanding: false;
  //   lang: 'en';
  const openAddSection = (idx = 0) => {
    setAddIndex(idx);
  };

  const onAddSection = async (model) => {
    const res = await addSection(id, addIndex, model);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    // mutate({data: {...data, }});
    mutate();
    setAddIndex(null);
  };
  const onSubmitEdit = async (values) => {
    if (editIndex === null) return;
    const section = sections[editIndex];
    const res = await updateSection(section.id, values);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    // mutate({data: {...data, }});
    mutate();
    setEditIndex(null);
  };

  const handleDelete = async (id, forAll) => {
    // make request with idx
    const res = await deleteSection(id, forAll);
    if (!isSuccessful(res)) {
      // TODO: HANDLE HERE
      return;
    }
    closeAlert();
    mutate();
  };
  const onDelete = (section) => {
    openAlert({
      title: 'Delete Page Section',
      description: `This section will be deleted.`,
      actions: [
        // {
        //   onClick: () => handleDelete(section.id),
        //   colorScheme: 'red',
        //   variant: 'outline',
        //   children: 'Delete',
        // },
        {
          onClick: () => handleDelete(section.section_id, true),
          colorScheme: 'red',
          children: 'Delete All',
        },
      ],
    });
  };
  const toggleVisibility = (section) => {
    openAlert({
      title: section.hidden ? 'Show' : 'Hide' + ' Page Section',
      description: `Are you sure? this section will be ${
        section.hidden ? 'visible to' : 'hidden from'
      } all users.`,
      onConfirm: async () => {
        // make request with idx
        const res = await toggleVisibilitySection(section.id);
        if (!isSuccessful(res)) {
          // TODO: HANDLE HERE
          return;
        }
        closeAlert();
        mutate();
      },
      // variant: '',
    });
  };
  const reorder = async (idx, targetIdx) => {
    setOrderLoading(true);
    const oldSections = [...sections];
    const newSections = [...sections];

    newSections[idx] = { ...sections[targetIdx] };
    newSections[targetIdx] = { ...sections[idx] };

    mutate({ data: { ...data, sections: newSections } }, false);
    const res = await reorderSection(sections[idx].id, targetIdx < idx);

    if (!isSuccessful(res)) {
      mutate({ data: { ...data, sections: oldSections } });
      return;
    }
    mutate();
    setOrderLoading(false);
  };

  return (
    <Card className="gap-4">
      <AddSectionButton onClick={() => openAddSection()} />
      {sections?.map((section, idx) => {
        const lastOne = idx === sections.length - 1;
        return (
          <Fragment key={section.id}>
            <SectionComponent
              onDelete={() => onDelete(section)}
              onEdit={() => setEditIndex(idx)}
              toggleVisibility={() => toggleVisibility(section)}
              onOrderDown={lastOne ? undefined : () => reorder(idx, idx + 1)}
              onOrderUp={idx === 0 ? undefined : () => reorder(idx, idx - 1)}
              data={section}
              orderLoading={orderLoading}
            />
            <AddSectionButton onClick={() => openAddSection(idx + 1)} />
          </Fragment>
        );
      })}
      <AddSectionModal
        isOpen={typeof addIndex === 'number'}
        onClose={() => setAddIndex(null)}
        onSelect={onAddSection}
      />
      {editIndex !== null && sections[editIndex] && (
        <EditSectionModal
          data={sections[editIndex]}
          onClose={() => setEditIndex(null)}
          onSubmit={onSubmitEdit}
        />
      )}
    </Card>
  );
}

import { Textarea } from '@chakra-ui/react';
import FormControl from '../FormControl';
import useFormHelpers from '../useFormHelpers';

export default function FormikTextarea(props) {
  const { controlProps, componentProps, ...others } = useFormHelpers(props);

  return (
    <FormControl {...controlProps}>
      <Textarea {...componentProps} {...others} />
    </FormControl>
  );
}

import { FormElementType } from 'components/formik/FormElement';
import * as Yup from 'yup';
import { createComponent } from './CreateComponent';
import STEPS1 from 'assets/img/component-temps/steps1.png';

export const steps1Component = createComponent({
  type: 'steps1',
  title: 'Steps 1',
  initialValues: {
    steps: [{ headline: '', title: '', description: '' }],
  },
  form: [
    {
      type: FormElementType.Array,
      name: 'steps',
      // validation: Yup.string().min(2).max(128),
      props: {
        label: 'Steps',
        emptyValues: { headline: '', title: '', description: '' },
        form: [
          {
            type: FormElementType.Input,
            name: 'headline',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Enter Headline',
              label: 'Headline',
            },
          },
          {
            type: FormElementType.Input,
            name: 'title',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Enter Title',
              label: 'Title',
            },
          },
          {
            type: FormElementType.Textarea,
            name: 'description',
            validation: Yup.string().min(2).max(512),
            props: {
              placeholder: 'Enter Description',
              label: 'Description',
            },
          },
        ],
      },
    },
  ],
  image: STEPS1,
});

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { createContext, useContext, useRef, useState } from 'react';

const defaultData = {
  title: '',
  description: "Are you sure? You can't undo this action afterwards.",
  confirmLabel: '',
  onConfirm: () => {},
  onClose: () => {},
  variant: 'confirm',
};

const AlertContext = createContext({
  closeAlert: () => {},
  openAlert: () => {},
  alertIsLoading: false,
});

export function useAlertContext() {
  return useContext(AlertContext);
}

export function AlertProvider({ children }) {
  const [data, setData] = useState(defaultData);
  const [alertIsLoading, setAlertIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const closeAlert = () => {
    setIsOpen(false);
  };
  const openAlert = (newData) => {
    setData({ ...defaultData, ...newData });
    setAlertIsLoading(false);
    setIsOpen(true);
  };

  return (
    <AlertContext.Provider value={{ closeAlert, openAlert, alertIsLoading }}>
      {children}
      <AlertModal
        data={data}
        isOpen={isOpen}
        closeAlert={closeAlert}
        isLoading={alertIsLoading}
        setIsLoading={setAlertIsLoading}
      />
    </AlertContext.Provider>
  );
}

function AlertModal(props) {
  const { data, isOpen, closeAlert, isLoading, setIsLoading } = props;
  const {
    title,
    description,
    confirmLabel,
    onConfirm,
    onClose,
    variant,
    actions,
  } = data;

  const handleClose = () => {
    if (isLoading) return;
    onClose?.();
    closeAlert();
  };
  const loadingFallback = async (fallback, e) => {
    setIsLoading(true);
    await fallback?.(e);
    setIsLoading(false);
  };

  const handleConfirm = () => {
    loadingFallback(onConfirm);
  };

  const cancelRef = useRef();
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={handleClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          {Boolean(description) && (
            <AlertDialogBody>{description}</AlertDialogBody>
          )}

          <AlertDialogFooter>
            <Button disabled={isLoading} ref={cancelRef} onClick={handleClose}>
              Cancel
            </Button>
            {actions ? (
              actions.map(({ onClick, ...action }) => (
                <Button
                  disabled={isLoading}
                  ml={3}
                  {...action}
                  onClick={(e) => loadingFallback(onClick, e)}
                />
              ))
            ) : (
              <Button
                disabled={isLoading}
                colorScheme={variant === 'delete' ? 'red' : 'brand'}
                onClick={handleConfirm}
                ml={3}
              >
                {confirmLabel || (variant === 'delete' ? 'Delete' : 'Confirm')}
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

import React, { useEffect, useMemo, useState } from 'react';
import './AchaPlorer.css';
import { AchaPlorerRibbon } from './ribbon';
import { AchaPlorerNavigationBar } from './navigation-bar';
import { AchaPlorerExplorer } from './explorer';
import {
  useFiles,
  renameFile,
  createFolder,
  deleteFile,
  uploadFiles,
} from '../../lib/api/files.api';
import InputModal from './InputModal';
import { useDisclosure } from '@chakra-ui/react';
import { isSuccessful } from 'lib/services/api.service';
import { useAlertContext } from 'components/alert-dialog';
import UploadModal from './uploadModal';

export default function AchaPlorer(props) {
  const { onSelectChange } = props;
  const [path, setPath] = useState('/');

  const { data, error, mutate, isLoading } = useFiles(path);
  const [selected, setSelected] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const upload = useDisclosure();
  const { openAlert, closeAlert } = useAlertContext();

  const siblings = useMemo(() => {
    if (!data) return [];
    return [
      ...data.files.map((f) => f.name.toLowerCase()),
      ...data.folders.map((f) => f.name.toLowerCase()),
    ];
  }, [data]);
  const handleSelectChange = (v) => {
    onSelectChange?.(v);
    setSelected(v);
  };

  useEffect(() => {
    handleSelectChange(null);
  }, [path]);

  const handleFilter = () => {};

  const onRibbonAction = async (action) => {};

  const onCreateFolder = () => {
    handleSelectChange(null);
    onOpen();
  };
  const onSubmitInput = async (value) => {
    let res;
    if (selected) {
      res = await renameFile(selected.path, value);
    } else {
      res = await createFolder(path, value);
    }
    if (!isSuccessful(res)) {
      // TODO: handle error;
      return;
    }
    await mutate();
  };

  const onSubmitUpload = async (files, callback) => {
    const res = await uploadFiles(path, files, callback);
    if (!isSuccessful(res)) {
      // TODO: handle error;
      return;
    }
    await mutate();
  };
  const onDelete = async () => {
    openAlert({
      title: 'Delete Item',
      variant: 'delete',
      // description: `Are you sure? "${page.name}" will be landing page.`,
      onConfirm: async () => {
        const res = await deleteFile(selected.path);
        if (!isSuccessful(res)) {
          // TODO: HANDLE ERROR
          return;
        }
        await mutate();
        closeAlert();
      },
    });
  };

  // TODO: HANDLE ERROR
  if (error) return <>error</>;
  return (
    <div className="achaplorer aui-achaplorer aui-component">
      <AchaPlorerRibbon
        data={data}
        onPathChanged={setPath}
        isLoading={isLoading}
        selected={selected}
        onCreateFolder={onCreateFolder}
        onRename={onOpen}
        onDelete={onDelete}
        onUpload={upload.onOpen}
      />

      <AchaPlorerNavigationBar
        isLoading={isLoading}
        path={path}
        onPathChanged={setPath}
        onPathRefresh={mutate}
        onPathFilter={handleFilter}
      />

      <AchaPlorerExplorer
        data={data}
        onPathChanged={setPath}
        isLoading={isLoading}
        onSelect={handleSelectChange}
        selected={selected}
      />
      <InputModal
        data={selected}
        siblings={siblings}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmitInput}
      />
      <UploadModal
        isOpen={upload.isOpen}
        onClose={upload.onClose}
        onSubmit={onSubmitUpload}
      />
    </div>
  );
}

import { Select } from '@chakra-ui/react';
import FormControl from '../FormControl';
import useFormHelpers from '../useFormHelpers';

export default function FormikSelect(props) {
  const {
    controlProps,
    componentProps,
    leftAddon,
    rightElement,
    options = [],
    keySelector = (item) => item.value,
    titleSelector = (item) => item.title,
    valueSelector = (item) => item.value,
    ...others
  } = useFormHelpers(props);

  return (
    <FormControl {...controlProps}>
      <Select {...componentProps} {...others}>
        {options.map((option) => (
          <option key={keySelector(option)} value={valueSelector(option)}>
            {titleSelector(option)}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

import {
  useFetchPost,
  post,
  get,
  del,
  useFetch,
} from '../services/api.service';

export const createComponent = (model) => {
  return post('/shared-components/create', model);
};

export const useSharedComponents = (model) => {
  return useFetchPost(['/shared-components/paginate', model]);
};
export const useSharedComponentDetails = (id, lang) => {
  return useFetch(!id ? null : `/shared-components/${id}/${lang}`);
};

export const deleteComponent = (id) => {
  return del('/shared-components/' + id);
};

export const updateSharedComponent = (model) => {
  return post('/shared-components/detail/update', model);
};
export const toggleComponentVisibility = (id) => {
  return post('/shared-components/visibility/' + id);
};

import React, { useMemo } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';

const Pagination = (props) => {
  const {
    page,
    totalPages,
    onPageChange,
    siblingCount = 1,
    boundaryCount = 1,
  } = props;
  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;
  // console.log({ page, siblingCount, totalPages, boundaryCount });
  // TODO: FIX BUG

  const pageNumbers = useMemo(() => {
    const paginationLength = 2 * siblingCount + 5;
    if (paginationLength >= totalPages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
    const pn = [];
    const startPage =
      page - siblingCount < boundaryCount + 3 ? 1 : page - siblingCount;

    const endPage =
      page + siblingCount < totalPages - boundaryCount - 2
        ? page + siblingCount
        : totalPages;

    for (let i = startPage; i <= endPage; i++) {
      pn.push(i);
    }
    if (startPage !== 1) {
      pn.unshift(-1);
      for (let i = boundaryCount; i >= 1; i--) {
        pn.unshift(i);
      }
    }
    if (endPage !== totalPages) {
      pn.push(-2);
      for (let i = totalPages - boundaryCount + 1; i <= totalPages; i++) {
        pn.push(i);
      }
    }

    return pn;
  }, [page, totalPages, siblingCount]);

  return (
    <Flex align="center" justify="center" my={4}>
      <Button
        onClick={() => onPageChange(page - 1)}
        disabled={isFirstPage}
        mr={2}
      >
        Previous
      </Button>
      {pageNumbers.map((pageNumber) =>
        pageNumber > 0 ? (
          <Button
            key={pageNumber}
            variant="solid"
            //   variant={pageNumber === page ? 'solid' : 'outline'}
            onClick={() => onPageChange(pageNumber)}
            colorScheme={pageNumber === page ? 'brand' : undefined}
            mx={1}
          >
            {pageNumber}
          </Button>
        ) : (
          <div key={pageNumber} disabled className="mx-4">
            ...
          </div>
        ),
      )}

      <Button
        onClick={() => onPageChange(page + 1)}
        disabled={isLastPage}
        ml={2}
      >
        Next
      </Button>
    </Flex>
  );
};

export default Pagination;

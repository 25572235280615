import FormControl from '../FormControl';
import useFormHelpers from '../useFormHelpers';

import { Fragment, useState, useMemo, useEffect, useRef } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckCircleIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Input,
  InputRightElement,
  InputGroup,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import useDebouncedState from 'hooks/useDebouncedState';
import clsx from 'clsx';

export default function FormikMultiAutocomplete(props) {
  const { formik, onChange, name } = props;

  const {
    controlProps,
    componentProps: { value, isInvalid, disabled },
    useBackend,
    // options = [],
    titleSelector = (item) => item.title,
    keySelector = (item) => item.value,
    ...others
  } = useFormHelpers(props);

  const inputRef = useRef(null);

  const [[queryTemp, setQueryTemp], [query]] = useDebouncedState('');
  const { data = [], error, mutate, isLoading } = useBackend(query);
  const [focused, setFocused] = useState(false);

  const [selected, setSelected] = useState(() => {
    if (!value?.length) return [];
    return value;
  });

  const filtered = useMemo(() => {
    let items = !queryTemp
      ? data
      : data?.filter((item) =>
          titleSelector(item)
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(queryTemp.toLowerCase().replace(/\s+/g, '')),
        );
    if (selected.length) {
      const stringifiedSelected = selected.map((i) => JSON.stringify(i));
      items = items?.filter(
        (i) => !stringifiedSelected.includes(JSON.stringify(i)),
      );
      items.unshift(...selected);
    }
    return items;
  }, [data, queryTemp, selected]);

  // useEffect(() => {
  //   if (!value) return setSelected(null);
  //   const newOption = data?.items?.find(
  //     (item) => item === value,
  //   );
  //   if (newOption) setSelected(newOption);
  // }, [data, value, stableValueSelector]);

  const handleChange = (items) => {
    formik.setFieldValue(name, items);
    setSelected(items);
    onChange?.(items);
  };
  const removeItem = (e, item) => {
    e.preventDefault();
    const items = value.filter(
      (i) => JSON.stringify(i) !== JSON.stringify(item),
    );
    formik.setFieldValue(name, items);
    setSelected(items);
    onChange?.(items);
  };

  return (
    <FormControl {...controlProps}>
      <Combobox value={selected} onChange={handleChange} multiple>
        <div className="relative">
          <InputGroup>
            <div
              className={clsx(
                'shrink grow flex gap-2 flex-wrap ps-4',
                'pe-10 border-border border border-solid rounded-md transition-all',
                focused
                  ? 'border-primary outline-primary outline'
                  : 'outline-2',
              )}
              onClick={() => inputRef.current.focus()}
            >
              {value?.map((item) => (
                <Tag
                  size="sm"
                  key={keySelector(item)}
                  borderRadius="full"
                  variant="solid"
                  colorScheme="brand"
                  className="my-2"
                >
                  <TagLabel>{titleSelector(item)}</TagLabel>
                  <TagCloseButton onClick={(e) => removeItem(e, item)} />
                </Tag>
              ))}
              <Combobox.Input
                ref={inputRef}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={(event) => setQueryTemp(event.target.value)}
                className={clsx(
                  others.className,
                  'grow min-w-10 h-10 outline-none',
                )}
                {...others}
              />
            </div>
            <InputRightElement>
              <Combobox.Button className="flex items-center justify-center text-xl w-full h-full">
                <ChevronDownIcon aria-hidden="true" />
              </Combobox.Button>
            </InputRightElement>
          </InputGroup>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQueryTemp('')}
          >
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {!filtered?.length ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  {isLoading ? 'Loading...' : 'Nothing found.'}
                </div>
              ) : (
                filtered.map((item) => (
                  <Combobox.Option
                    key={keySelector(item)}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 ps-10 pe-4 ${
                        active ? 'bg-teal-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={item}
                  >
                    {({ selected: isSelected, active }) => {
                      // const isSelected =
                      //   selected &&
                      //   value.some(
                      //     (v) => JSON.stringify(item) === JSON.stringify(v),
                      //   );

                      return (
                        <>
                          <span
                            className={`block truncate ${
                              isSelected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {titleSelector(item)}
                          </span>
                          {isSelected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-teal-600'
                              }`}
                            >
                              <CheckCircleIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </FormControl>
  );
}

import { Box, Button, Grid, SimpleGrid } from '@chakra-ui/react';
import { usePage } from 'lib/api/pages.api';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PageComponents from './components/PageComponents';
import { updateSeo, syncPage } from 'lib/api/pages.api';
import { isSuccessful } from 'lib/services/api.service';
import SeoForm from 'components/page-components/seo-form';
import { defaultLang } from 'lib/constants';
import Card from 'components/card/Card';

export default function Page() {
  const { id, lang } = useParams();
  const history = useHistory();
  const { data, error, isLoading, mutate } = usePage(id, lang);
  const [cloning, setCloning] = useState(false);

  const onBack = () => {
    history.replace('/admin/pages');
  };

  const onSubmitSeo = async (values) => {
    const res = await updateSeo(data.id, values);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate({ data: { ...data, ...values } });
  };

  const clone = async () => {
    setCloning(true);
    const res = await syncPage(id, lang);
    setCloning(false);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate();
  };

  return (
    <Box>
      <Button className="mb-4" onClick={() => onBack()}>
        Back
      </Button>

      {error ? (
        data?.noDetail ? (
          <Card className="items-start gap-4">
            <p>
              This page has not yet built. if you are done editing this page in
              "{defaultLang}" you can make a clone.
            </p>
            <Button colorScheme="brand" isLoading={cloning} onClick={clone}>
              Clone
            </Button>
          </Card>
        ) : (
          // TODO: handle error
          <div>An error has occurred: {JSON.stringify(data)}</div>
        )
      ) : (
        <div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">
            <div className="lg:col-span-2 mb-4">
              {data ? <PageComponents data={data} mutate={mutate} /> : null}
            </div>
            <div>
              {data ? (
                <SeoForm
                  data={data}
                  onSubmit={onSubmitSeo}
                  getRoute={(val) => `/${val}`}
                  permalinkPrefix={`/`}
                  excludePrefix="blog"
                />
              ) : (
                <SeoForm />
              )}
            </div>
          </div>
        </div>
      )}
    </Box>
  );
}

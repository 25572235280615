import * as Yup from 'yup';
import { FormElementType } from 'components/formik/FormElement';
import { createComponent } from './CreateComponent';
import YOUTUBE1 from 'assets/img/component-temps/youtube1.png';

export const youtube1Component = createComponent({
  type: 'youtube1',
  title: 'Youtube 1',
  initialValues: {
    title: '',
    description: '',
    buttonTitle: '',
    buttonLink: '',
    videoLink: '',
    videoImage: '',
  },
  form: [
    {
      type: FormElementType.Input,
      name: 'title',
      validation: Yup.string().min(2).max(128),
      props: {
        placeholder: 'Enter Section Title',
        label: 'Title',
      },
    },
    {
      type: FormElementType.Textarea,
      name: 'description',
      initialValue: '',
      validation: Yup.string().min(2).max(256),
      props: {
        placeholder: 'Enter Section Description',
        label: 'Description',
        rows: 6,
      },
    },
    {
      type: FormElementType.Input,
      name: 'buttonTitle',
      initialValue: '',
      validation: Yup.string().min(2).max(32),
      props: {
        // placeholder: '',
        label: 'Button Title',
      },
    },
    {
      type: FormElementType.Input,
      name: 'buttonLink',
      initialValue: '',
      validation: Yup.string().min(2).max(1024),
      props: {
        // placeholder: '',
        label: 'Button Link',
      },
    },
    {
      type: FormElementType.Input,
      name: 'videoLink',
      initialValue: '',
      validation: Yup.string().min(2).max(1024),
      props: {
        // placeholder: '',
        label: 'Video Link',
        rightElement: <button>hi</button>,
      },
    },
    {
      type: FormElementType.FilePicker,
      name: 'videoImage',
      initialValue: '',
      validation: Yup.string().min(2),
      props: {
        // placeholder: '',
        label: 'Video Image',
      },
    },
  ],
  image: YOUTUBE1,
});

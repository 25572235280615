import { useCallback } from 'react';
import moment from 'moment';

// function toDateObject(date) {
//   if (date instanceof Date && !isNaN(date.getTime())) {
//     return date;
//   }
//   const parsedDate = new Date(date);
//   if (!isNaN(parsedDate.getTime())) {
//     return parsedDate;
//   }
//   return null;
// }

// export default function useDateFormat() {
//   return (date) => {
//     const dateObj = toDateObject(date);
//     if (!dateObj) return '';

//     const options = {
//       weekday: 'short',
//       year: 'numeric',
//       month: 'short',
//       day: 'numeric',
//       hour: 'numeric',
//       minute: 'numeric',
//     };
//     // const options = {
//     //   weekday: 'long',
//     //   year: 'numeric',
//     //   month: 'numeric',
//     //   day: 'numeric',
//     //   hour: 'numeric',
//     //   minute: 'numeric',
//     // };

//     return new Intl.DateTimeFormat('en-US', { ...options }).format(dateObj);
//   };
// }

// 'LT' // 11:05 PM
// 'LTS' // 11:05:32 PM
// 'L' // 06/06/2024
// 'l' // 6/6/2024
// 'LL' // June 6, 2024
// 'll' // Jun 6, 2024
// 'LLL' // June 6, 2024 11:05 PM
// 'lll' // Jun 6, 2024 11:05 PM
// 'LLLL' // Thursday, June 6, 2024 11:05 PM
// 'llll' // Thu, Jun 6, 2024 11:05 PM

export default function useDateFormat() {
  // useEffect(() => {
  //   moment.locale('en');
  // }, [locale]);

  return useCallback(
    (date, format = 'llll') => {
      if (!date) return '';
      if (!(date instanceof Date)) date = new Date(date);
      const inputDate = moment(date);
      // const inputDate = moment(date).locale(locale);

      return inputDate.format(format);
    },
    [],
    // [locale],
  );
}

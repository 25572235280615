import { Button, Text } from '@chakra-ui/react';
import { Editor } from '@monaco-editor/react';
import Card from 'components/card/Card';
import { useGlobalMetadata, updateGlobalMetadata } from 'lib/api/globals.api';
import { isSuccessful } from 'lib/services/api.service';
import { useRef, useState } from 'react';

export default function GlobalCodesComponent({ metaKey, title, language }) {
  const { data, error, mutate, isLoading } = useGlobalMetadata(metaKey);
  const editorRef = useRef(null);
  const [dirty, setDirty] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleEditorDidMount = (editor, monaco) => {
    // here is the editor instance
    // you can store it in `useRef` for further usage
    editorRef.current = editor;
  };

  const handleSubmit = async () => {
    const value = editorRef.current.getValue();
    if (!editorRef.current || value === data) return;
    setSubmitting(true);
    const res = await updateGlobalMetadata(metaKey, value);
    setSubmitting(false);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    setDirty(false);
    mutate({ data: value });
  };
  function handleEditorValidation(markers) {
    // model markers
    markers.forEach((marker) => console.log('onValidate:', marker.message));
  }

  return (
    <Card className="flex flex-col">
      <Text className="mb-3" fontWeight="bold" fontSize="xl" align="center">
        {title}
      </Text>
      <div className="grow">
        {error ? (
          'An error has occurred'
        ) : isLoading ? (
          'is loading...'
        ) : (
          <Editor
            // className="h-full min-h-96"
            onChange={() => setDirty(true)}
            className="h-96 2xl:h-full min-h-96"
            defaultLanguage={language}
            defaultValue={data}
            onMount={handleEditorDidMount}
            onValidate={handleEditorValidation}
            options={{ minimap: { enabled: false } }}
          />
        )}
      </div>
      <Button
        disabled={!dirty || submitting || isLoading}
        colorScheme="brand"
        onClick={handleSubmit}
      >
        Save Changes
      </Button>
    </Card>
  );
}

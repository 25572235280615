import {
  IconButton,
  useDisclosure,
  Tag,
  TagLabel,
  TagCloseButton,
  TagRightIcon,
} from '@chakra-ui/react';
import FormControl from '../FormControl';
import useFormHelpers from '../useFormHelpers';
import { useState } from 'react';
import LinkPickerModal, { LinkType } from './picker-modal';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { CONFIG } from 'lib/configuration';
// import {
//   VscEdit,
//   VscFileMedia,
//   VscFileSubmodule,
//   VscLinkExternal,
//   VscListTree,
//   VscSymbolFile,
//   VscTrash,
// } from 'react-icons/vsc';

export default function FormikLinkPicker(props) {
  const { formik, onChange, name } = props;
  const {
    controlProps,
    componentProps: { value, isInvalid, disabled },
    ...others
  } = useFormHelpers(props);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editingIdx, setEditingIdx] = useState();

  const handleChange = (newValue) => {
    formik.setFieldValue(name, newValue);
    onChange?.(newValue);
  };

  const onRemove = (e, idx) => {
    e.preventDefault();
    const newValue = [...value];
    newValue.splice(idx, 1);
    handleChange(newValue);
  };

  const handleChangeLink = async (values) => {
    onClose();
    if (typeof editingIdx === 'number') {
      const newValue = [...value];
      newValue.splice(editingIdx, 1, values);
      handleChange(newValue);
      return;
    }
    handleChange([...value, values]);
  };

  const onEdit = (e, idx) => {
    e.preventDefault();
    setEditingIdx(idx);
    onOpen();
  };
  const onAdd = () => {
    setEditingIdx(null);
    onOpen();
  };

  return (
    <FormControl
      {...controlProps}
      label={
        <div className="flex items-center gap-2">
          <span>{controlProps.label}</span>
          <IconButton onClick={onAdd} icon={<AddIcon />} />
        </div>
      }
    >
      {Boolean(value?.length) && (
        <div className="flex gap-1 flex-wrap mb-4">
          {value?.map(({ title, type, url }, idx) => {
            const prefix =
              +type === LinkType.External ? '' : CONFIG.PLATFORM_CLIENT_URL;
            const href = `${prefix}${url}`;

            return (
              <Tag
                as="a"
                className="underline"
                key={title}
                href={href}
                target="_blank"
                borderRadius="full"
                variant="solid"
                colorScheme="brand"
              >
                <TagLabel>{title}</TagLabel>
                <TagRightIcon as={EditIcon} onClick={(e) => onEdit(e, idx)} />
                <TagCloseButton onClick={(e) => onRemove(e, idx)} />
              </Tag>
            );
            // return (
            //   <a className="underline" key={title} href={href} target="_blank">
            //     <span>{title}</span>
            //     <IconButton
            //       size="sm"
            //       icon={<DeleteIcon />}
            //       onClick={() => onRemove(idx)}
            //     />
            //   </a>
            // );
          })}
        </div>
      )}
      {/* <Input
        disabled={disabled}
        isInvalid={isInvalid}
        {...others}
        value={inputVal}
        onChange={(e) => setInputVal(e.target.value)}
        onKeyDown={onKeyDown}
      /> */}

      <LinkPickerModal
        onClose={onClose}
        isOpen={isOpen}
        data={typeof editingIdx === 'number' ? value[editingIdx] : undefined}
        onSubmit={handleChangeLink}
      />
    </FormControl>
  );
}

import React, { useState } from 'react';
import {
  createForm,
  deleteForm,
  updateForm,
  useFormsPagination,
} from 'lib/api/forms.api';
import Pagination from 'components/pagination/Pagination';
import AddPageButton from 'components/page-components/add-page-button';
import FormCard from './components/form-card';
import { Box, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import FormGeneratorModal from './components/form-generator-modal';
import { isSuccessful } from 'lib/services/api.service';
import { defaultLang } from 'lib/constants';
import FormTranslateModal from './components/form-translate-modal';
import { useAlertContext } from 'components/alert-dialog';

export default function FormsPage() {
  const [page, setPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const translationModal = useDisclosure();
  const history = useHistory();
  const [editData, setEditData] = useState();
  const [selectedLang, setSelectedLang] = useState();
  const { openAlert, closeAlert } = useAlertContext();

  const { data, error, isLoading, mutate } = useFormsPagination({
    page,
    pageSize: 20,
  });

  const onAdd = () => {
    setEditData();
    onOpen();
  };
  const handleClick = (data, lang) => {
    if (!lang || lang === defaultLang) {
      const url = `/admin/forms/${data.id}`;
      history.push(url);
      return;
    }
    setEditData(data);
    setSelectedLang(lang);
    translationModal.onOpen();
  };

  const handleToggleDisabled = async (data) => {
    const res = await updateForm(data.id, {
      disabled: !data.disabled,
    });
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate();
  };
  const handleDelete = (data) => {
    openAlert({
      title: 'Delete Form',
      onConfirm: async () => {
        const res = await deleteForm(data.id);
        if (!isSuccessful(res)) {
          // TODO: HANDLE HERE
          return;
        }
        closeAlert();
        mutate();
      },
      variant: 'delete',
    });
  };
  const handleEdit = (data) => {
    setEditData(data);
    onOpen();
  };
  const handleTranslateSubmit = async (values) => {
    const res = await updateForm(editData.id, {
      translations: JSON.stringify({
        ...data.translations,
        [selectedLang]: values,
      }),
    });
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate();
    translationModal.onClose();
  };

  const handleSubmit = async (values) => {
    const model = {
      ...values,
      data: JSON.stringify({ ...values.data, version: 1 }),
    };

    let res;

    if (editData) {
      res = await updateForm(editData.id, model);
    } else {
      res = await createForm(model);
    }
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate();
    onClose();
  };

  return (
    <Box>
      {error ? (
        // TODO: Handle error
        <div>An error has occurred</div>
      ) : (
        <div className="flex flex-wrap gap-4">
          <div className="w-full sm:w-96">
            <AddPageButton onClick={onAdd} />
          </div>

          {isLoading
            ? Array(10)
                .fill('')
                .map((_, idx) => (
                  <div className="w-full sm:w-96 max-w-full" key={idx}>
                    <FormCard />
                  </div>
                ))
            : data?.items.map((form) => (
                <div className="w-full sm:w-96 max-w-full" key={form.id}>
                  <FormCard
                    data={form}
                    onClick={(lang) => handleClick(form, lang)}
                    onToggleDisabled={handleToggleDisabled}
                    onEdit={handleEdit}
                    //  onClone={handleClone}
                    onDelete={handleDelete}
                  />
                </div>
              ))}
        </div>
      )}
      {Boolean(data?.totalPages > 1) && (
        <Pagination
          page={page}
          totalPages={data?.totalPages}
          onPageChange={(p) => setPage(p)}
        />
      )}
      <FormGeneratorModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSubmit}
        data={editData}
        //  title={cloneObj ? `Cloning "${cloneObj.name}" Page` : 'Add Page'}
        //  excludePrefix="blog"
        //  permalinkPrefix="/"
        // permalinkPrefix="/en/id/"
      />
      <FormTranslateModal
        data={editData}
        lang={selectedLang}
        onSubmit={handleTranslateSubmit}
        {...translationModal}
      />
    </Box>
  );
}

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import useForm from 'components/formik/useForm';
import { useMemo } from 'react';
import { FormElementType } from 'components/formik/FormElement';
import * as Yup from 'yup';
import { components } from 'views/admin/page/components/PageComponents/components';

// Banner = "banner",
// Steps = "steps",
// Carousel = "carousel",
// Html = "html",

export function ComponentInfoModal(props) {
  const { onClose, data, onSubmit } = props;

  const component = useMemo(
    () => components.find((c) => c.type === data.type),
    [components, data.type],
  );

  const { formik, SubmitButton, AllElements } = useForm({
    onSubmit,
    form:
      [
        {
          type: FormElementType.Input,
          name: 'componentName',
          initialValue: '',
          validation: Yup.string().min(2).max(32).required('Required'),
          props: {
            placeholder: 'Enter Components Name',
            label: 'Name',
          },
        },
        {
          type: FormElementType.Textarea,
          name: 'componentDescription',
          initialValue: '',
          validation: Yup.string().min(2).max(256),
          props: {
            placeholder: 'Enter Components Description',
            label: 'Description',
            rows: 6,
          },
        },
        ...component?.form,
      ] || [],
    initialValues: {
      ...component?.initialValues,
      ...JSON.parse(data.payload || '{}'),
    },
  });

  return (
    <Modal onClose={onClose} closeOnEsc={false} isOpen={Boolean(data)}>
      <ModalOverlay />
      <ModalContent className="md:!max-w-screen-sm lg:!max-w-screen-lg xl:!max-w-screen-xl">
        <ModalHeader>
          {data?.id ? 'Edit Component' : 'Create Component'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex flex-col gap-4">{AllElements}</div>
        </ModalBody>

        <ModalFooter>
          <Button disabled={formik.isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <SubmitButton>Save</SubmitButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

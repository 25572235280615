import { Image } from '@chakra-ui/react';
export default function TemplateCard(props) {
  return (
    <div
      className="flex flex-col border rounded text-center hover:shadow-lg active:shadow-none select-none cursor-pointer transition-all"
      onClick={props.onClick}
    >
      {props.image && <Image src={props.image} />}
      <div className="py-3 mt-auto text-lg">{props.title}</div>
    </div>
  );
}

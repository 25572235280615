import { Button, IconButton, Select, Spinner } from '@chakra-ui/react';
import Card from 'components/card/Card';
import {
  useMenuItems,
  deleteMenuItem,
  createMenuItem,
  reorderMenuItem,
} from 'lib/api/menu.api';
import React, { useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdAdd } from 'react-icons/md';
import {
  CreateMenuItemModal,
  MenuType,
} from './components/CreateMenuItemModal';
import {
  VscEdit,
  VscFileMedia,
  VscFileSubmodule,
  VscLinkExternal,
  VscListTree,
  VscSymbolFile,
  VscTrash,
} from 'react-icons/vsc';
import { isSuccessful } from 'lib/services/api.service';
import clsx from 'clsx';
import { useAlertContext } from 'components/alert-dialog';
import { updateMenuItem } from 'lib/api/menu.api';
import { ExternalLinkIcon, LinkIcon } from '@chakra-ui/icons';
import LanguageSelect from 'components/language-select';
import { defaultLang } from 'lib/constants';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  result[startIndex].order = endIndex + 1;

  const increment = endIndex > startIndex ? 1 : -1;

  for (let idx = startIndex; idx !== endIndex; idx += increment) {
    const item = result[idx + increment];
    if (item.parent_id !== result[startIndex].parent_id) continue;
    item.order -= increment;
  }
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export default function MenuPage() {
  const [editingItem, setEditingItem] = useState(null);
  const [subMenuId, setSubMenuId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { openAlert, closeAlert } = useAlertContext();
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(defaultLang);

  const { data, error, isLoading, mutate } = useMenuItems(language);

  const onDragEnd = async (result) => {
    if (!result.destination || result.destination.index === result.source.index)
      return;
    const item = data[result.source.index];
    const destinationOrder = data[result.destination.index].order;

    mutate(
      (i) => ({
        ...i,
        data: reorder(i.data, result.source.index, result.destination.index),
      }),
      false,
    );
    setLoading(true);
    const res = await reorderMenuItem(
      item.id,
      destinationOrder,
      item.parent_id,
    );
    setLoading(false);
    if (!isSuccessful(res)) {
      // TODO: handle error
      mutate();
      return;
    }
  };

  const onModalClose = () => {
    setModalOpen(false);
    setEditingItem(null);
  };
  const onEditItem = (item) => {
    setEditingItem(item);
    setModalOpen(true);
  };
  const onDeleteItem = async (item, index) => {
    openAlert({
      title: 'Delete Menu Item',
      variant: 'delete',
      // description: `Are you sure? "${page.name}" will be landing page.`,
      onConfirm: async () => {
        mutate((i) => {
          const data = [...i.data];
          const removed = data.splice(index, 1);
          for (let idx = index; idx < data.length; idx++) {
            const item = data[idx];
            if (item.parent_id !== removed.parent_id) continue;
            item.order--;
          }
          return {
            ...i,
            data: data.filter((d) => d.parent_id !== item.id),
          };
        }, false);
        const res = await deleteMenuItem(item.id);
        closeAlert();
        if (!isSuccessful(res)) {
          mutate();
          // TODO: HANDLE ERROR
          return;
        }
      },
    });
  };
  const onAdd = () => {
    setSubMenuId(null);
    setModalOpen(true);
  };

  const onSubmitAdd = async (values) => {
    const { record_id, ...model } = values;

    if ([MenuType.Page, MenuType.Blog, MenuType.Post].includes(+values.type)) {
      model.record_id = record_id;
    }
    // if create
    const res = await createMenuItem({
      parent_id: subMenuId,
      icon: '',
      order: 1,
      ...model,
    });
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate((i) => {
      const data = [
        res.data.data,
        ...i.data.map((d) => ({
          ...d,
          order:
            res.data.data.parent_id === d.parent_id ? d.order + 1 : d.order,
        })),
      ];
      return {
        ...i,
        data: data,
      };
    }, false);
  };
  const onSubmitEdit = async (values) => {
    const model = {
      ...editingItem,
      ...values,
    };
    if (![MenuType.Page, MenuType.Blog, MenuType.Post].includes(+values.type)) {
      delete model.record_id;
    }
    // if create
    const res = await updateMenuItem(editingItem.id, language, model);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate((i) => {
      const data = [...i.data];
      const idx = data.findIndex((item) => item.id === editingItem.id);
      data[idx] = { ...model };
      return {
        ...i,
        data,
      };
    }, false);
  };
  const onSubmit = async (values) => {
    values.type = +values.type;
    if (editingItem) {
      await onSubmitEdit(values);
    } else {
      await onSubmitAdd(values);
    }

    setModalOpen(false);
  };

  const openSubmenu = (item) => {
    setSubMenuId(
      item.type === MenuType.Sub || item.type === 'Sub' ? item.id : null,
    );
  };
  if (error) return <>An error has occurred</>;

  return (
    <Card>
      <div className="flex justify-end mb-2">
        <div className="w-32">
          <LanguageSelect value={language} setValue={setLanguage} />
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center py-10">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="grid md:grid-cols-2 gap-4 lg:gap-8">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="menu">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    // style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <Button
                      onClick={onAdd}
                      leftIcon={<MdAdd />}
                      isFullWidth
                      mb={2}
                    >
                      ADD
                    </Button>
                    {(data || []).map((item, index) =>
                      item.parent_id ? null : (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          isDragDisabled={loading}
                        >
                          {(provided, snapshot) => (
                            <MenuItem
                              provided={provided}
                              data={item}
                              selected={subMenuId}
                              isDragging={snapshot.isDragging}
                              openSubmenu={openSubmenu}
                              onEditItem={onEditItem}
                              onDeleteItem={onDeleteItem}
                              index={index}
                            />
                          )}
                        </Draggable>
                      ),
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {subMenuId && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="submenu">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <Button
                        onClick={() => setModalOpen(true)}
                        leftIcon={<MdAdd />}
                        isFullWidth
                        mb={2}
                      >
                        ADD
                      </Button>
                      {(data || []).map((item, index) =>
                        item.parent_id !== subMenuId ? null : (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                            isDragDisabled={loading}
                          >
                            {(provided, snapshot) => (
                              <MenuItem
                                provided={provided}
                                data={item}
                                isDragging={snapshot.isDragging}
                                onEditItem={onEditItem}
                                onDeleteItem={onDeleteItem}
                                index={index}
                              />
                            )}
                          </Draggable>
                        ),
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
          <CreateMenuItemModal
            onClose={onModalClose}
            data={editingItem}
            isOpen={modalOpen}
            onSubmit={onSubmit}
            lang={language}
          />
        </>
      )}
    </Card>
  );
}

const MenuItem = (props) => {
  const {
    provided,
    data,
    selected,
    isDragging,
    openSubmenu,
    onEditItem,
    onDeleteItem,
    index,
  } = props;
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={clsx(
        'flex gap-1 items-center select-none p-2 border-b',
        data.id === selected
          ? 'bg-primary text-primary-contrastText'
          : 'bg-white',
        {
          ['opacity-60']: isDragging,
        },
      )}
      style={provided.draggableProps.style}
      onClick={() => openSubmenu?.(data)}
    >
      {data.type === MenuType.Sub ? (
        <VscListTree className="mx-2" />
      ) : data.type === MenuType.External ? (
        <VscLinkExternal className="mx-2" />
      ) : data.type === MenuType.Blog ? (
        <VscFileSubmodule className="mx-2" />
      ) : data.type === MenuType.Post ? (
        <VscFileMedia className="mx-2" />
      ) : (
        <VscSymbolFile className="mx-2" />
      )}
      <span className="grow">{data.title}</span>
      <IconButton
        className="text-black"
        onClick={() => onEditItem(data)}
        icon={<VscEdit />}
      />
      <IconButton
        className="text-black"
        onClick={() => onDeleteItem(data, index)}
        icon={<VscTrash />}
      />
    </div>
  );
};

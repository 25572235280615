import RECOMMENDED_HOTELS1 from 'assets/img/component-temps/recommendedHotels1.png';
import { useHotelLocationQuery } from 'lib/api/location.api';
import { FormElementType } from 'components/formik/FormElement';
import { createComponent } from './CreateComponent';

export const recommendedHotels1Component = createComponent({
  type: 'recommendedHotels1',
  title: 'Recommended Hotels',
  initialValues: {
    cities: [],
  },
  // className: 'md:grid-cols-3 lg:grid-cols-4',
  size: 'sm',
  form: [
    {
      type: FormElementType.MultiAutocomplete,
      name: 'cities',
      props: {
        placeholder: 'Select...',
        label: 'Cities',
        keySelector: (item) => item.id,
        titleSelector: (item) => item.name,
        useBackend: (query) =>
          useHotelLocationQuery({
            query,
            culture: 'en-US',
            locationType: 2,
          }),
      },
    },
  ],
  image: RECOMMENDED_HOTELS1,
});

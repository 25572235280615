import BlogSeoForm from '../blog-seo-form';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

export default function BlogSeoFormModal(props) {
  const { isOpen, onClose, id, lang } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="capitalize">{lang}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          <BlogSeoForm id={id} lang={lang} onClose={() => onClose(true)} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

import { IconButton, useDisclosure, Button } from '@chakra-ui/react';
import { FormElementGeneratorModal } from './FormElementGeneratorModal';
import { VscEdit, VscTrash } from 'react-icons/vsc';
import { Fragment, useState } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import { getIn } from 'formik';
import clsx from 'clsx';
import Fieldset from 'components/fieldset';

export function FormikFormGenerator(props) {
  const { name, formik } = props;

  const value = getIn(formik.values, name);
  const error = getIn(formik.errors, name);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeIndex, setActiveIndex] = useState();
  const [editData, setEditData] = useState();

  const onDelete = (index) => {
    const newVal = [...value];
    newVal.splice(index, 1);
    formik.setFieldValue(name, newVal);
  };
  const submitElement = (element) => {
    const newVal = [...value];

    newVal.splice(activeIndex, editData ? 1 : 0, element);

    formik.setFieldValue(name, newVal);
    setEditData(undefined);
  };
  const handleOpen = (index) => {
    onOpen();
    setActiveIndex(index);
  };
  const onEdit = (index) => {
    setEditData(value[index]);
    handleOpen(index);
  };

  return (
    <Fieldset title="Form Elements:" error={error}>
      <AddButton onClick={() => handleOpen(0)} />
      {value.map((el, idx) => (
        <Fragment key={el.name}>
          <div className="border border-border border-solid px-3 py-2 rounded-lg flex flex-wrap items-center gap-2">
            <span className="grow">
              {el.label} ({el.name}-{el.type})
            </span>
            <span className="shrink-0 flex gap-2">
              <IconButton onClick={() => onEdit(idx)} icon={<VscEdit />} />
              <IconButton onClick={() => onDelete(idx)} icon={<VscTrash />} />
            </span>
          </div>
          <AddButton onClick={() => handleOpen(idx + 1)} />
        </Fragment>
      ))}

      <FormElementGeneratorModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={submitElement}
        data={editData}
      />
      {error ? <p className="text-red-500">{error}</p> : null}
    </Fieldset>
  );
  // return (
  //   <div
  //     className={clsx(
  //       'border rounded-xl p-4',
  //       error ? 'border-red-500' : 'border-border',
  //     )}
  //   >
  //     <p className="text-lg font-semibold">Form Elements</p>

  //   </div>
  // );
}

function AddButton({ onClick }) {
  return (
    <div className="my-2">
      <Button
        onClick={onClick}
        colorScheme="brand"
        // variant="outline"
        // variant="solid"
        variant="ghost"
        leftIcon={<AddIcon />}
      >
        Add Element
      </Button>
    </div>
  );
  // return (
  //   <div
  //     onClick={onClick}
  //     className="border border-dashed text-primary border-primary p-4 rounded-xl cursor-pointer select-none my-2 flex items-center justify-center gap-2"
  //   >
  //     <AddIcon />
  //     <span>Add Element</span>
  //   </div>
  // );
}

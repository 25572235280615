import { FormElementType } from 'components/formik/FormElement';
import * as Yup from 'yup';
import OVERVIEW1 from 'assets/img/component-temps/overview1.png';
import { createComponent, defaultForm } from './CreateComponent';

export const overview1Component = createComponent(
  {
    type: 'overview1',
    title: 'Overview',
    initialValues: {
      title: '',
      description: '',
      bgColor: '',
      column1: [
        {
          title: '',
          items: [],
        },
      ],
      column2: [
        {
          title: '',
          items: [],
        },
      ],
    },
    form: [
      {
        type: FormElementType.Input,
        name: 'title',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Title',
          label: 'Title',
        },
      },
      {
        type: FormElementType.Textarea,
        name: 'description',
        validation: Yup.string().min(2).max(512),
        props: {
          placeholder: 'Enter Description',
          label: 'Description',
        },
      },
      ...defaultForm,
      ...Array(2)
        .fill('')
        .map((_, i) => ({
          type: FormElementType.Array,
          name: `column${i + 1}`,
          // validation: Yup.string().min(2).max(128),
          props: {
            label: `Column Items ${i + 1}`,
            emptyValues: { title: '', items: [] },
            form: [
              {
                type: FormElementType.Input,
                name: 'title',
                props: {
                  placeholder: 'Enter title...',
                  label: 'Title',
                },
              },
              {
                type: FormElementType.Tags,
                name: 'items',
                props: {
                  placeholder: 'Enter Items...',
                  label: 'Items',
                },
              },
            ],
          },
        })),
    ],
    image: OVERVIEW1,
  },
  true,
);

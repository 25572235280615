// chakra imports
import { Box, Flex, HStack, Stack, Text } from '@chakra-ui/react';
//   Custom components
import Brand from './Brand';
import Links from './Links';
import SidebarCard from './SidebarCard';
import React from 'react';
import { MdOutlineLogout } from 'react-icons/md';
import { useHistory } from 'react-router-dom';

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  const history = useHistory();

  const logout = () => {
    global.$app.token = '';
    global.$app.storeInstance();
    history.push('/auth/sign-in');
  };
  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      pb="55px"
      px="16px"
      borderRadius="30px"
    >
      {/*<Brand />*/}

      <Box
        display="flex"
        flexDir="column"
        ps="20px"
        pe={{ md: '16px', '2xl': '1px' }}
        h="100%"
      >
        <Links routes={routes} />
        <Box onClick={logout} as="button" w="100%" mt="auto">
          <HStack spacing={'26px'} py="5px" ps="10px">
            <Flex w="100%" alignItems="center" justifyContent="center">
              <Box me="18px">
                <MdOutlineLogout />
              </Box>
              <Text
                me="auto"
                // color={
                //   activeRoute(route.path.toLowerCase())
                //     ? activeColor
                //     : textColor
                // }
              >
                {/* {route.name} */}
                LogOut
              </Text>
            </Flex>
            <Box h="36px" w="4px" borderRadius="5px" />
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
}

export default SidebarContent;

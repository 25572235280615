import { Button } from '@chakra-ui/react';
import { getIn, setIn, useFormik } from 'formik';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import FormElement, { FormElementType } from './FormElement';
import * as Yup from 'yup';

export default function useForm(props) {
  const {
    form,
    initialValues: _initialValues,
    onSubmit,
    validationSchema: _validationSchema,
  } = props;

  const { validationSchema, initialValues } = useMemo(() => {
    let validationObject = {};
    let initialValues = {};

    const setInitials = (element) => {
      const { name, type, validation, initialValue, props } =
        typeof element === 'function' ? element() : element;

      if (type === FormElementType.Container) {
        props.form.forEach(setInitials);
        return;
      }

      if (!_validationSchema && validation) {
        validationObject = setIn(validationObject, name, validation);
      }

      initialValues = setIn(
        initialValues,
        name,
        getIn(_initialValues, name) ?? initialValue ?? '',
      );
    };

    form.forEach(setInitials);

    validationObject = makeYupCompatibleObject(validationObject);
    return {
      initialValues,
      validationSchema: _validationSchema || validationObject,
    };
  }, [form, _initialValues, _validationSchema]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  useEffect(() => {
    if (JSON.stringify(initialValues) === JSON.stringify(formik.initialValues))
      return;
    formik.resetForm({ values: initialValues });
  }, [initialValues, formik.initialValues]);

  const { isSubmitting, submitForm, dirty } = formik;

  const FormElements = useMemo(() => {
    const elements = {};
    form.forEach((element) => {
      const { name, type, props, hidden, component } =
        typeof element === 'function' ? element(formik) : element;
      if (hidden instanceof Function ? hidden(formik) : hidden) return;
      elements[name] = (
        <FormElement
          {...{
            type,
            name,
            props,
            formik,
            component,
          }}
        />
      );
    });
    return elements;
  }, [formik, form]);

  const SubmitButton = useCallback(
    (buttonProps) => {
      const { disabled, isLoading, ...other } = buttonProps;
      return (
        <Button
          isLoading={isLoading || isSubmitting}
          onClick={submitForm}
          colorScheme="brand"
          disabled={disabled || !dirty || isSubmitting}
          children="Submit"
          {...other}
        />
      );
    },
    [dirty, isSubmitting],
  );
  const AllElements = Object.entries(FormElements).map(([name, element]) => (
    <Fragment key={name}>{element}</Fragment>
  ));

  return { formik, SubmitButton, FormElements, AllElements };
}

function makeYupCompatibleObject(validationObject) {
  const newObject = {};
  Object.entries(validationObject).forEach(([key, value]) => {
    if (typeof value === 'object' && !(value instanceof Yup.Schema)) {
      newObject[key] = makeYupCompatibleObject(validationObject[key]);
    } else {
      newObject[key] = value;
    }
  });
  return Yup.object(newObject);
}

import { post } from 'lib/services/api.service';
import { useFetch } from 'lib/services/api.service';

export const useSeoData = () => {
  return useFetch(`/seo/settings`);
};
export const updateSeoData = (values) => {
  return post(`/seo/settings`, values);
};

export const useGlobalMetadata = (key) => {
  return useFetch('/meta-data/' + key);
};
export const updateGlobalMetadata = (key, value) => {
  return post('/meta-data', { key, value });
};

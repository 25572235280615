import { Select } from '@chakra-ui/react';
import { languages } from 'lib/constants';

export default function LanguageSelect(props) {
  const { value, setValue, onChange } = props;

  const handleChange = (e) => {
    onChange?.(e);
    setValue?.(e.target.value);
  };

  return (
    <Select variant="outline" value={value} onChange={handleChange}>
      {languages.map(({ name, code }) => (
        <option key={code} value={code}>
          {name}
        </option>
      ))}
    </Select>
  );
}

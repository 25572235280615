import GlobalCodesComponent from './codes';

export default function GlobalScriptsComponent() {
  return (
    <GlobalCodesComponent
      metaKey="META_DATA_JS"
      title="Global Scripts"
      language="javascript"
    />
  );
}

import { useEffect, useRef, useState } from 'react';

export function useChangeCounter(valueGetter, data) {
  const prevStateRef = useRef(valueGetter(data));
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const newValue = valueGetter(data);
    if (newValue === prevStateRef.current) return;
    prevStateRef.current = newValue;
    setCounter((c) => c + 1);
  }, [data]);

  return counter;
}

import { FormElementType } from 'components/formik/FormElement';
import * as Yup from 'yup';
import { createComponent } from './CreateComponent';
import FORM1 from 'assets/img/component-temps/box1.png';
import { useFormsPagination } from 'lib/api/forms.api';
import Fieldset from 'components/fieldset';
// import { FormikFormGenerator } from '../../FormikFormGenerator';

export const form1Component = createComponent(
  {
    type: 'form1',
    title: 'Form',
    initialValues: {
      title: '',
      description: '',
      requestConfig: {
        url: '',
        method: 'post',
      },
      background: {
        image: '',
        color: '',
      },
      paper: {
        maxWidth: 'sm',
      },
      form: {
        submitButton: {},
        elements: [],
      },
    },
    form: [
      {
        type: FormElementType.Input,
        name: 'title',
        validation: Yup.string().min(2).max(128),
        props: {
          placeholder: 'Enter Section Title',
          label: 'Title',
        },
      },
      {
        type: FormElementType.Textarea,
        name: 'description',
        initialValue: '',
        validation: Yup.string().min(2).max(256),
        props: {
          placeholder: 'Enter Section Description',
          label: 'Description',
          rows: 6,
        },
      },
      {
        type: FormElementType.Autocomplete,
        name: 'formId',
        props: {
          placeholder: 'Select Form',
          label: 'Form',
          keySelector: (item) => item.id,
          valueSelector: (item) => item.id,
          useBackend: (query) => {
            const { data, ...others } = useFormsPagination({
              query,
            });
            return { data: data?.items, ...others };
          },
        },
      },
      {
        type: FormElementType.Container,
        name: 'background',
        props: {
          wrapper: ({ children, ...other }) => (
            <Fieldset title="Style:">
              <div className="grid gap-4 grid-cols-2">{children}</div>
            </Fieldset>
          ),
          form: [
            {
              type: FormElementType.FilePicker,
              name: 'background.image',
              props: {
                image: true,
                label: 'Background Image',
              },
            },
            {
              type: FormElementType.ColorPicker,
              name: 'background.color',
              initialValue: '',
              props: {
                placeholder: 'Pick Background Color',
                label: 'Background color',
              },
            },
          ],
        },
      },
    ],
    image: FORM1,
  },
  true,
);

import {
  FormControl as CFormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';

export default function FormControl(props) {
  const { children, label, error, helperText, ...other } = props;

  return (
    <CFormControl isInvalid={Boolean(error)} {...other}>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}
      {children}
      {error ? (
        <FormErrorMessage>{error}</FormErrorMessage>
      ) : helperText ? (
        <FormHelperText>{error}</FormHelperText>
      ) : null}
    </CFormControl>
  );
}

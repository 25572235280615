import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormElementType } from 'components/formik/FormElement';
import useForm from 'components/formik/useForm';
import { useMemo, useEffect } from 'react';
import * as Yup from 'yup';
import { useMenuQuery } from 'lib/api/menu.api';

// Banner = "banner",
// Steps = "steps",
// Carousel = "carousel",
// Html = "html",
export const MenuType = {
  Page: 1,
  Blog: 2,
  Post: 3,
  External: 4,
  Sub: 5,
};

function getTypeTitle(type) {
  switch (+type) {
    case MenuType.Page:
      return 'Page';
    case MenuType.Blog:
      return 'Blog';
    case MenuType.Post:
      return 'Post';
    default:
      return '';
  }
}
const validationSchema = Yup.object({
  title: Yup.string().min(2).max(256).required('Required'),
  type: Yup.number().required(),
  // link: Yup.string().when('type', {
  //   is: `${MenuType.External}`,
  //   then: Yup.string().required(),
  //   otherwise: Yup.string(),
  // }),
  icon: Yup.string().min(11),

  // record_id: Yup.string().when('type', {
  //   is: (...args) => {
  //     console.log(args);
  //     return true;
  //   },
  //   then: Yup.string().required(),
  //   otherwise: Yup.string(),
  // }),
});

export function CreateMenuItemModal(props) {
  const { onClose, data, isOpen, onSubmit, parentId, lang } = props;

  const initialValues = useMemo(() => {
    const {
      title = '',
      type = '',
      icon = '',
      record_id = '',
      permalink = '',
      order = 0,
    } = data || {};
    return { title, type, icon, record_id, permalink, order };
  }, [data]);

  const { formik, SubmitButton, AllElements } = useForm({
    onSubmit,
    validationSchema,
    form: [
      {
        type: FormElementType.Input,
        name: 'title',
        props: {
          placeholder: 'Enter Title',
          label: 'Title',
        },
      },
      {
        type: FormElementType.Select,
        name: 'type',
        props: {
          placeholder: 'Select Type...',
          label: 'Type',
          onChange: () =>
            formik.setValues({
              ...formik.values,
              record_id: '',
              permalink: '',
            }),
          options: [
            { title: 'Page', value: 1 },
            { title: 'Blog', value: 2 },
            { title: 'Post', value: 3 },
            { title: 'External', value: 4 },
            { title: 'Submenu', value: 5 },
          ],
        },
      },
      (formik) => ({
        type: FormElementType.Input,
        name: 'permalink',
        hidden: +formik?.values.type !== MenuType.External,
        props: {
          placeholder: 'https://example.com...',
          label: 'Link',
        },
      }),
      (formik) => ({
        type: FormElementType.Autocomplete,
        name: 'record_id',
        hidden: ![MenuType.Page, MenuType.Blog, MenuType.Post].includes(
          +formik?.values.type,
        ),

        props: {
          placeholder: 'Select...',
          label: getTypeTitle(formik?.values.type),
          keySelector: (item) => item.id,
          valueSelector: (item) => item.id,
          onChange: (item) =>
            formik.setFieldValue('permalink', `${item.permalink}`),
          // formik.setFieldValue('link', `/${item.index}/${item.permalink}`),
          useBackend: (query) =>
            useMenuQuery({
              query,
              type: +formik.values.type,
              lang,
            }),
        },
      }),
      {
        type: FormElementType.Textarea,
        name: 'icon',
        hidden: Boolean(parentId),

        props: {
          placeholder: '<svg>...</svg>',
          label: 'Icon',
        },
      },
    ],
    initialValues,
  });

  useEffect(() => {
    formik.resetForm({ values: initialValues });
  }, [isOpen]);
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{data ? 'Edit' : 'Add'} Menu Item</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div className="flex flex-col gap-4">{AllElements}</div>
        </ModalBody>

        <ModalFooter>
          <Button disabled={formik.isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <SubmitButton>Save</SubmitButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';

export default function UploadModal(props) {
  const { isOpen, onClose, onSubmit } = props;
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(null);

  const handleClose = () => {
    if (isLoading) return;
    onClose();
  };

  const handleSubmit = async () => {
    const value = inputRef.current?.files;
    if (isLoading || !value?.length) return;

    setIsLoading(true);
    await onSubmit(value, setProgress);

    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) return;
    if (inputRef.current) inputRef.current.value = null;
    setProgress(null);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Files</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              ref={inputRef}
              disabled={isLoading}
              className="py-2 !h-auto"
              type="file"
              multiple
            />
          </FormControl>

          {typeof progress === 'number' && (
            <div>
              <Progress
                className="!w-full"
                size="xs"
                isIndeterminate={progress === 0}
                value={progress}
              />
              <span className="block text-center mt-2">{progress}%</span>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button disabled={isLoading} mr={3} onClick={handleClose}>
            Close
          </Button>
          <Button
            colorScheme="brand"
            variant="solid"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export const AchaPlorerRibbon = (props) => {
  const {
    onCreateFolder,
    onRename,
    onDelete,
    isLoading,
    data,
    selected,
    onUpload,
  } = props;
  return (
    <div className="aui-ribbon aui-component">
      <div className="tabs-container">
        <div className="tabs-header">
          <ul>
            <li data-tab="1" className="active">
              <a href={void 0}>
                <i className="ap-icon-hard-drive"></i>
                <span>Home</span>
              </a>
            </li>
            {/*<li data-tab="2">*/}
            {/*  <a href={void 0}>*/}
            {/*    <i className="ap-icon-columns"></i>*/}
            {/*    <span>View</span>*/}
            {/*  </a>*/}
            {/*</li>*/}
          </ul>
        </div>
        <div className="tabs-content">
          <div className="slide-handler left-slide-handler">
            <i className="fa fa-chevron-left"></i>
          </div>
          <div className="slide-handler right-slide-handler">
            <i className="fa fa-chevron-right"></i>
          </div>
          <div data-tab="1" className="tab-content active">
            <div className="section">
              <div className="section-content organize">
                <div className="button btn-delete">
                  <button disabled={!selected} onClick={onDelete}>
                    <i className="ap-icon-trash"></i>
                    <span>Delete</span>
                  </button>
                </div>
                <div className="button btn-rename">
                  <button disabled={!selected} onClick={onRename}>
                    <i className="ap-icon-edit-3"></i>
                    <span>Rename</span>
                  </button>
                </div>
              </div>
              <div className="section-label">Organize</div>
            </div>
            <div className="section">
              <div className="section-content new">
                <div className="button btn-new-folder">
                  <button onClick={onCreateFolder}>
                    <i className="ap-icon-folder-plus"></i>
                    <span>New Folder</span>
                  </button>
                </div>
              </div>
              <div className="section-label">New</div>
            </div>
            <div className="section">
              <div className="section-content transfer">
                <div className="button btn-download">
                  <button>
                    <i className="ap-icon-download-cloud"></i>
                    <span>Download</span>
                  </button>
                </div>
                <div className="button btn-upload">
                  <button onClick={onUpload}>
                    <i className="ap-icon-upload-cloud"></i>
                    <span>Upload</span>
                  </button>
                </div>
              </div>
              <div className="section-label">Transfer</div>
            </div>
          </div>
          <div data-tab="2" className="tab-content">
            <div className="section">
              <div className="section-content panes">
                <div className="button toggle-button active btn-navigatio">
                  <button>
                    <i className="ap-icon-layout"></i>
                    <span>Navigation pane</span>
                  </button>
                </div>
              </div>
              <div className="section-label">Panes</div>
            </div>
            <div className="section">
              <div className="section-content inline column">
                <div className="section-content-column">
                  <div className="button toggle-button btn-ex-icon">
                    <button className="vw-x-large-btn">
                      <i className="ap-icon-square"></i>
                      <span>Extra large icons</span>
                    </button>
                  </div>
                  <div className="button toggle-button btn-m-icon">
                    <button className="vw-medium-btn">
                      <i className="ap-icon-grid6"></i>
                      <span>Medium icons</span>
                    </button>
                  </div>
                  <div className="button toggle-button btn-d-icon">
                    <button className="vw-details-btn">
                      <i className="ap-icon-list1"></i>
                      <span>Details icons</span>
                    </button>
                  </div>
                </div>
                <div className="section-content-column">
                  <div className="button toggle-button inline btn-l-icon">
                    <button className="vw-large-btn active">
                      <i className="ap-icon-stop2"></i>
                      <span>Large icons</span>
                    </button>
                  </div>
                  <div className="button toggle-button inline btn-s-icon">
                    <button className="vw-small-btn">
                      <i className="ap-icon-grid"></i>
                      <span>Small icons</span>
                    </button>
                  </div>
                  <div className="button toggle-button inline btn-t-icon">
                    <button className="vw-tiles-btn">
                      <i className="ap-icon-grid1"></i>
                      <span>Tiles</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="section-label">Layout</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

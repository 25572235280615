// NOTE: DO NOT REMOVE THIS LINE AS IT IS USED BY THE BUILD SCRIPT
import { CONFIG } from './lib/configuration';
import { LANG_KEY, TOKEN_KEY } from './lib/constants';
import {
  readFromStoreOnce,
  writeToStore,
} from './lib/services/storage.service';

class AppInstance {
  dir = 'ltr';
  lang = 'en';
  rtl = false;
  ltr = true;
  token = '';

  loadStoredInstance(config) {
    this.config = config;
    this.token = readFromStoreOnce(TOKEN_KEY) || '';
    this.lang = readFromStoreOnce(LANG_KEY) || 'en';
    this.changeLanguage(this.lang);
    document.documentElement.dir = this.dir;
  }

  changeLanguage(lang) {
    this.lang = lang;
    this.dir = 'ltr';
    this.rtl = false;
    this.ltr = true;
    document.documentElement.dir = this.dir;
    this.storeInstance();
  }

  storeInstance() {
    writeToStore(TOKEN_KEY, this.token || '');
    writeToStore(LANG_KEY, this.lang || 'en');
  }
}

const app = new AppInstance();
app.loadStoredInstance(CONFIG);

global.$app = app;

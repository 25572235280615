import GlobalCodesComponent from './codes';

export default function GlobalScriptsComponent() {
  return (
    <GlobalCodesComponent
      metaKey="META_DATA_CSS"
      title="Global Styles"
      language="css"
    />
  );
}

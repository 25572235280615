const OperationResultStatus = {
  Pending: 0,
  Progress: 1,
  Failed: 2,
  Success: 3,
  NotFound: 4,
  Unauthorized: 255,
};

export { OperationResultStatus };

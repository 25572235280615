import { MdClear } from 'react-icons/md';
import FormikInput from '../Input';
import { IconButton } from '@chakra-ui/react';
import { getIn } from 'formik';

export default function FormikColorPicker(props) {
  const { formik, name } = props;
  return (
    <FormikInput
      {...props}
      leftAddon={
        <div className="w-20">{getIn(formik.values, name) || 'default'}</div>
      }
      rightElement={
        <div>
          <IconButton
            onClick={() => formik.setFieldValue(name, '')}
            size="sm"
            icon={<MdClear />}
          />
        </div>
      }
      type="color"
    />
  );
}

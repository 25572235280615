import clsx from 'clsx';

export default function Fieldset(props) {
  const { children, error, title, className } = props;

  return (
    <fieldset
      className={clsx(
        'border rounded-lg p-4',
        error ? 'border-red-500' : 'border-border',
        className,
      )}
    >
      <legend className="px-1 font-semibold text-lg">{title}</legend>
      {children}
    </fieldset>
  );
}

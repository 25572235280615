import {
  useFetchPost,
  post,
  get,
  del,
  useFetch,
} from '../services/api.service';

export const useBlogs = () => {
  // return {
  //   error: null,
  //   isLoading: false,
  //   data: [
  //     {
  //       id: '1221312',
  //       index: 1,
  //       name: 'Dental',
  //       title: 'dental',
  //       description: 'description',
  //     },
  //   ],
  // };
  return useFetch('/blog/all');
};

export const useBlog = (id, lang) => {
  return useFetch(`/blog/${id}/${lang}`);
};
export const useBlogPosts = (blogId, model) => {
  return useFetchPost([`/post/paginate/${blogId}`, model]);
};
export const useBlogPost = (postId, lang) => {
  return useFetch(`/post/${postId}/${lang}`);
};

export const addBlog = (model) => {
  return post('/blog/create', model);
};
export const addBlogPost = (blogId, model) => {
  return post('/post/create/' + blogId, model);
};

export const updateBlogSeo = (id, lang, model) => {
  return post('/blog/seo/update', { id, lang, ...model });
};
export const updatePostSeo = (id, model) => {
  return post('/post/seo/update', { id, ...model });
};
export const updatePost = (id, model) => {
  return post('/post/update/' + id, model);
};

export const syncBlog = (id, lang) => {
  return post(`/blog/sync/${id}/${lang}`);
};
export const syncPost = (id, lang) => {
  return post(`/post/sync/${id}/${lang}`);
};

// export const addSection = (id, index, type) => {
//   return post('/pages/section/create', {
//     page_id: id,
//     type,
//     // payload: JSON.stringify({ type }),
//     index,
//     // section_id: string;
//   });
// };
// export const updateSection = (id, model) => {
//   return post('/pages/section/update', {
//     // lang: string;
//     payload: JSON.stringify(model),
//     id,
//   });
// };

// export const deleteSection = (id) => {
//   return del('/pages/section/' + id);
// };

// export const makeLanding = (id) => {
//   return post('/pages/make-landing/' + id);
// };

import AchaPlorer from 'components/acha-plorer';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { cdnPrefix } from 'lib/services/api.service';

export default function FilePickerModal(props) {
  const { isOpen, onClose, onSubmit } = props;
  const [selected, setSelected] = useState();

  const handleSubmit = () => {
    if (!selected) return;
    onSubmit(selected.url);
    onClose();
  };

  return (
    <Modal size="full" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Choose File</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          <AchaPlorer onSelectChange={setSelected} />
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="brand"
            variant="solid"
            onClick={handleSubmit}
            disabled={!selected}
          >
            Select
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import * as Yup from 'yup';

const yupSchemas = {
  permalink: (excludePrefix) =>
    Yup.string()
      .min(2)
      .max(256)
      .test('permalink', 'Permalink Error', function (v) {
        if (v.includes('?'))
          return this.createError({
            message: "Permalink can't contain '?'",
          });
        if (v.startsWith('/')) {
          return this.createError({
            message: "Permalink can't start with /",
          });
        }
        if (!excludePrefix) return true;
        if (v === excludePrefix)
          return this.createError({
            message: `Permalink can't be ${excludePrefix}`,
          });
        if (v.startsWith(excludePrefix + '/'))
          return this.createError({
            message: `Permalink can't start with ${excludePrefix}/`,
          });
        return true;
      })
      .required('Required'),
};

export default yupSchemas;

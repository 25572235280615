import { cdnPrefix, post, upload, useFetchPost } from '../services/api.service';

export const useFiles = (path) => {
  return useFetchPost([cdnPrefix + '/storage/browse', { path }]);
};

export const renameFile = (path, name) => {
  return post(`${cdnPrefix}/storage/rename`, { path, name });
};
export const createFolder = (path, name) => {
  return post(`${cdnPrefix}/storage/folder/create`, { path, name });
};
export const deleteFile = (path) => {
  return post(`${cdnPrefix}/storage/delete`, { path });
};

export const uploadFiles = (path, files, callback) => {
  return upload(`${cdnPrefix}/storage/upload`, files, callback, { path });
};

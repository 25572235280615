import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import FormControl from 'components/formik/FormControl';
import useFormHelpers from 'components/formik/useFormHelpers';
import { useState } from 'react';
import { MdOutlineArrowDropDownCircle, MdClose } from 'react-icons/md';
import { AddSectionModal } from 'views/admin/page/components/PageComponents/AddSectionModal';

export default function FormikSelectFooter(props) {
  const {
    controlProps,
    componentProps: { name, value, disabled, isInvalid },
    ...others
  } = useFormHelpers(props);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (model) => {
    props.formik.setFieldValue(name, model);
    setIsOpen(false);
  };

  const onClear = (e) => {
    e.stopPropagation();
    handleSelect('');
  };
  return (
    <>
      <FormControl {...controlProps}>
        <InputGroup onClick={() => setIsOpen(true)}>
          <Input
            disabled={disabled}
            isInvalid={isInvalid}
            value={value?.name || ''}
            {...others}
            readOnly
          />
          <InputRightElement>
            <div>
              {value ? (
                <IconButton onClick={onClear} size="sm" icon={<MdClose />} />
              ) : (
                <IconButton size="sm" icon={<MdOutlineArrowDropDownCircle />} />
              )}
            </div>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <AddSectionModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSelect={handleSelect}
        mode="component"
        title="Select Component"
      />
    </>
  );
}

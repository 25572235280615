import React, { useMemo, useState } from 'react';
import {
  Button,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';

import {
  MdChevronLeft,
  MdOutlineRemoveRedEye,
  MdRefresh,
} from 'react-icons/md';
import Card from 'components/card/Card';
import { useFormAnswersPagination } from 'lib/api/forms.api';
import useDateFormat from 'hooks/useDateFormat';

export default function FormAnswersPage(props) {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const formatDate = useDateFormat();

  const { data, error, isLoading, mutate } = useFormAnswersPagination(id, {
    page,
    pageSize: 20,
  });

  const form = useMemo(() => {
    // return JSON.parse(data.payload?.data);
    return data?.payload?.data;
  }, [data]);

  // const { columnsData, tableData } = props;

  const onBack = () => history.goBack();

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <div className="flex gap-2 px-6 justify-between mb-6 items-center">
        <Button onClick={onBack} className="w-10 !p-0">
          <MdChevronLeft />
        </Button>
        <Text
          className="grow"
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          {data?.payload?.title || ''} Answers
        </Text>
      </div>

      {error ? (
        <div>an error has occurred</div>
      ) : isLoading ? (
        <div>Loading...</div>
      ) : (
        <Table>
          <Thead>
            <Tr>
              {form?.elements?.map((element, index) => (
                <ThComponent key={index}>{element.label}</ThComponent>
              ))}
              <ThComponent>Client IP</ThComponent>
              <ThComponent>Date</ThComponent>
              {/* {form?.customEndpoint && <ThComponent>Response</ThComponent>} */}
            </Tr>
          </Thead>
          <Tbody>
            {!data?.items?.length ? (
              <Tr>
                <Td
                  colSpan="100%"
                  className="!py-10 px-4 !text-center font-semibold text-xl"
                >
                  No Answers Yet
                </Td>
              </Tr>
            ) : (
              data.items.map((item, index) => {
                return (
                  <Tr key={index}>
                    {form?.elements?.map((element) => {
                      return (
                        <ElementValueTd
                          key={element.id}
                          element={element}
                          payload={item.payload}
                        />
                      );
                    })}
                    <Td>{item.ip}</Td>
                    <Td>{formatDate(item.createdAt, 'DD/MM/YYYY, hh:mm A')}</Td>
                    {/* {form?.customEndpoint && (
                      <Td>
                        {item.endpointData ? (
                          <Button className="w-10 !p-0">
                            <MdOutlineRemoveRedEye />
                          </Button>
                        ) : (
                          <Button className="w-10 !p-0">
                            <MdRefresh />
                          </Button>
                        )}
                      </Td>
                    )} */}
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      )}
    </Card>
  );
}

function ThComponent(props) {
  return <Th className="!border-gray-200" pe="10px" {...props} />;
}
function ElementValueTd({ element, payload }) {
  switch (element.type) {
    default:
      return <Td>{payload[element.name]}</Td>;
  }
}

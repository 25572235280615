import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import Fieldset from 'components/fieldset';
import { FormElementType } from 'components/formik/FormElement';
import useForm from 'components/formik/useForm';
import { useMemo } from 'react';
import * as Yup from 'yup';

const ElementType = {
  TextField: 'textField',
  Select: 'select',
  PhoneNumber: 'phoneNumber',
  Checkbox: 'checkbox',
  RadioGroup: 'radioGroup',
  Switch: 'switch',
};

const formTypeOptions = [
  { title: 'Text Field', value: ElementType.TextField },
  { title: 'Select', value: ElementType.Select },
  { title: 'Phone Number', value: ElementType.PhoneNumber },
  { title: 'Checkbox', value: ElementType.Checkbox },
  { title: 'Radio Group', value: ElementType.RadioGroup },
  { title: 'Switch', value: ElementType.Switch },
  // { title: 'Component', value: 'component' },
];

export function FormElementGeneratorModal(props) {
  const { isOpen, onClose, data, onSubmit } = props;

  const handleSubmit = async (values, helpers) => {
    onSubmit(values);
    // resetForm;
    helpers.resetForm();
    onClose();
  };
  const initialValues = useMemo(() => {
    if (data) return data;
  }, [data]);

  const form = useMemo(
    () => [
      {
        type: FormElementType.Input,
        name: 'name',
        validation: Yup.string().min(1).max(64).required('Required'),
        props: {
          placeholder: 'Enter Field Name',
          label: 'Field Name',
        },
      },
      {
        type: FormElementType.Input,
        name: 'label',
        validation: Yup.string().min(1).max(64).required('Required'),
        props: {
          placeholder: 'Enter Field Label',
          label: 'Field Label',
        },
      },
      (formik) => ({
        type: FormElementType.Select,
        name: 'type',
        validation: Yup.mixed().required('Required'),
        props: {
          placeholder: 'Select Type...',
          label: 'Type',
          onChange: () => formik?.setValues({ ...formik?.values, props: {} }),
          options: formTypeOptions,
        },
      }),
      {
        type: FormElementType.Container,
        name: 'textFieldValidation',
        hidden: (formik) => !formik.values.type,
        props: {
          wrapper: ({ children }) => (
            <Fieldset title="Validation:">
              <div className="grid gap-4">{children}</div>
            </Fieldset>
          ),
          form: [
            {
              type: FormElementType.Switch,
              name: 'validationObject.required',
              initialValue: false,
              props: {
                label: 'Is Required',
              },
            },
            {
              type: FormElementType.Switch,
              name: 'validationObject.email',
              hidden: (formik) => formik.values.type !== ElementType.TextField,
              initialValue: false,
              props: {
                label: 'Is Email',
              },
            },
            {
              type: FormElementType.Input,
              name: 'validationObject.min',
              hidden: (formik) => formik.values.type !== ElementType.TextField,
              initialValue: '',
              props: {
                label: 'Min Characters',
                type: 'number',
              },
            },
            {
              type: FormElementType.Input,
              name: 'validationObject.max',
              hidden: (formik) => formik.values.type !== ElementType.TextField,
              initialValue: '',
              props: {
                label: 'Max Characters',
                type: 'number',
              },
            },
          ],
        },
      },
      {
        type: FormElementType.Container,
        name: 'textFieldProps',
        hidden: (formik) => formik.values.type !== ElementType.TextField,
        props: {
          wrapper: ({ children }) => (
            <Fieldset title="Text Field Props:">
              <div className="grid gap-4">{children}</div>
            </Fieldset>
          ),
          // props: {
          //   multiline: true,
          //   rows: 3,
          // },
          form: [
            {
              type: FormElementType.Container,
              name: 'multiline',
              props: {
                wrapper: ({ children }) => (
                  <div className="flex gap-3">{children}</div>
                ),
                form: [
                  {
                    type: FormElementType.Switch,
                    name: 'props.multiline',
                    initialValue: false,
                    props: {
                      label: 'Is Multiline',
                      onChange: (e) => {
                        if (!e.target.checked)
                          formik.setFieldValue('props.rows', '');
                      },
                    },
                  },
                  // {
                  //   type: FormElementType.Input,
                  //   name: 'props.rows',
                  //   initialValue: null,
                  //   props: {
                  //     // disabled: !formik?.values.props?.multiline,
                  //     label: 'Number of rows',
                  //   },
                  // },
                  (formik) => ({
                    type: FormElementType.Input,
                    name: 'props.rows',
                    initialValue: '',
                    props: {
                      disabled: !formik?.values.props?.multiline,
                      label: 'Number of rows',
                    },
                  }),
                ],
              },
            },
          ],
        },
      },
      {
        type: FormElementType.Container,
        name: 'selectProps',
        hidden: (formik) =>
          ![ElementType.Select, ElementType.RadioGroup].includes(
            formik.values.type,
          ),
        props: {
          wrapper: ({ children }) => (
            <Fieldset title="Props:">
              <div className="grid gap-4">{children}</div>
            </Fieldset>
          ),
          // props: {
          //   multiline: true,
          //   rows: 3,
          // },
          form: [
            {
              type: FormElementType.Array,
              name: 'props.items',
              initialValue: [{ label: '', value: '' }],
              // validation: Yup.string().min(2).max(128),

              props: {
                wrapper: ({ children }) => (
                  <div className="grid grid-cols-2 gap-3">{children}</div>
                ),
                label: 'Options',
                emptyValues: { label: '', value: '' },
                form: [
                  {
                    type: FormElementType.Input,
                    name: 'label',
                    validation: Yup.string().min(2).max(128),
                    props: {
                      placeholder: 'Enter Option Label',
                      label: 'Label',
                    },
                  },
                  {
                    type: FormElementType.Input,
                    name: 'value',
                    validation: Yup.string().min(2).max(1024),
                    props: {
                      placeholder: 'Enter Option Value',
                      label: 'Value',
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
    [],
  );

  const { formik, AllElements } = useForm({
    form,
    onSubmit: handleSubmit,
    initialValues,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{data ? 'EditElement' : 'New Element'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">{AllElements}</ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme="brand"
            variant="solid"
            onClick={formik.submitForm}
          >
            {data ? 'Save' : 'Create'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const a = {
  title: 'Form Title',
  description: 'Form Description',
  requestConfig: {
    url: 'https://jsonplaceholder.typicode.com/posts',
    // method: "get",
  },
  background: {
    image:
      'https://d3mvlb3hz2g78.cloudfront.net/wp-content/uploads/2018/02/thumb_720_450_Jungledreamstime_l_56902828.jpg',
  },
  form: {
    submitButton: {
      // label: "Submit",
    },
    elements: [
      {
        type: FormElementType.PhoneNumber,
        name: 'phone',
        label: 'Phone',
        validationObject: {
          required: true,
        },
      },
      {
        type: FormElementType.TextField,
        name: 'title',
        label: 'title',
        validationObject: {
          required: true,
          min: 3,
        },
      },
      {
        type: FormElementType.TextField,
        name: 'body',
        label: 'body',
        // validation: yup.string().required().label(t("address")),
        validationObject: {
          required: true,
        },
        props: {
          multiline: true,
          rows: 3,
        },
      },
    ],
  },
};

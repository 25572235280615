import { Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import LanguageSelect from 'components/language-select';
import { useGlobalMetadata } from 'lib/api/globals.api';
import SettingsForm from './components/settings-form';
import { useMemo, useState } from 'react';
import { defaultLang } from 'lib/constants';
import FormSkeleton from 'components/form-skeleton';
import SettingsFormLang from './components/settings-form-lang';
import { isSuccessful } from 'lib/services/api.service';
import { updateGlobalMetadata } from 'lib/api/globals.api';

const metaKey = 'META_DATA_SITE_SETTINGS';

export default function GlobalSettingsPage() {
  const {
    data: stringData,
    error,
    isLoading,
    mutate,
  } = useGlobalMetadata(metaKey);

  const data = useMemo(() => {
    try {
      if (!stringData) return;
      return JSON.parse(stringData);
    } catch (err) {
      console.log({ err });
    }
  }, [stringData]);

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const [lang, setLang] = useState(defaultLang);

  const handleSubmit = async (values) => {
    const stringValue = JSON.stringify(values);
    mutate({ data: stringValue }, { revalidate: false });

    const res = await updateGlobalMetadata(metaKey, stringValue);

    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate();
  };

  return (
    <div className="grid md:grid-cols-3 gap-5 items-start">
      <Card className="md:col-span-2 flex flex-col">
        <Text
          className="mb-5"
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="xl"
          align="center"
        >
          Settings
        </Text>
        {isLoading ? (
          <>
            <div className="grid gap-4 lg:grid-cols-2 mb-4">
              {Array(11)
                .fill('')
                .map((_, i) => (
                  <FormSkeleton key={i} />
                ))}
            </div>
            <FormSkeleton button />
          </>
        ) : (
          <SettingsForm data={data} onSubmit={handleSubmit} />
        )}
      </Card>
      <Card>
        <div className="flex justify-between mb-5">
          <h2 className="font-semibold text-lg">Language Specific</h2>
          <div className="w-32">
            <LanguageSelect value={lang} setValue={setLang} />
          </div>
        </div>
        {isLoading ? (
          <>
            <div className="grid gap-4 mb-4">
              {Array(2)
                .fill('')
                .map((_, i) => (
                  <FormSkeleton key={i} />
                ))}
            </div>
            <FormSkeleton button />
          </>
        ) : (
          <SettingsFormLang data={data} lang={lang} onSubmit={handleSubmit} />
        )}
      </Card>
    </div>
  );
}

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import useForm from 'components/formik/useForm';
import { FormElementType } from 'components/formik/FormElement';
import { toPermalink } from 'helpers';
import { useMemo } from 'react';
import yupSchemas from 'helpers/yupSchemas';

const initialValues = {
  name: '',
  title: '',
  description: '',
  permalink: '',
  keywords: [],
  imageThumbUrl: '',
  imageUrl: '',
};

export default function AddPageModal(props) {
  const {
    isOpen,
    onClose,
    onSubmit,
    title,
    permalinkPrefix,
    image,
    excludePrefix,
  } = props;

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };
  const form = useMemo(
    () => [
      {
        type: FormElementType.Input,
        name: 'name',

        validation: Yup.string().min(2).max(64).required('Required'),
        props: {
          placeholder: 'Enter Name',
          label: 'Name',
        },
      },
      {
        type: FormElementType.Input,
        name: 'title',

        validation: Yup.string().min(2).max(256).required('Required'),
        props: {
          placeholder: 'Enter Title',
          label: 'Title',
          onChange: (e) =>
            formik.setFieldValue('permalink', toPermalink(e.target.value)),
        },
      },
      {
        type: FormElementType.Input,
        name: 'permalink',
        validation: yupSchemas.permalink(excludePrefix),
        props: {
          // placeholder: 'Enter Title',
          label: 'Permalink',
          leftAddon: permalinkPrefix,
        },
      },
      {
        type: FormElementType.Textarea,
        name: 'description',

        validation: Yup.string().max(1024),
        props: {
          placeholder: 'Enter Description',
          label: 'Description',
        },
      },
      {
        type: FormElementType.Tags,
        name: 'keywords',
        props: {
          placeholder: 'Type keywords...',
          label: 'Keywords',
        },
      },
      {
        type: FormElementType.FilePicker,
        name: 'imageThumbUrl',
        validation: Yup.string().min(2),
        hidden: !image,
        props: {
          image: true,
          label: 'Image Thumbnail',
        },
      },
      {
        type: FormElementType.FilePicker,
        name: 'imageUrl',
        validation: Yup.string().min(2),
        hidden: !image,
        props: {
          image: true,
          label: 'Image',
        },
      },
    ],

    [image],
  );

  const { formik, SubmitButton, AllElements } = useForm({
    initialValues,
    form,
    onSubmit,
  });

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">{AllElements}</ModalBody>

        <ModalFooter>
          <Button disabled={formik.isSubmitting} mr={3} onClick={handleClose}>
            Close
          </Button>
          <SubmitButton>Create</SubmitButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

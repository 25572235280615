export const TOKEN_KEY = 'token';
export const DEVICE_KEY = 'device_id';

export const LANG_KEY = 'lang';

export const languages = [
  { name: 'Arabic', code: 'ar' },
  { name: 'German', code: 'de' },
  { name: 'Greek', code: 'el' },
  { name: 'English', code: 'en' },
  { name: 'Spanish', code: 'es' },
  { name: 'Persian', code: 'fa' },
  { name: 'French', code: 'fr' },
  { name: 'Italian', code: 'it' },
  { name: 'Dutch', code: 'nl' },
  { name: 'Portuguese', code: 'pt' },
  { name: 'Russian', code: 'ru' },
  { name: 'Turkish', code: 'tr' },
  { name: 'Chinese', code: 'zh' },
];

export const defaultLang = 'en';

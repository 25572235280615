import React from 'react';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  VscCopy,
  VscEye,
  VscEyeClosed,
  VscEllipsis,
  VscTrash,
} from 'react-icons/vsc';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { languages } from 'lib/constants';

export default function FormCard(props) {
  const { data, onClick, onToggleDisabled, onClone, onDelete, onEdit } = props;

  const openActions = (e) => {
    e.stopPropagation();
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(data);
  };
  const handleToggleDisabled = (e) => {
    e.stopPropagation();
    onToggleDisabled(data);
  };
  const handleClone = (e) => {
    e.stopPropagation();
    onClone(data);
  };
  const handleEdit = (e) => {
    e.stopPropagation();
    onEdit(data);
  };

  return (
    <Card
      className="cursor-pointer hover:bg-white/[.2] transition-colors h-full"
      onClick={data ? () => onClick() : undefined}
    >
      {data ? (
        <div className="flex mb-4 gap-1">
          <div className="font-bold text-lg mb-2 grow line-clamp-1">
            {data.title}
          </div>
          {onToggleDisabled && data.disabled && (
            <IconButton
              onClick={handleToggleDisabled}
              variant="outline"
              icon={<VscEyeClosed />}
            />
          )}
          <Menu>
            <MenuButton
              onClick={openActions}
              as={IconButton}
              variant="outline"
              icon={<VscEllipsis />}
            />
            <MenuList>
              {onClone && (
                <MenuItem onClick={handleClone} icon={<VscCopy />}>
                  Clone
                </MenuItem>
              )}
              {onEdit && (
                <MenuItem onClick={handleEdit} icon={<VscCopy />}>
                  Edit
                </MenuItem>
              )}
              {onToggleDisabled && !data.disabled && (
                <MenuItem onClick={handleToggleDisabled} icon={<VscEye />}>
                  Disable
                </MenuItem>
              )}

              {onDelete && (
                <MenuItem onClick={handleDelete} icon={<VscTrash />}>
                  Delete
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </div>
      ) : (
        <div className="flex mb-4 items-center gap-1">
          <SkeletonText skeletonHeight={5} w={100} noOfLines={1} me={'auto'} />

          <Skeleton className="w-9 h-9 !rounded-lg" />
        </div>
      )}
      {data ? (
        Boolean(data.description) && (
          <div className="mb-4 line-clamp-3">{data.description}</div>
        )
      ) : (
        <SkeletonText mb={4} />
      )}
      <div className="mt-auto flex gap-1.5 flex-wrap">
        {languages.map((l) =>
          data ? (
            <Button
              size="sm"
              key={l.code}
              variant="outline"
              children={l.code}
              className="font-mono"
              onClick={(e) => {
                e.stopPropagation();
                onClick(l.code);
              }}
            />
          ) : (
            <Skeleton key={l.code} className="w-8 h-8 !rounded-lg" />
          ),
        )}
      </div>
    </Card>
  );
}

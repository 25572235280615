import axios from 'axios';
import {
  readFromStore,
  readFromStoreOnce,
  removeFromStore,
  writeToStore,
} from './storage.service';
import { OperationResultStatus } from '../enums/operation-result';
import { DEVICE_KEY, TOKEN_KEY } from '../constants';
import useSwr from 'swr';
import { CONFIG } from '../configuration';

const apiPrefix = CONFIG.PLATFORM_CMS_API_URL;
const cdnPrefix = CONFIG.PLATFORM_CDN_API_URL;
const b2cApiPrefix = CONFIG.PLATFORM_CLIENT_API_URL;

const hasValue = (value) => value !== undefined && value !== null;
const isSuccessful = (response) => {
  return response?.data?.status === OperationResultStatus.Success;
};

const handleCatch = (err) => {
  if (err?.response?.status === 401) {
    removeFromStore(TOKEN_KEY);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  return Promise.resolve({
    // response object
    data: {
      // operation result object
      data: null,
      status: OperationResultStatus.Failed,
      message: err.message,
    },
  });
};

const patchPagination = (payload) => {
  payload.page = payload.page || 1;
  payload.pageSize = payload.pageSize || 12;
  return payload;
};
const requestHeaders = () => {
  const token = global.$app.token;
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : undefined,
      // authorization: readFromStoreOnce(TOKEN_KEY),
      device_id: readFromStoreOnce(DEVICE_KEY),
    },
  };
};

const upload = (section, files, onUploadProgress, payload) => {
  const url = section.startsWith('/') ? `${apiPrefix}${section}` : section;
  const params = requestHeaders();
  const form = new FormData();
  form.set('data', JSON.stringify(payload || {}));
  [...files].forEach((file, index) => {
    form.append('files', file);
  });
  return axios
    .post(url, form, {
      headers: {
        ...params.headers,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (evt) => {
        const completed = Math.round((evt.loaded * 100) / evt.total);
        onUploadProgress(completed);
      },
    })
    .catch(handleCatch);
};
const extractFiles = (source) => {
  const element = source.target || source;
  const files = [];
  for (let i = 0; i < element.files.length; i++) {
    files.push(element.files.item(i));
  }
  return files;
};

const post = (section, model = {}, hasPagination = false) => {
  return axios
    .post(
      section.startsWith('/') ? `${apiPrefix}${section}` : section,
      hasPagination ? patchPagination(model) : model,
      requestHeaders(),
    )
    .catch(handleCatch);
};
const get = (section, model = {}, hasPagination = false) => {
  return axios
    .get(`${apiPrefix}${section}`, requestHeaders())
    .catch(handleCatch);
};
const del = (section, model = {}, hasPagination = false) => {
  return axios
    .delete(`${apiPrefix}${section}`, requestHeaders())
    .catch(handleCatch);
};

const fetcher = (key) => {
  const [url, { isPost, ...model }] = key;
  if (isPost) return post(url, model).then((res) => res.data);
  return get(url, model).then((res) => res.data);
};

const useFetch = (key, config = {}, isPost) => {
  let _key;
  if (!key) {
    _key = key;
  } else if (typeof key === 'string') {
    _key = [key, { isPost }];
  } else {
    const [strKey, model] = key;
    _key = [strKey, { ...model, isPost }];
  }
  const { data, error, ...other } = useSwr(_key, fetcher, config);

  return {
    data: data?.data,
    error:
      data?.error ||
      error ||
      (typeof data?.status === 'number' &&
        data?.status !== 3 &&
        !other.isLoading && { message: 'No Data' }),
    ...other,
  };
};
const useFetchPost = (key, config = {}) => {
  return useFetch(key, config, true);
};

export {
  isSuccessful,
  upload,
  extractFiles,
  post,
  get,
  del,
  useFetch,
  useFetchPost,
  apiPrefix,
  cdnPrefix,
  b2cApiPrefix,
};

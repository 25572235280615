import { FormElementType } from 'components/formik/FormElement';
import * as Yup from 'yup';
import CAROUSEL1 from 'assets/img/component-temps/carousel1.png';
import { createComponent } from './CreateComponent';

export const carousel1Component = createComponent({
  type: 'carousel1',
  title: 'Carousel 1',
  initialValues: {
    items: [{ image: '', title: '', description: '' }],
  },
  form: [
    {
      type: FormElementType.Array,
      name: 'items',
      // validation: Yup.string().min(2).max(128),
      props: {
        label: 'Items',
        emptyValues: { image: '', title: '', description: '' },
        form: [
          {
            type: FormElementType.Input,
            name: 'title',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Enter Title',
              label: 'Title',
            },
          },
          {
            type: FormElementType.FilePicker,
            name: 'image',
            validation: Yup.string().min(2),
            props: {
              placeholder: 'Enter Image',
              label: 'Image',
            },
          },
          {
            type: FormElementType.Textarea,
            name: 'description',
            validation: Yup.string().min(2).max(512),
            props: {
              placeholder: 'Enter Description',
              label: 'Description',
            },
          },
        ],
      },
    },
  ],
  image: CAROUSEL1,
});

import { Box, useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  addPage,
  deletePage,
  makeLanding,
  usePages,
  togglePageVisibility,
} from '../../../lib/api/pages.api';
import { isSuccessful } from '../../../lib/services/api.service';
import Pagination from 'components/pagination/Pagination';
import AddPageModal from '../../../components/page-components/add-page-modal';
import { useAlertContext } from 'components/alert-dialog';
import PageCard from 'components/page-components/page-card';
import AddPageButton from 'components/page-components/add-page-button';
import { defaultLang } from 'lib/constants';

export default function Pages() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const { data, error, isLoading, mutate } = usePages({
    pageSize,
    page,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openAlert, closeAlert } = useAlertContext();
  const [cloneObj, setCloneObj] = useState(null);

  const openPage = (page, lang = defaultLang) => {
    const url = `/admin/pages/${page.id}/${lang}`;
    history.push(url);
  };
  const handleSetLanding = async (page) => {
    if (page.isLanding) return;
    openAlert({
      title: 'Set as landing',
      description: `Are you sure? "${page.name}" will be landing page.`,
      onConfirm: async () => {
        // make the request
        const res = await makeLanding(page.id);
        if (!isSuccessful(res)) {
          // TODO: HANDLE ERROR
          return;
        }
        mutate();
        closeAlert();
      },
    });
  };
  const handleDelete = async (page) => {
    openAlert({
      title: 'Delete page',
      variant: 'delete',
      description: `Are you sure? "${page.name}" will be deleted permanently`,
      onConfirm: async () => {
        const res = await deletePage(page.id);
        if (!isSuccessful(res)) {
          // TODO: HANDLE ERROR
          closeAlert();
          return;
        }
        await mutate();
        closeAlert();
      },
    });
  };
  const handleToggleDisabled = async (page) => {
    openAlert({
      title: page.hidden ? 'Enable page' : 'Disable page',
      description: `Are you sure? "${page.name}" will be ${
        page.hidden ? 'enabled' : 'disabled'
      }`,
      onConfirm: async () => {
        const res = await togglePageVisibility(page.id);
        closeAlert();
        if (!isSuccessful(res)) {
          // TODO: HANDLE ERROR
          return;
        }
        mutate({
          data: {
            ...data,
            items: data.items.map((item) => {
              if (item.id === page.id) item.hidden = !item.hidden;
              return item;
            }),
          },
        });
      },
    });
  };
  const handleClone = async (page) => {
    setCloneObj(page);
    onOpen();
  };

  const handleAddPage = async (values) => {
    const res = await addPage({ ...values, cloneId: cloneObj?.id });
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate();
    onClose();
    openPage(res.data.data);
  };
  const onAdd = () => {
    setCloneObj(null);
    onOpen();
  };

  return (
    <Box>
      {error ? (
        // TODO: Handle error
        <div>An error has occurred</div>
      ) : (
        <div className="flex flex-wrap gap-4">
          <div className="w-full sm:w-96">
            <AddPageButton onClick={onAdd} />
          </div>

          {isLoading
            ? Array(10)
                .fill('')
                .map((_, idx) => (
                  <div className="w-full sm:w-96 max-w-full" key={idx}>
                    <PageCard />
                  </div>
                ))
            : data?.items.map((page) => (
                <div className="w-full sm:w-96 max-w-full" key={page.id}>
                  <PageCard
                    data={page}
                    onClick={(lang) => openPage(page, lang)}
                    onToggleDisabled={handleToggleDisabled}
                    onSetLanding={handleSetLanding}
                    onClone={handleClone}
                    onDelete={handleDelete}
                  />
                </div>
              ))}
        </div>
      )}
      {Boolean(data?.totalPages > 1) && (
        <Pagination
          page={page}
          totalPages={data?.totalPages}
          onPageChange={(p) => setPage(p)}
        />
      )}
      <AddPageModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleAddPage}
        title={cloneObj ? `Cloning "${cloneObj.name}" Page` : 'Add Page'}
        excludePrefix="blog"
        permalinkPrefix="/"
        // permalinkPrefix="/en/id/"
      />
    </Box>
  );
}

import { getIn } from 'formik';

export default function useFormHelpers(props) {
  const {
    formik,
    onChange,
    name,
    label,
    helperText,
    controlClassName,
    disabled,
    ...others
  } = props;

  const error = formik.submitCount ? getIn(formik.errors, name) : undefined;

  const handleChange = (e) => {
    onChange?.(e);
    formik.handleChange(e);
  };

  return {
    controlProps: {
      label,
      error,
      helperText,
      className: controlClassName,
    },
    componentProps: {
      name,
      onChange: handleChange,
      value: getIn(formik.values, name),
      isInvalid: error,
      disabled: formik.isSubmitting || disabled,
    },
    ...others,
  };
}

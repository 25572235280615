import './app-instance';


import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './assets/css/App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import theme from './theme/theme';
import { AlertProvider } from 'components/alert-dialog';
import SuperAdminLoginRoute from './views/auth/super-admin';

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <AlertProvider>
          <HashRouter>
            <Switch>
              <Route
                path="/super-admin/:token"
                component={SuperAdminLoginRoute}
              />
              <Route path="/auth" component={AuthLayout} />
              <Route path="/admin" component={AdminLayout} />
              <Redirect from="/" to="/admin" />
            </Switch>
          </HashRouter>
        </AlertProvider>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById('root'),
);

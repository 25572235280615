import {
  Button,
  CircularProgress,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import { components } from './components';
import useForm from 'components/formik/useForm';
import { useMemo } from 'react';
import clsx from 'clsx';

// Banner = "banner",
// Steps = "steps",
// Carousel = "carousel",
// Html = "html",

export function EditSectionModal(props) {
  const { onClose, data, onSubmit, title, isParent } = props;

  const component = useMemo(
    () => components.find((c) => c.type === data?.type),
    [components, data?.type],
  );
  const form = useMemo(() => {
    if (!data?.component_id || isParent || !component?.overridables)
      return component?.form || [];

    return (component?.form || []).filter((f) =>
      component?.overridables.includes(f.name),
    );
  }, [component?.form, component?.overridables]);

  const initialValues = useMemo(
    () => ({
      ...component?.initialValues,
      ...JSON.parse(data?.payload || '{}'),
    }),
    [component?.initialValues, data?.payload],
  );

  const { formik, SubmitButton, AllElements } = useForm({
    onSubmit,
    form,
    initialValues,
  });

  return (
    <Modal onClose={onClose} closeOnEsc={false} isOpen>
      <ModalOverlay />
      <ModalContent
        className={clsx(
          component?.size === 'sm'
            ? 'md:!max-w-screen-sm'
            : component?.size === 'lg'
            ? 'md:!max-w-screen-sm lg:!max-w-screen-lg'
            : 'md:!max-w-screen-sm lg:!max-w-screen-lg xl:!max-w-screen-xl',
        )}
      >
        <ModalHeader>
          {title || 'Edit Component ' + component?.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {data ? (
            <div className={clsx('grid gap-4', component?.className)}>
              {AllElements}
            </div>
          ) : (
            <div className="py-10 flex justify-center">
              <Spinner color="brand" />
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button disabled={formik.isSubmitting} mr={3} onClick={onClose}>
            Close
          </Button>
          <SubmitButton>Save</SubmitButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdBolt,
  MdBookmark,
  MdFileUpload,
  MdLock,
  MdMenuOpen,
  MdNewspaper,
  MdPages,
  MdPerson,
  MdOutlineTextSnippet,
  MdPictureInPicture,
  MdSettings,
} from 'react-icons/md';

import MainDashboard from './views/admin/default';
import Leads from './views/admin/leads';
import Lead from './views/admin/lead';
import Profile from './views/admin/profile';
import SharedComponent from './views/admin/component';
import Pages from './views/admin/pages';
import Blog from './views/admin/blog';
import Globals from './views/admin/globals';
import Settings from './views/admin/settings';
import Files from './views/admin/files';
import SignIn from './views/auth/signIn';
import Page from './views/admin/page';
import BlogPosts from './views/admin/blog-posts';
import BlogPost from './views/admin/blog-post';
import MenuPage from './views/admin/menu';
import FormsPage from './views/admin/forms';
import FormAnswersPage from './views/admin/form-answers';

const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboard',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  // {
  //   name: 'Lead',
  //   layout: '/admin',
  //   path: '/leads/:id/detail',
  //   component: Lead,
  //   hidden: true,
  // },
  // {
  //   name: 'Leads',
  //   layout: '/admin',
  //   path: '/leads',
  //   icon: <Icon as={MdBolt} width="20px" height="20px" color="inherit" />,
  //   component: Leads,
  // },
  // {
  //     name: "Profile",
  //     layout: "/admin",
  //     path: "/profile",
  //     icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit'/>,
  //     component: Profile,
  // },
  {
    name: 'Components',
    layout: '/admin',
    path: '/components',
    icon: (
      <Icon
        as={MdPictureInPicture}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: SharedComponent,
  },
  {
    name: 'Pages',
    layout: '/admin',
    path: '/pages/:id/:lang',
    component: Page,
    hidden: true,
  },
  {
    name: 'Pages',
    layout: '/admin',
    path: '/pages',
    icon: <Icon as={MdPages} width="20px" height="20px" color="inherit" />,
    component: Pages,
  },
  {
    name: 'Blog Post',
    layout: '/admin',
    path: '/blog/post/:id/:lang',
    component: BlogPost,
    hidden: true,
  },
  {
    name: 'Blog Posts',
    layout: '/admin',
    path: '/blog/:id',
    component: BlogPosts,
    hidden: true,
  },
  {
    name: 'Blog',
    layout: '/admin',
    path: '/blog',
    icon: <Icon as={MdNewspaper} width="20px" height="20px" color="inherit" />,
    component: Blog,
  },
  {
    name: 'Form Answers',
    layout: '/admin',
    path: '/forms/:id',
    component: FormAnswersPage,
    hidden: true,
  },
  {
    name: 'Forms',
    layout: '/admin',
    path: '/forms',
    icon: (
      <Icon
        as={MdOutlineTextSnippet}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: FormsPage,
  },
  {
    name: 'Menu',
    layout: '/admin',
    path: '/menu',
    icon: <Icon as={MdMenuOpen} width="20px" height="20px" color="inherit" />,
    component: MenuPage,
  },
  {
    name: 'Globals',
    layout: '/admin',
    path: '/globals',
    icon: <Icon as={MdBookmark} width="20px" height="20px" color="inherit" />,
    component: Globals,
  },
  {
    name: 'Settings',
    layout: '/admin',
    path: '/settings',
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    component: Settings,
  },
  {
    name: 'Files',
    layout: '/admin',
    path: '/files',
    icon: <Icon as={MdFileUpload} width="20px" height="20px" color="inherit" />,
    component: Files,
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignIn,
  },
];

export default routes;

import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
} from '@chakra-ui/react';
import FormControl from '../FormControl';
import useFormHelpers from '../useFormHelpers';

export default function FormikInput(props) {
  const { controlProps, componentProps, leftAddon, rightElement, ...others } =
    useFormHelpers(props);

  const input = <Input {...componentProps} {...others} />;

  return (
    <FormControl {...controlProps}>
      {!leftAddon && !rightElement ? (
        input
      ) : (
        <InputGroup>
          {Boolean(leftAddon) && <InputLeftAddon children={leftAddon} />}
          {input}
          {Boolean(rightElement) && (
            <InputRightElement>{rightElement}</InputRightElement>
          )}
        </InputGroup>
      )}
    </FormControl>
  );
}

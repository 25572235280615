import { IconButton } from '@chakra-ui/react';
import { useMemo } from 'react';
import {
  VscEdit,
  VscEye,
  VscEyeClosed,
  VscTrash,
  VscArrowDown,
  VscArrowUp,
} from 'react-icons/vsc';
import { components } from './components';
import { CONFIG } from 'lib/configuration';
import { useChangeCounter } from 'hooks/useChangeCounter';

export function SectionComponent(props) {
  const {
    data,
    onEdit,
    onDelete,
    toggleVisibility,
    onOrderDown,
    onOrderUp,
    orderLoading,
  } = props;

  // TODO: CHANGE THIS TO A HASH GENERATOR FOR BETTER PERFORMANCE
  const hash = useChangeCounter((data) => JSON.stringify(data), data?.payload);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
  };
  const handleToggleVisibility = (e) => {
    e.stopPropagation();
    toggleVisibility();
  };
  const component = useMemo(() => {
    return components.find((c) => c.type === data.type);
  }, [components, data.type]);

  const editable = useMemo(() => {
    if (!data.component_id) return true;
    return component.overridables?.length;
  }, [data.component_id, component]);

  const preview = useMemo(() => {
    if (!data?.id) return;
    // const encodedData = encodeData(data);
    // const urlFriendly = encodeURIComponent(encodedData);
    return `${CONFIG.PLATFORM_CLIENT_URL}/----section-loader----/${data.id}#${hash}`;
    // return `${process.env.PLATFORM_CLIENT_URL}/---section-loader---/${urlFriendly}`;
  }, [data?.id, hash]);

  return (
    <div className="border rounded-lg relative overflow-hidden h-[450px]">
      <iframe
        // onLoad={() => setLoading(false)}
        className="w-full h-full"
        // style={{ width: '200%', scale: 0.5, height: 500 }}
        // width="560"
        // height="315"
        src={preview}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        // title="YouTube video player"
        // frameBorder={0}
        // allowfullscreen
      />
      <div className="absolute p-2 top-0 inset-x-0 flex gap-1 items-center">
        <div className="component-name grow shrink text-lg font-semibold p-2 bg-gradient-to-r from-white rounded-s-md">
          {component?.title || component?.type}
        </div>
        {onOrderDown && (
          <IconButton
            onClick={onOrderDown}
            variant="outline"
            icon={<VscArrowDown />}
            className="bg-white/40"
            disabled={orderLoading}
          />
        )}
        {onOrderUp && (
          <IconButton
            onClick={onOrderUp}
            variant="outline"
            icon={<VscArrowUp />}
            className="bg-white/40"
            disabled={orderLoading}
          />
        )}
        {editable && (
          <IconButton
            onClick={onEdit}
            variant="outline"
            icon={<VscEdit />}
            className="bg-white/40"
          />
        )}
        <IconButton
          onClick={handleToggleVisibility}
          variant="outline"
          className="bg-white/40"
          icon={data.hidden ? <VscEye /> : <VscEyeClosed />}
        />
        <IconButton
          onClick={handleDelete}
          variant="outline"
          className="bg-white/40"
          icon={<VscTrash />}
        />
      </div>
    </div>
  );
}

import * as Yup from 'yup';

import TOP_DESTINATIONS1 from 'assets/img/component-temps/topDestinations1.png';
import { FormElementType } from 'components/formik/FormElement';
import { createComponent } from './CreateComponent';

export const topDestinations1Component = createComponent({
  type: 'topDestinations1',
  title: 'Top Destinations',
  initialValues: {
    title: '',
    groups: [],
  },
  form: [
    {
      type: FormElementType.Input,
      name: 'title',
      validation: Yup.string().min(2).max(128),
      props: {
        placeholder: 'Enter Title',
        label: 'Section Title',
      },
    },
    {
      type: FormElementType.Array,
      name: 'groups',
      // validation: Yup.string().min(2).max(128),
      props: {
        maxItems: 6,
        label: 'Groups',
        itemsWrapper: (props) => <div {...props} className="grid gap-4" />,
        wrapper: (props) => (
          <div
            {...props}
            className="p-4 border rounded-lg flex flex-col gap-3"
          />
        ),
        divider: false,
        emptyValues: { title: '', items: [] },
        form: [
          {
            type: FormElementType.Input,
            name: 'title',
            validation: Yup.string().min(2).max(128),
            props: {
              placeholder: 'Enter Title',
              label: 'Group Title',
            },
          },
          {
            type: FormElementType.Array,
            name: 'items',
            // validation: Yup.string().min(2).max(128),
            props: {
              maxItems: 6,
              label: 'Items',
              itemsWrapper: (props) => (
                <div {...props} className="grid md:grid-cols-2 gap-4" />
              ),
              wrapper: (props) => (
                <div
                  {...props}
                  className="p-4 border rounded-lg flex flex-col gap-3"
                />
              ),
              divider: false,
              emptyValues: { title: '', imageUrl: '', url: '' },
              form: [
                {
                  type: FormElementType.Input,
                  name: 'title',
                  validation: Yup.string().min(2).max(128),
                  props: {
                    placeholder: 'Enter Title',
                    label: 'Item Title',
                  },
                },

                {
                  type: FormElementType.FilePicker,
                  name: 'imageUrl',
                  validation: Yup.string(),
                  props: {
                    image: true,
                    placeholder: 'Pick Image',
                    label: 'Item Image',
                  },
                },
                {
                  type: FormElementType.Input,
                  name: 'url',
                  validation: Yup.string().min(2).max(128),
                  props: {
                    placeholder: 'Enter Url',
                    label: 'Item Url',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
  image: TOP_DESTINATIONS1,
});

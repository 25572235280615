import { Box, CircularProgress, Spinner } from '@chakra-ui/react';
import { useSharedComponents } from 'lib/api/shared-components.api';
import React, { useState } from 'react';
import Pagination from 'components/pagination/Pagination';
import TemplateCard from './TemplateCard';

export default function SelectSharedComponent(props) {
  const { onSelect } = props;
  const [page, setPage] = useState(1);
  const pageSize = 24;
  const { data, error, isLoading, mutate } = useSharedComponents({
    pageSize,
    page,
  });

  return (
    <Box>
      {error ? (
        // TODO: Handle error
        <div>An error has occurred</div>
      ) : isLoading ? (
        <Spinner color="brand" />
      ) : (
        <div className="grid grid-cols-4 gap-4">
          {data?.items.map((component) => (
            <TemplateCard
              key={component.id}
              title={component.name}
              onClick={() => onSelect(component)}
            />
          ))}
        </div>
      )}
      {Boolean(data?.totalPages > 1) && (
        <Pagination
          page={page}
          totalPages={data?.totalPages}
          onPageChange={(p) => setPage(p)}
        />
      )}
    </Box>
  );
}

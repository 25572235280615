import { Switch } from '@chakra-ui/react';
import FormControl from '../FormControl';
import useFormHelpers from '../useFormHelpers';
import clsx from 'clsx';

export default function FormikSwitch(props) {
  const { controlProps, componentProps, inline, ...others } =
    useFormHelpers(props);

  return (
    <FormControl
      {...controlProps}
      className={clsx({ ['flex']: inline }, controlProps.className)}
    >
      <Switch
        isChecked={componentProps.value}
        {...componentProps}
        {...others}
        className={clsx(
          { ['order-first me-2']: inline },
          componentProps.className,
        )}
      />
    </FormControl>
  );
}

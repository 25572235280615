import { IconButton, useDisclosure } from '@chakra-ui/react';
import FormikInput from '../Input';
import FilePickerModal from './modal';
import { MdFileOpen } from 'react-icons/md';

export default function FormikFilePicker(props) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { formik, name, image, ...others } = props;

  return (
    <>
      <FormikInput
        formik={formik}
        name={name}
        {...others}
        rightElement={
          <div>
            <IconButton
              // onClick={() => formik.setFieldValue(name, '')}
              onClick={onOpen}
              size="sm"
              icon={<MdFileOpen />}
            />
          </div>
        }
      />
      <FilePickerModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={(v) => formik.setFieldValue(name, v)}
      />
    </>
  );
}

import { PlusSquareIcon } from '@chakra-ui/icons';
import Card from 'components/card/Card';

export default function AddPageButton(props) {
  return (
    <Card
      onClick={props.onClick}
      className="h-full max-w-full border rounded-[20px] flex justify-center items-center cursor-pointer select-none hover:bg-white/[.2]"
    >
      <PlusSquareIcon className="my-16" fontSize={40} />
    </Card>
  );
}

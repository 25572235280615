import FormControl from '../FormControl';
import useFormHelpers from '../useFormHelpers';

import { Fragment, useState, useMemo, useEffect } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckCircleIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Input, InputRightElement, InputGroup } from '@chakra-ui/react';
import useDebouncedState from 'hooks/useDebouncedState';
import useStabler from 'hooks/useStabler';

export default function FormikAutocomplete(props) {
  const { formik, onChange, name } = props;
  const {
    controlProps,
    componentProps: { value, isInvalid, disabled },
    useBackend,
    // options = [],
    titleSelector = (item) => item.title,
    keySelector = (item) => item.value,
    valueSelector = (item) => item,
    initialOption,
    ...others
  } = useFormHelpers(props);

  const [[queryTemp, setQueryTemp], [query]] = useDebouncedState('');
  const { data = [], error, mutate, isLoading } = useBackend(query);

  const [selected, setSelected] = useState(() => {
    if (initialOption) return initialOption;
    if (!value) return value;
    if (props.valueSelector)
      return data?.find((item) => valueSelector(item) === value) || null;
    return value;
  });

  const stableValueSelector = useStabler(valueSelector);

  // const selected = useMemo(() => {
  //   return data?.find((item) => valueSelector(item) === value) || null;
  // }, [data, value]);

  const filtered = useMemo(() => {
    const items =
      queryTemp === ''
        ? data
        : data?.filter((item) =>
            titleSelector(item)
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(queryTemp.toLowerCase().replace(/\s+/g, '')),
          );
    const stringSelected = JSON.stringify(selected);
    if (selected && !items?.find((i) => JSON.stringify(i) === stringSelected)) {
      items.unshift(selected);
    }
    return items;
  }, [data, queryTemp, selected]);

  useEffect(() => {
    if (!value) return setSelected(null);
    const newOption = data?.items?.find(
      (item) => stableValueSelector(item) === value,
    );
    if (newOption) setSelected(newOption);
  }, [data, value, stableValueSelector]);

  const handleChange = (item) => {
    formik.setFieldValue(name, valueSelector(item));
    setSelected(item);
    onChange?.(item);
  };

  return (
    <FormControl {...controlProps}>
      <Combobox value={selected} onChange={handleChange}>
        <div className="relative">
          <InputGroup>
            <Input
              as={Combobox.Input}
              displayValue={(val) => (val ? titleSelector(val) : val)}
              onChange={(event) => setQueryTemp(event.target.value)}
              // placeholder={placeholder}
              {...others}
            />
            <InputRightElement>
              <Combobox.Button className="flex items-center justify-center text-xl w-full h-full">
                <ChevronDownIcon aria-hidden="true" />
              </Combobox.Button>
            </InputRightElement>
          </InputGroup>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQueryTemp('')}
          >
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {!filtered?.length ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  {isLoading ? 'Loading...' : 'Nothing found.'}
                </div>
              ) : (
                filtered.map((item) => (
                  <Combobox.Option
                    key={keySelector(item)}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 ps-10 pe-4 ${
                        active ? 'bg-teal-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={item}
                  >
                    {({ active }) => {
                      const isSelected =
                        selected &&
                        JSON.stringify(stableValueSelector(item)) ===
                          JSON.stringify(value);

                      return (
                        <>
                          <span
                            className={`block truncate ${
                              isSelected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {titleSelector(item)}
                          </span>
                          {isSelected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? 'text-white' : 'text-teal-600'
                              }`}
                            >
                              <CheckCircleIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </FormControl>
  );
}

import { del, post, useFetch, useFetchPost } from '../services/api.service';

export const useMenuItems = (lang) => {
  return useFetch(`/menu/all/${lang}`);
};

export const createMenuItem = (model) => {
  return post('/menu/create', model);
};
export const updateMenuItem = (id, lang, model) => {
  return post('/menu/update/' + id, { ...model, lang });
};

export const useMenuQuery = (model) => {
  return useFetchPost(['/menu/query', model]);
  // return { data: [{ title: 'title', value: 'id' }] };
};

export const reorderMenuItem = (id, order, parent_id) => {
  return post('/menu/reorder/' + id, { order, parent_id });
};
export const deleteMenuItem = (id) => {
  return del('/menu/' + id);
};

import clsx from 'clsx';
import FileItem from './file-item';

export const AchaPlorerExplorer = (props) => {
  const { data, onPathChanged, isLoading, onSelect, selected } = props;
  if (isLoading || !data) return <>loading...</>;
  return (
    <div className="aui-split-panel aui-component ">
      <div className="panel file-explorer-panel">
        <div className="aui-file-explorer-preview aui-component">
          <ul className="explorer-container">
            {data.path !== data.parent && (
              <li
                onDoubleClick={() => onPathChanged(data.parent)}
                className="folder"
              >
                <div className="details name folder-item">
                  <i className="ap-icon-folder1"></i>
                  <span>..</span>
                </div>
              </li>
            )}
            {(data.folders || []).map((f, idx) => (
              <li
                onDoubleClick={() => onPathChanged(f.path)}
                onClick={() => onSelect(f)}
                key={idx}
                className={clsx('folder', {
                  selected: f.path === selected?.path,
                })}
                title={f.name}
              >
                <div className="details name folder-item">
                  <i className="ap-icon-folder1"></i>
                  <span>{f.name}</span>
                </div>
              </li>
            ))}
            {(data.files || []).map((f, idx) => (
              <FileItem
                key={idx}
                onSelect={onSelect}
                data={f}
                selectedPath={selected?.path}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

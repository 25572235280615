import { FormElementType } from 'components/formik/FormElement';
import useForm from 'components/formik/useForm';
import * as Yup from 'yup';
import FormikSelectFooter from './formik-select-footer';
import Fieldset from 'components/fieldset';

const form = [
  {
    type: FormElementType.Input,
    name: 'name',
    validation: Yup.string().min(2).max(64).required('Required'),
    props: {
      placeholder: 'Enter Name',
      label: 'Name',
    },
  },
  {
    type: FormElementType.FilePicker,
    name: 'logo',
    validation: Yup.string().min(2).required('Required'),
    props: {
      image: true,
      label: 'Logo',
      placeholder: 'Logo Url',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'primaryColor',
    initialValue: '#164c61',
    validation: Yup.string(),
    props: {
      label: 'Primary color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'secondaryColor',
    initialValue: '#3E4151',
    validation: Yup.string(),
    props: {
      label: 'Secondary color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'tertiaryColor',
    initialValue: '#FD9728',
    validation: Yup.string(),
    props: {
      label: 'Tertiary color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'successColor',
    initialValue: '#038F2B',
    validation: Yup.string(),
    props: {
      label: 'Success color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'infoColor',
    initialValue: '#003c95',
    validation: Yup.string(),
    props: {
      label: 'Info color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'warningColor',
    initialValue: '#fd9628',
    validation: Yup.string(),
    props: {
      label: 'Warning color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'errorColor',
    initialValue: '#E84855',
    validation: Yup.string(),
    props: {
      label: 'Error color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'borderColor',
    initialValue: '#e2e7ea',
    validation: Yup.string(),
    props: {
      label: 'Border color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'textColor',
    initialValue: '#3e4151',
    validation: Yup.string(),
    props: {
      label: 'Text color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'textSecondary',
    initialValue: '#787a85',
    validation: Yup.string(),
    props: {
      label: 'Text Secondary color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'backgroundColor',
    initialValue: '#F5F7F8',
    validation: Yup.string(),
    props: {
      label: 'Background color',
    },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'backgroundSurfaceColor',
    initialValue: '#fff',
    validation: Yup.string(),
    props: {
      label: 'Background Surface color',
    },
  },
  {
    type: FormElementType.Container,
    name: 'products',
    props: {
      wrapper: ({ children }) => (
        <Fieldset title="Products:" className="lg:col-span-2">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {children}
          </div>
        </Fieldset>
      ),
      form: [
        {
          type: FormElementType.Switch,
          name: 'products.vacationPackage',
          initialValue: true,
          props: {
            label: 'Vacation Package',
            inline: true,
          },
        },
        {
          type: FormElementType.Switch,
          name: 'products.flight',
          initialValue: true,
          props: {
            label: 'Flight',
            inline: true,
          },
        },
        {
          type: FormElementType.Switch,
          name: 'products.hotel',
          initialValue: true,
          props: {
            label: 'Hotel',
            inline: true,
          },
        },
        {
          type: FormElementType.Switch,
          name: 'products.car',
          initialValue: true,
          props: {
            label: 'Car Rental',
            inline: true,
          },
        },
        {
          type: FormElementType.Switch,
          name: 'products.transfer',
          initialValue: true,
          props: {
            label: 'Transfer',
            inline: true,
          },
        },
        {
          type: FormElementType.Switch,
          name: 'products.bus',
          initialValue: true,
          props: {
            label: 'Bus',
            inline: true,
          },
        },
        {
          type: FormElementType.Switch,
          name: 'products.train',
          initialValue: true,
          props: {
            label: 'Train',
            inline: true,
          },
        },
        {
          type: FormElementType.Switch,
          name: 'products.activity',
          initialValue: true,
          props: {
            label: 'Activities',
            inline: true,
          },
        },
        {
          type: FormElementType.Switch,
          name: 'products.cruises',
          initialValue: true,
          props: {
            label: 'Cruises',
            inline: true,
          },
        },
      ],
    },
  },
  {
    type: FormElementType.Custom,
    name: 'footerComponent',
    initialValue: '',
    props: {
      label: 'Footer',
      placeholder: 'Select Footer Component',
    },

    component: (props) => <FormikSelectFooter {...props} />,
  },
];

export default function SettingsForm({ data, onSubmit }) {
  const handleSubmit = async (values) => {
    await onSubmit({
      ...data,
      main: values,
    });
  };

  const { formik, SubmitButton, AllElements } = useForm({
    form,
    initialValues: data?.main,
    onSubmit: handleSubmit,
  });

  console.log(formik.values);

  return (
    <>
      <div className="grid gap-4 lg:grid-cols-2">{AllElements}</div>
      <SubmitButton className="mt-4" />
    </>
  );
}

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormElementType } from 'components/formik/FormElement';
import useForm from 'components/formik/useForm';
import { useMemo } from 'react';
import * as Yup from 'yup';

export default function FormTranslateModal(props) {
  const { data, lang, isOpen, onClose, onSubmit } = props;

  const initialValues = useMemo(() => {
    if (!data) return;
    const translations = data.translations?.[lang];

    return data.data?.elements?.reduce((p, c) => {
      return {
        ...p,
        [c.name]: { label: translations?.[c.name]?.label || c.label },
      };
    }, {});
  }, [data, lang]);

  const form = useMemo(() => {
    if (!initialValues) return [];
    return Object.keys(initialValues).map((key) => ({
      type: FormElementType.Input,
      name: `${key}.label`,
      validation: Yup.string().min(1).max(64).required('Required'),
      props: {
        placeholder: `Enter ${key} Label`,
        label: `${key} Label`,
      },
    }));
  }, [initialValues]);

  console.log({ initialValues, form });

  const { formik, SubmitButton, AllElements } = useForm({
    onSubmit,
    form,
    initialValues,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="capitalize">
          Form Translations ({lang})
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="flex flex-col gap-4">
          {AllElements}
          <SubmitButton>Save</SubmitButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

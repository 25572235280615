import { Box, Button } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { isSuccessful } from 'lib/services/api.service';
import { useBlogPost } from 'lib/api/blogs.api';
import SeoForm from 'components/page-components/seo-form';
import PostDetails from './components/PostDetails';
import { updatePost, updatePostSeo, syncPost } from 'lib/api/blogs.api';
import Card from 'components/card/Card';
import { defaultLang } from 'lib/constants';

export default function BlogPost() {
  const { id, lang } = useParams();
  const history = useHistory();
  const { data, error, mutate } = useBlogPost(id, lang);
  const [cloning, setCloning] = useState(false);

  const onBack = () => {
    history.goBack();
  };

  const onSubmitSeo = async (values) => {
    const res = await updatePostSeo(data.id, values);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate({ data: { ...data, ...values } });
  };
  const onSubmitDetails = async (values) => {
    const res = await updatePost(data.id, values);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate({ data: { ...data, ...values } });
  };

  const clone = async () => {
    setCloning(true);
    const res = await syncPost(id, lang);
    setCloning(false);
    if (!isSuccessful(res)) {
      // TODO: handle error
      return;
    }
    mutate();
  };

  return (
    <Box>
      <Button className="mb-4" onClick={() => onBack()}>
        Back
      </Button>
      {error ? (
        data?.noDetail ? (
          <Card className="items-start gap-4">
            <p>
              This page has not yet built. if you are done editing this page in
              "{defaultLang}" you can make a clone.
            </p>
            <Button colorScheme="brand" isLoading={cloning} onClick={clone}>
              Clone
            </Button>
          </Card>
        ) : (
          // TODO: handle error
          <div>An error has occurred: {JSON.stringify(data)}</div>
        )
      ) : (
        <div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8">
            <div className="lg:col-span-2 mb-4">
              <PostDetails data={data} onSubmit={onSubmitDetails} />
            </div>
            <SeoForm
              data={data}
              onSubmit={onSubmitSeo}
              getRoute={(val) => `/blog/post/${val}`}
              permalinkPrefix={`/blog/post/`}
              hasImage
            />
          </div>
        </div>
      )}
    </Box>
  );
}

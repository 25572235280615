import {
  useFetchPost,
  post,
  get,
  del,
  useFetch,
} from '../services/api.service';

export const usePages = (model) => {
  return useFetchPost(['/pages/paginate', model]);
};
export const usePage = (id, lang) => {
  return useFetch(`/pages/${id}/${lang}`);
};

export const addPage = (model) => {
  return post('/pages/create', model);
};

export const updateSeo = (id, model) => {
  return post('/pages/seo/update', { id, ...model });
};
export const syncPage = (id, lang) => {
  return post(`/pages/sync/${id}/${lang}`);
};

export const deletePage = (id) => {
  return del('/pages/' + id);
};
export const togglePageVisibility = (id) => {
  return post('/pages/visibility/' + id);
};

export const addSection = (id, index, model) => {
  return post('/pages/section/create', {
    page_id: id,
    ...model,
    // payload: JSON.stringify({ type }),
    index,
    // section_id: string;
  });
};
export const updateSection = (id, model) => {
  return post('/pages/section/update', {
    // lang: string;
    payload: JSON.stringify(model),
    id,
  });
};

export const deleteSection = (id, forAll) => {
  return del(`/pages/${forAll ? 'section-all' : 'section'}/${id}`);
};

export const toggleVisibilitySection = (id) => {
  return post('/pages/section/visibility/' + id);
};

export const makeLanding = (id) => {
  return post('/pages/make-landing/' + id);
};

export const reorderSection = (sectionId, up) => {
  return post(`/pages/section/order-${up ? 'up' : 'down'}/${sectionId}`);
};

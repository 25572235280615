import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
// Custom components
import { HSeparator } from '../../../components/separator/Separator';
import DefaultAuth from '../../../layouts/auth/Default';
// Assets
import illustration from '../../../assets/img/auth/auth.png';
import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { mode } from '@chakra-ui/theme-tools';
import { signin } from '../../../lib/api/auth.api';
import { isSuccessful } from '../../../lib/services/api.service';

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');

  const history = useHistory();
  const [waiting, setWaiting] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [model, setModel] = React.useState({
    username: '',
    password: '',
    remember: true,
  });
  const updateField = (field, value) => {
    const payload = {
      ...model,
      [field]: value,
    };
    setModel(payload);
  };
  const onSignin = async () => {
    model.username = model.username.trim();
    model.password = model.password.trim();

    if (!model.username || !model.password) {
      // TODO: handle validation
      return;
    }

    setWaiting(true);
    const response = await signin(model);
    if (!isSuccessful(response)) {
      // TODO: handle validation
      setWaiting(false);
      return;
    }

    global.$app.token = response.data.data.token;
    global.$app.storeInstance();
    history.push('/admin/default');
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              disabled={waiting}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="email"
              placeholder="me@myself.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={model.username}
              onChange={(e) => updateField('username', e.target.value)}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                disabled={waiting}
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                value={model.password}
                onChange={(e) => updateField('password', e.target.value)}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={() => setShow(!show)}
                />
              </InputRightElement>
            </InputGroup>
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  disabled={waiting}
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                  checked={model.remember}
                  defaultChecked={true}
                  onChange={(e) => updateField('remember', e.target.checked)}
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>
            </Flex>
            <Button
              disabled={waiting}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              onClick={() => onSignin()}
              mb="24px"
            >
              {waiting && <i className="fa fa-spinner fa-spin"></i>}
              <span>Sign In</span>
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;

import { useMemo, useState } from 'react';

export const AchaPlorerNavigationBar = ({
  placeholder,
  path,
  isLoading,
  onPathChanged,
  onPathRefresh,
  onPathFilter,
}) => {
  const [query, setQuery] = useState('');
  const crumbs = useMemo(() => {
    const parts = path
      .trim()
      .split('/')
      .filter((p) => p && p !== '/');
    return parts.map((p, idx) => {
      return {
        path: '/' + [...parts].slice(0, idx + 1).join('/'),
        name: p,
      };
    });
  }, [path]);

  return (
    <div className="aui-navigation-bar aui-component ">
      <div className="path-container ">
        <div className="parent-button-container">
          <button onClick={() => onPathChanged('/')}>
            <i className="ap-icon-hard-drive"></i>
          </button>
          <div className="show-sub-folders">
            <i className="ap-icon-chevron-right"></i>
          </div>
        </div>
        <div className="path-inner-container slide">
          <div className="slide-handler left-slide-handler">
            <i className="fa fa-chevron-left"></i>
          </div>
          <div className="slide-handler right-slide-handler">
            <i className="fa fa-chevron-right"></i>
          </div>
          <ul className="root-folders">
            {(crumbs || []).map((crumb, i) => (
              <li key={i} className="root-folder open">
                <button onClick={() => onPathChanged(crumb.path)}>
                  <i className="ap-icon-folder1"></i>
                  <span>{crumb.name}</span>
                </button>
                <div className="show-sub-folders">
                  <i className="ap-icon-chevron-right"></i>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="refresh-button-container">
          <button onClick={() => onPathRefresh()}>
            <i className="ap-icon-refresh-ccw"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

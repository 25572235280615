import Card from 'components/card/Card';
import { FormElementType } from 'components/formik/FormElement';
import useForm from 'components/formik/useForm';
import { useMemo } from 'react';
import * as Yup from 'yup';

export default function PostDetails(props) {
  if (props.data) return <RealComponent {...props} />;
  return (
    <Card>
      loading...
      {/* content
                  createdAt
                  description
                  id
                  index
                  keywords
                  lang
                  name
                  permalink
                  summary
                  title 
              */}
    </Card>
  );
}

const RealComponent = (props) => {
  const { data, onSubmit } = props;
  const initialValues = useMemo(
    () => ({
      summary: data.summary,
      content: data.content,
    }),
    [data],
  );
  const { SubmitButton, AllElements, formik } = useForm({
    initialValues,
    form: [
      {
        type: FormElementType.Textarea,
        name: 'summary',
        validation: Yup.string().max(1024),
        props: {
          placeholder: 'Enter Summary',
          label: 'Summary',
        },
      },
      {
        type: FormElementType.TextEditor,
        name: 'content',
        validation: Yup.string(),
        props: {
          placeholder: 'Enter Content',
          label: 'Content',
        },
      },
    ],
    onSubmit,
  });

  return (
    <Card className="flex flex-col gap-4">
      {AllElements}
      <SubmitButton className="mt-4" />
      {/* content
                  createdAt
                  description
                  id
                  index
                  keywords
                  lang
                  name
                  permalink
                  summary
                  title 
              */}
    </Card>
  );
};

import * as Yup from 'yup';
import { FormElementType } from 'components/formik/FormElement';
import { getIn } from 'formik';

export const defaultForm = [
  {
    type: FormElementType.ColorPicker,
    name: 'bgColor',
    initialValue: '',
    validation: Yup.string(),
    props: { placeholder: 'Pick Background Color', label: 'Background color' },
  },
  {
    type: FormElementType.ColorPicker,
    name: 'textColor',
    initialValue: '',
    validation: Yup.string(),
    props: { placeholder: 'Pick Text Color', label: 'Text color' },
  },
  // {
  //   type: FormElementType.Input,
  //   name: 'name',
  //   initialValue: '',
  //   validation: Yup.string().min(2).max(32),
  //   props: {
  //     placeholder: 'Enter Section Name',
  //     label: 'Name',
  //   },
  // },
  // {
  //   type: FormElementType.Textarea,
  //   name: 'styles',
  //   initialValue: '',
  //   props: {
  //     placeholder: 'Enter inline styles here',
  //     label: 'Styles',
  //   },
  // },
];

export function createComponent(data, skipDefaults) {
  if (skipDefaults) return data;
  return {
    ...data,
    form: [...data.form, ...defaultForm],
  };
}

export function embedYoutubeLink(formik, field) {
  const url = getIn(formik.values, field);

  // Regular expression to match YouTube URL and extract the video ID
  const regExp =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|shorts)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const match = url.match(regExp);

  if (!match) return;

  formik.setFieldValue(field, `https://www.youtube.com/embed/${match[1]}`);
}

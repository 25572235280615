import POPULAR_ROUTES1 from 'assets/img/component-temps/popularRoutes1.png';
import { useHotelLocationQuery } from 'lib/api/location.api';
import { FormElementType } from 'components/formik/FormElement';
import { createComponent } from './CreateComponent';

export const popularRoutes1Component = createComponent({
  type: 'popularRoutes1',
  title: 'Popular Routes',
  initialValues: { cities: [] },
  form: [
    {
      type: FormElementType.MultiAutocomplete,
      name: 'cities',
      props: {
        placeholder: 'Select...',
        label: 'Cities',
        keySelector: (item) => item.id,
        titleSelector: (item) => item.name,
        useBackend: (query) =>
          useHotelLocationQuery({
            query,
            culture: 'en-US',
            locationType: 2,
          }),
      },
    },
  ],
  image: POPULAR_ROUTES1,
});

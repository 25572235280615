import { FormElementType } from 'components/formik/FormElement';
import * as Yup from 'yup';
import CAROUSEL2 from 'assets/img/component-temps/carousel2.png';
import { createComponent } from './CreateComponent';

export const carousel2Component = createComponent({
  type: 'carousel2',
  title: 'Carousel 2',
  initialValues: {
    title: '',
    category: '',
    items: [{ image: '', alt: '' }],
  },
  form: [
    {
      type: FormElementType.Input,
      name: 'title',
      validation: Yup.string().min(2),
      props: {
        placeholder: 'Enter Section Title',
        label: 'Title',
      },
    },
    {
      type: FormElementType.Input,
      name: 'category',
      validation: Yup.string().min(2),
      props: {
        placeholder: 'Enter Section Category',
        label: 'Category',
      },
    },
    {
      type: FormElementType.Array,
      name: 'items',
      // validation: Yup.string().min(2).max(128),
      props: {
        label: 'Items',
        emptyValues: { image: '', alt: '' },
        form: [
          {
            type: FormElementType.Input,
            name: 'alt',
            validation: Yup.string().min(2),
            props: {
              placeholder: 'Enter Image Alt',
              label: 'Alt',
            },
          },
          {
            type: FormElementType.FilePicker,
            name: 'image',
            validation: Yup.string().min(2),
            props: {
              placeholder: 'Enter Image',
              label: 'Image',
            },
          },
        ],
      },
    },
  ],
  image: CAROUSEL2,
});

import * as Yup from 'yup';

import MAP_LOCATIONS1 from 'assets/img/component-temps/mapLocations1.png';
import { FormElementType } from 'components/formik/FormElement';
import { createComponent } from './CreateComponent';

export const mapLocations1Component = createComponent({
  type: 'mapLocations1',
  title: 'Map Locations',
  initialValues: {
    title: '',
  },
  form: [
    {
      type: FormElementType.Input,
      name: 'title',
      validation: Yup.string().min(2).max(128),
      props: {
        placeholder: 'Hot Summer Location Must Go',
        label: 'Title',
      },
    },
    {
      type: FormElementType.Textarea,
      name: 'description',
      initialValue: '',
      validation: Yup.string().min(2).max(256),
      props: {
        placeholder:
          'To see the recommended hotels of each city and country, please select your destination',
        label: 'Description',
        rows: 3,
      },
    },
  ],
  image: MAP_LOCATIONS1,
});

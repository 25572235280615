import React from 'react';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  VscCopy,
  VscEye,
  VscEyeClosed,
  VscEllipsis,
  VscTrash,
} from 'react-icons/vsc';
import { Skeleton, SkeletonText } from '@chakra-ui/react';
import { PiStar, PiStarFill } from 'react-icons/pi';
import Card from 'components/card/Card';
import { languages } from 'lib/constants';

export default function PageCard(props) {
  const { data, onClick, onToggleDisabled, onClone, onDelete, onSetLanding } =
    props;

  const openActions = (e) => {
    e.stopPropagation();
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(data);
  };
  const handleToggleDisabled = (e) => {
    e.stopPropagation();
    onToggleDisabled(data);
  };
  const handleClone = (e) => {
    e.stopPropagation();
    onClone(data);
  };
  const handleLanding = (e) => {
    e.stopPropagation();
    onSetLanding(data);
  };

  return (
    <Card
      className="cursor-pointer hover:bg-white/[.2] transition-colors h-full"
      onClick={data ? () => onClick() : undefined}
    >
      {data ? (
        <div className="flex mb-4 gap-1">
          <div className="font-bold text-lg mb-2 grow line-clamp-1">
            {data.name}
          </div>

          {onSetLanding && data.isLanding && (
            <IconButton
              onClick={handleLanding}
              colorScheme="brand"
              icon={<PiStarFill />}
            />
          )}
          {onToggleDisabled && !data.isLanding && data.hidden && (
            <IconButton
              onClick={handleToggleDisabled}
              variant="outline"
              icon={<VscEyeClosed />}
            />
          )}
          <Menu>
            <MenuButton
              onClick={openActions}
              as={IconButton}
              variant="outline"
              icon={<VscEllipsis />}
            />
            <MenuList>
              {onClone && (
                <MenuItem onClick={handleClone} icon={<VscCopy />}>
                  Clone
                </MenuItem>
              )}
              {onToggleDisabled && !data.isLanding && !data.hidden && (
                <MenuItem onClick={handleToggleDisabled} icon={<VscEye />}>
                  Disable
                </MenuItem>
              )}
              {onSetLanding && !data.isLanding && !data.hidden && (
                <MenuItem onClick={handleLanding} icon={<PiStar />}>
                  Make Landing
                </MenuItem>
              )}
              {onDelete && !data.isLanding && (
                <MenuItem onClick={handleDelete} icon={<VscTrash />}>
                  Delete
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </div>
      ) : (
        <div className="flex mb-4 items-center gap-1">
          <SkeletonText skeletonHeight={5} w={100} noOfLines={1} me={'auto'} />

          <Skeleton className="w-9 h-9 !rounded-lg" />
        </div>
      )}
      {data ? (
        Boolean(data.description) && (
          <div className="mb-4 line-clamp-3">{data.description}</div>
        )
      ) : (
        <SkeletonText mb={4} />
      )}
      <div className="mt-auto flex gap-1.5 flex-wrap">
        {languages.map((l) =>
          data ? (
            <Button
              size="sm"
              key={l.code}
              variant="outline"
              children={l.code}
              className="font-mono"
              onClick={(e) => {
                e.stopPropagation();
                onClick(l.code);
              }}
            />
          ) : (
            <Skeleton key={l.code} className="w-8 h-8 !rounded-lg" />
          ),
        )}
      </div>
    </Card>
  );
}
